import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

export function validateFreqOptions(cntrl: AbstractControl): { [key: string]: any } | null {
  let anySelected = cntrl.get('SUN').value || cntrl.get('MON').value ||
    cntrl.get('TUE').value || cntrl.get('WED').value ||
    cntrl.get('FRI').value || cntrl.get('SAT').value;
  //console.log('selected ', anySelected);
  if (anySelected) {
    //console.log(this.sectionForm.get('WeeklyOptions'));
    return null;
  } else {
    //console.log(this.sectionForm.get('WeeklyOptions'));
    return {
      WeeklyOptionsMissing: { valid: false , msg: 'Please select atleast one weekly day. ' }
    }
  }
}
