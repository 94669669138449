import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { SectionCFormModel, ADLSIADSL, SectionCFields } from '../../../shared';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-adl-row',
    templateUrl: './adl-row.component.html',
    styleUrls: ['./adl-row.component.css']
})
export class AdlRowComponent implements OnInit {

    constructor() { }

    @Output("SectionUpdated")
    sectionUpdateEvent = new EventEmitter<SectionCFormModel>();

    @Input("SectionData")
    sectionData: ADLSIADSL;


    sectionForm: FormGroup;

    ngOnInit() {
        this.initForm();
    }

    initForm() {

        let formValues = {};
        let cntrls = {};
        for (let adl of this.sectionData.adl_rows) {

            let cntrl_sec = {};
            cntrl_sec[SectionCFields.adl_title] = new FormControl('');
            cntrl_sec[SectionCFields.adl_val] = new FormControl('');
            cntrl_sec[SectionCFields.na] = new FormControl('');
            cntrl_sec[SectionCFields.col_name] = new FormControl('');
            cntrl_sec[SectionCFields.col_val] = new FormControl('', [Validators.required]);
            cntrl_sec[SectionCFields.rpt_col] = new FormControl('');
            cntrl_sec[SectionCFields.rpt_col_val] = new FormControl('');
            cntrl_sec[SectionCFields.acat_id] = new FormControl('');
            cntrl_sec[SectionCFields.sec_id] = new FormControl('');
            cntrls[adl.adl_val] = new FormGroup(cntrl_sec);

            let sec_vals = {};
            sec_vals[SectionCFields.adl_title] = adl.adl_title;
            sec_vals[SectionCFields.adl_val] = adl.adl_val;
            sec_vals[SectionCFields.na] = adl.col_val == 'NA';
            sec_vals[SectionCFields.col_name] = adl.col_name;
            sec_vals[SectionCFields.col_val] = adl.col_val;
            sec_vals[SectionCFields.rpt_col] = adl.rpt_col;
            sec_vals[SectionCFields.rpt_col_val] = adl.rpt_col_val;
            sec_vals[SectionCFields.acat_id] = adl.acat_id;
            sec_vals[SectionCFields.sec_id] = adl.sec_id;
            formValues[adl.adl_val] = sec_vals;
        }
        this.sectionForm = new FormGroup(cntrls);
        this.sectionForm.patchValue(formValues);

      Object.keys(this.sectionForm.controls).forEach(key => {
        const AbstractControl = this.sectionForm.controls[key];
        this.configNaChanges(AbstractControl);
        this.formDataUpdates(AbstractControl);
      });

      //below code was not working in IE 11, so we are using the above one Ex: Object.keys
        //Object.values(this.sectionForm.controls).forEach((section: AbstractControl) => {
        //    this.configNaChanges(section);
        //    this.formDataUpdates(section);
        //});

    }


    configNaChanges(form: AbstractControl) {

        let col_change = false;
        let na_change = false;

        form.get(SectionCFields.na).valueChanges.subscribe(val => {
            if (!na_change && col_change) {
                col_change = false;
                return;
            }
            na_change = true;
            if (val) {
                form.get(SectionCFields.col_val).setValue('NA');
                form.get(SectionCFields.rpt_col_val).setValue(null);
            } else {
                form.get(SectionCFields.col_val).setValue(null);
                form.get(SectionCFields.col_val).markAsTouched();
            }
        });

        form.get(SectionCFields.col_val).valueChanges.subscribe(val => {
            if (!col_change && na_change) {
                na_change = false;
                return;
            }
            col_change = true;
            if (val == 'NA') {
                form.get(SectionCFields.na).setValue(true);
                form.get(SectionCFields.rpt_col_val).setValue(null);
            } else {
                form.get(SectionCFields.na).setValue(false);
            }
        });

    }

    formDataUpdates(form: AbstractControl) {
        form.valueChanges.pipe(debounceTime(300)).subscribe((formValue: SectionCFormModel) => {
            this.sectionUpdateEvent.emit(formValue);
        });
    }

}
