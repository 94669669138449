import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators, RequiredValidator } from '@angular/forms';
import { DataService, SummaryService, AlertsService, SectionDropdownServiceService } from '../../../shared/services';
import { LocDetermination } from '../../../shared/models/loc-determination.model';
import * as ALOCValidators from '../../../shared/validators/AlocDateValidators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-loc-determination',
  templateUrl: './loc-determination.component.html',
  styleUrls: ['./loc-determination.component.css'],
  providers: [DatePipe]
})
export class LocDeterminationComponent implements OnInit {
  locDeterminationForm: FormGroup;
  locDetermination: LocDetermination = {} as LocDetermination;
  locDeterminationStatus: any[];
  locDelayedExemptionReason: any[];
  locPASValidation: any[];
  locDetermination_dr: any[];

  minmumDate120 = new Date((new Date()).getFullYear() - 120, (new Date()).getMonth(), (new Date()).getDate());
  maximumDate120 = new Date((new Date()).getFullYear() + 120, (new Date()).getMonth(), (new Date()).getDate());

  constructor(private fb: FormBuilder, public dataService: DataService, public SummarySVC: SummaryService, public AlertSVC: AlertsService, private datePipe: DatePipe, private dropdownservice: SectionDropdownServiceService) {
    this.locDeterminationForm = this.fb.group({
      aO_ALOC_ID: new FormControl(''), 
      aO_DOC_REQUEST_DATE: new FormControl('', { validators: [Validators.required, ALOCValidators.validateFutureDate, , ALOCValidators.validate120Years]}),
      aO_DOC_REQUEST_COMMENTS: new FormControl('', [Validators.required]),
      aO_REQUEST_COMPLETE_DATE: new FormControl('', { validators: [Validators.required, ALOCValidators.validateFutureDate, , ALOCValidators.validate120Years] }),
      aO_PAS_EFFECTIVE_DATE: new FormControl('', { validators: [Validators.required, , ALOCValidators.validate120Years] }),
      aO_PAS_VALIDATION: new FormControl('', [Validators.required]),
      aO_PAS_ID: new FormControl('', [Validators.required]),
      aO_NEEDS_FACE_TO_FACE: new FormControl(''),
      aO_DELAYED_LOC_NEEDED: new FormControl(''),
      aO_DELAYED_EXEMPTION_REASON: new FormControl('', [Validators.required]),
      aO_ADVERSE_LOC_SCHED_DATE: new FormControl('', { validators: [Validators.required, , ALOCValidators.validate120Years]}),
      aO_LOC_DETERMINATION: new FormControl('', [Validators.required]),
      aO_LOC_DETERMINATION_DATE: new FormControl('', { validators: [Validators.required, ALOCValidators.validateFutureDate, , ALOCValidators.validate120Years] }),
      aO_LOC_DETERMINATION_STATUS: new FormControl('', [Validators.required]),
      aO_LOC_EFFECTIVE_DATE: new FormControl('', { validators: [Validators.required, ALOCValidators.validateFutureDate, , ALOCValidators.validate120Years] }),
      aO_PAA_DETERM_NOTE: new FormControl('', [Validators.required]),
      aO_LOC_RECOMMENDATION: new FormControl({ value: '', disabled: true }, [Validators.required]),
      aO_PAA_DETERM_NOTE_DATE: new FormControl('', { validators: [Validators.required, ALOCValidators.validateFutureDate, , ALOCValidators.validate120Years] })
    });
  }

 

  ngOnInit() {
    this.loadDropdownService();

  }

  receiveLocDetermination(data) {
    this.locDeterminationForm.patchValue(data);
    this.setFormData(data)
  }

  locdeterminationSave() {
   
    if (this.locDeterminationForm.get('aO_NEEDS_FACE_TO_FACE').value == null) {
      this.locDeterminationForm.get('aO_NEEDS_FACE_TO_FACE').setValidators([Validators.required]);
      this.locDeterminationForm.get('aO_NEEDS_FACE_TO_FACE').setErrors({ 'required': true });
    } 
    if (this.locDeterminationForm.get('aO_DELAYED_LOC_NEEDED').value == null) {
      this.locDeterminationForm.get('aO_DELAYED_LOC_NEEDED').setErrors([Validators.required]);
      this.locDeterminationForm.get('aO_DELAYED_LOC_NEEDED').setErrors({ 'required': true });
    }
    this.locDeterminationForm.updateValueAndValidity();
    if (!this.locDeterminationForm.valid) {
      for (let cntrl in this.locDeterminationForm.controls) {
        if (this.locDeterminationForm.controls[cntrl].invalid) {
          console.log('invalid field ', cntrl, this.locDeterminationForm.controls[cntrl]);
        }
      }
      return;
    }
 
    this.locDeterminationForm.get('aO_ALOC_ID').setValue(this.dataService.getSelectedDocument());

    this.updateLocDetermination(this.locDeterminationForm.value);

    let LOCformData = this.locDetermination;
    if (LOCformData['aO_DOC_REQUEST_DATE'] != null)
      LOCformData['aO_DOC_REQUEST_DATE'] = this.datePipe.transform(LOCformData['aO_DOC_REQUEST_DATE'], 'MM/dd/yyyy');

    if (LOCformData['aO_REQUEST_COMPLETE_DATE'] != null)
      LOCformData['aO_REQUEST_COMPLETE_DATE'] = this.datePipe.transform(LOCformData['aO_REQUEST_COMPLETE_DATE'], 'MM/dd/yyyy');

    if (LOCformData['aO_PAS_EFFECTIVE_DATE'] != null)
      LOCformData['aO_PAS_EFFECTIVE_DATE'] = this.datePipe.transform(LOCformData['aO_PAS_EFFECTIVE_DATE'], 'MM/dd/yyyy');

    if (LOCformData['aO_ADVERSE_LOC_SCHED_DATE'] != null)
      LOCformData['aO_ADVERSE_LOC_SCHED_DATE'] = this.datePipe.transform(LOCformData['aO_ADVERSE_LOC_SCHED_DATE'], 'MM/dd/yyyy');

    if (LOCformData['aO_LOC_DETERMINATION_DATE'] != null)
      LOCformData['aO_LOC_DETERMINATION_DATE'] = this.datePipe.transform(LOCformData['aO_LOC_DETERMINATION_DATE'], 'MM/dd/yyyy');

    if (LOCformData['aO_LOC_EFFECTIVE_DATE'] != null)
      LOCformData['aO_LOC_EFFECTIVE_DATE'] = this.datePipe.transform(LOCformData['aO_LOC_EFFECTIVE_DATE'], 'MM/dd/yyyy');

    if (LOCformData['aO_PAA_DETERM_NOTE_DATE'] != null)
      LOCformData['aO_PAA_DETERM_NOTE_DATE'] = this.datePipe.transform(LOCformData['aO_PAA_DETERM_NOTE_DATE'], 'MM/dd/yyyy');

    this.locDetermination.aO_ALOC_ID = this.dataService.getSelectedDocument();
  

    this.SummarySVC.PostLOCDetermination(this.locDetermination).subscribe(resp => {
     // console.log('summary loc determination create ', resp);
      this.AlertSVC.success("Summary LOC Determination Saved");

    }, error => {
        console.error('error creating summary loc determination', error);      
        this.AlertSVC.error("Error Saving Summary LOC Determination");
    }
    );
  

  }
  locdeterminationReset() {
   // Reset the LOC determination form values
  //  this.locDeterminationForm.reset();
    this.onClear();
    
  }
  updateLocDetermination(values: Object) {
    Object.assign(this.locDetermination, values);
  }
  loadDropdownService() {
    //aO_LOC_DETERMINATION_STATUS_IN
    this.dropdownservice.GetDropDownValues('LOC_DETER_STATUS').subscribe((data: any[]) => {
      this.locDeterminationStatus = data;
      this.AlertSVC.clearLoader();
    }, error => {
        this.AlertSVC.error(error);
      });

    //aO_DELAYED_EXEMPTION_REASON_IN
    this.dropdownservice.GetDropDownValues('DLY_EXE_RES').subscribe((data: any[]) => {
      this.locDelayedExemptionReason = data;
      this.AlertSVC.clearLoader();
    }, error => {
      this.AlertSVC.error(error);
      });

    //aO_PAS_VALIDATION_IN
    this.dropdownservice.GetDropDownValues('PAS_VAL').subscribe((data: any[]) => {
      this.locPASValidation = data;
      this.AlertSVC.clearLoader();
    }, error => {
      this.AlertSVC.error(error);
      });

    //aO_LOC_DETERMINATION_IN
    this.dropdownservice.GetDropDownValues('LOC_DETER').subscribe((data: any[]) => {
      this.locDetermination_dr = data;
      this.AlertSVC.clearLoader();
    }, error => {
      this.AlertSVC.error(error);
    });
  }
  setFormData(data) {
    //console.log(this.datePipe.transform(data['aO_REQUEST_COMPLETE_DATE']));

    if (data['aO_REQUEST_COMPLETE_DATE'] != null)
      this.locDeterminationForm.get('aO_REQUEST_COMPLETE_DATE').setValue(new Date(data['aO_REQUEST_COMPLETE_DATE']));

    if (data['aO_DOC_REQUEST_DATE'] != null)
      this.locDeterminationForm.get('aO_DOC_REQUEST_DATE').setValue(new Date(data['aO_DOC_REQUEST_DATE']));

    if (data['aO_PAS_EFFECTIVE_DATE'] != null)
      this.locDeterminationForm.get('aO_PAS_EFFECTIVE_DATE').setValue(new Date(data['aO_PAS_EFFECTIVE_DATE']));

    if (data['aO_ADVERSE_LOC_SCHED_DATE'] != null)
      this.locDeterminationForm.get('aO_ADVERSE_LOC_SCHED_DATE').setValue(new Date(data['aO_ADVERSE_LOC_SCHED_DATE']));

    if (data['aO_LOC_EFFECTIVE_DATE'] != null)
      this.locDeterminationForm.get('aO_LOC_EFFECTIVE_DATE').setValue(new Date(data['aO_LOC_EFFECTIVE_DATE']));

    if (data['aO_LOC_DETERMINATION_DATE'] != null)
      this.locDeterminationForm.get('aO_LOC_DETERMINATION_DATE').setValue(new Date(data['aO_LOC_DETERMINATION_DATE']));

    if (data['aO_PAA_DETERM_NOTE_DATE'] != null)
      this.locDeterminationForm.get('aO_PAA_DETERM_NOTE_DATE').setValue(new Date(data['aO_PAA_DETERM_NOTE_DATE']));
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.locDeterminationForm.reset();
      }
    });

  }
}
