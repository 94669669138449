import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SummaryRoutingModule } from './summary-routing.module';
import { SummaryComponent } from './summary/summary.component';
import { AttachmentsComponent} from './summary/attachments/attachments.component';
import { ContactInformationComponent } from './summary/contact-information/contact-information.component';
import { LocDeterminationComponent } from './summary/loc-determination/loc-determination.component';
import { McpReviewComponent } from './summary/mcp-review/mcp-review.component';
import { TopsummaryComponent } from './summary/topsummary/topsummary.component';
import { SharedModule } from '../shared'
@NgModule({
  declarations: [
    SummaryComponent,
    AttachmentsComponent,
    ContactInformationComponent,
    LocDeterminationComponent,
    McpReviewComponent,
    TopsummaryComponent],
  imports: [
    CommonModule,
    SummaryRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SummaryModule { }
