import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SectionAModel } from '../models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SectionDService {

  constructor(private apiService: ApiService) { }

  getSectionD(alocqId): Observable<any> {
    return this.apiService.get(`SectionD/${alocqId}`).pipe(map(data => {
      return data;
    }));
  }
  saveSection(section) {
    return this.apiService.put('sectionD/', section).pipe(map(data => {
      return data;
    }));
  }
  createSection(section) {
    return this.apiService.post('sectionD/', section).pipe(map(data => {
      return data;
    }));
  }
}
