import {
    Component, OnInit, Input, forwardRef, QueryList,
    ViewChild, ViewChildren, ViewContainerRef, ViewEncapsulation, ElementRef, Output, EventEmitter
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, Portal, TemplatePortalDirective } from '@angular/cdk/portal';
import { MatTableDataSource } from '@angular/material';
import { AlertsService, SectionBService, physicians } from '../../../shared/index';


@Component({
    selector: 'app-physician-selector',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhysicianSelectorComponent),
            multi: true
        }
    ],
    templateUrl: './physician-selector.component.html',
    styleUrls: ['./physician-selector.component.css']
})
export class PhysicianSelectorComponent implements OnInit, ControlValueAccessor {

    //dataSource = new MatTableDataSource<phy.Physician>(phy.physician_data);
    dataSource: MatTableDataSource<physicians>;
    displayedColumns = ['lic', 'fname', 'lname'];
    selectedPhysician: physicians;
    PhysicianName: FormControl;
    toggleSelection = true;
    ShowPhysicianGrid = false;
    isPhysicianLoaded = false;


    @ViewChild('physicianSelect', { static: true }) physicianSelect: TemplatePortalDirective;
    @ViewChild('physicianSelectInp', { static: true }) physicianSelectInp: ElementRef;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    @Input()
    name: string;

    @Input('value')
    val: string;

    @Input('placeholder')
    placeholder: string;

    @Input('hintLabel')
    hintLabel: string;

    @Output() physicianDataLoaded: EventEmitter<any> = new EventEmitter();

    constructor(
        private sectionbservice: SectionBService,
        private alertservice: AlertsService,
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef
    ) {
        this.PhysicianName = new FormControl('');
        this.selectedPhysician = { fname: '', lname: '', lic:'' };
    }

    ngOnInit() {

        //load physicians---<
        //this.sectionbservice.getPhysicians().subscribe(data => {
        //  //console.log(data);
        //  this.physicianDataLoaded.emit(data);
        //  this.isPhysicianLoaded = true;
        //  this.dataSource = new MatTableDataSource<phy.Physician>(data);
        //  this.dataSource.paginator = this.paginator;
        //  this.dataSource.filterPredicate = (row: phy.Physician, filter: string): boolean => {
        //    //console.log('filter predicate called ');
        //    if (filter.indexOf(',') > 0) {
        //      let fullname = `${row.pH_LASTNAME} ,${row.pH_FIRSTNAME}`.toLowerCase();
        //      if (fullname.indexOf(filter.toLocaleLowerCase()) > -1) {
        //        return true;
        //      } else {
        //        return false;
        //      }
        //    }
        //    //first name
        //    if (
        //      (row.licenseno && row.licenseno.toLowerCase().indexOf(filter) > -1)
        //      || (row.pH_FIRSTNAME && row.pH_FIRSTNAME.toLowerCase().indexOf(filter) > -1)
        //      || (row.pH_LASTNAME && row.pH_LASTNAME.toLowerCase().indexOf(filter) > -1)
        //    ) {
        //      return true;
        //    } else {
        //      return false;
        //    }

        //  }
        //}, error => {
        //  console.log('error loading physicians data ', error);
        //  this.alertservice.error('error loading physicians data ');
        //}, () => {
        //  this.isPhysicianLoaded = true;
        //});
        //load physicians -->



        this.value = '';
    }

    loadPhysicians(data: physicians[]) {
        this.isPhysicianLoaded = true;
        this.dataSource = new MatTableDataSource<physicians>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (row: physicians, filter: string): boolean => {
            //console.log('filter predicate called ');
            if (filter.indexOf(',') > 0) {
                let fullname = `${row.lname} ,${row.fname}`.toLowerCase();
                if (fullname.indexOf(filter.toLocaleLowerCase()) > -1) {
                    return true;
                } else {
                    return false;
                }
            }
            //first name
            if (
                (row.lic && row.lic.toLowerCase().indexOf(filter) > -1)
                || (row.fname && row.fname.toLowerCase().indexOf(filter) > -1)
                || (row.lname && row.lname.toLowerCase().indexOf(filter) > -1)
            ) {
                return true;
            } else {
                return false;
            }

        }
    }

    onChange: any = () => { };
    onTouched: any = () => { };

    get value() {
        return this.val;
    }

    set value(val) {
        this.val = val;
        this.onChange(val);
        this.onTouched();
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    writeValue(value) {
        if (value) {
            this.value = value;
        }
        //console.log('write value ', value, this.value);
    }

    //openPhysician() {
    //  //let f = new FlexibleConnectedPositionStrategy(this.physicianSelectInp);
    //  let strategy = this.overlay.position().flexibleConnectedTo(this.physicianSelectInp);
    //      //this.physicianSelectInp,
    //      //{ originX: 'start', originY: 'bottom' },
    //      //{ overlayX: 'start', overlayY: 'top' });

    //  let config = new OverlayConfig({ positionStrategy: strategy });
    //  let overlayRef = this.overlay.create(config);

    //  overlayRef.attach(this.physicianSelect);

    //}

    ToggleSelect(selected: physicians) {
        this.selectedPhysician = selected;
        this.value = `${selected.lname} ,${selected.fname}`;
        this.ShowPhysicianGrid = false;
        //console.log(this.selectedPhysician);
    }


    SelectPhysicianClick() {
        this.ShowPhysicianGrid = !this.ShowPhysicianGrid;
    }

    applyFilter(event) {
        //console.log(event);
        if (event.code == 'Enter') {// show tbl on enter
            this.ShowPhysicianGrid = true;
        }
        if (event.code == 'Escape') {// close on escape
            this.ShowPhysicianGrid = false;
        }
        this.dataSource.filter = event.target.value.trim().toLowerCase();
    }

}
