import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { SectionCComponent } from './section-c/section-c.component';

@Injectable({
  providedIn: 'root'
})
export class AdlsUnsaveGuardGuard implements CanDeactivate<SectionCComponent> {
    canDeactivate(component: SectionCComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!component.canNavigateAway()) {
            return window.confirm(`There are unsaved changes. \nDo you wish to navigate away ?`);
        } else {
            return component.canNavigateAway();
        }

    }
}
