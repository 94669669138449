import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SectionEService {

  constructor(private apiService: ApiService) { }

  getSectionE(alocqId): Observable<any> {
    return this.apiService.get('sectionE/' + alocqId).pipe(map(data => {
      return data;
    }));
  }

  updateSectionE(sectionE) {
    return this.apiService.put('sectionE', sectionE).pipe(map(data => {
      return data;
    }));
  }

  createSection(formData): any {
    return this.apiService.post('sectionE', formData);
  }


}
