import { Component, OnInit, ViewChild, HostListener, OnDestroy, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { SectionB1Service, AlertsService, SectionDropdownServiceService, DataService } from '../../shared/index';
import { Subject, forkJoin, Subscription, Observable } from 'rxjs';
import { SubSectionComponent } from './sub-section/sub-section.component';
import { SectionB1ComponentServiceService } from './section-b1-component-service.service';
import { SectionB1DataRow } from './section-b1-data-row';
import { SectionB1Dropdown } from './section-b1-dropdown';
import { DatePipe } from '@angular/common';
import { handleNAchangesInSubSection } from './section-b1-nachanges';
import { configureDynamicValidation } from './section-b1-dyn-validation';
import * as mapData from './section-b1-util';
import { timer, interval } from 'rxjs';
import { filter, debounceTime, window } from "rxjs/operators";
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { validateFutureDate } from '../../shared/validators/AlocDateValidators';
import { SkillLevelOptions } from './section-b1-util';


@Component({
    selector: 'app-section-b1',
    templateUrl: './section-b1.component.html',
    styleUrls: ['./section-b1.component.css'],
    providers: [DatePipe]
})
export class SectionB1Component implements OnInit, OnDestroy {


    autoSaveReady = false;
    formchanged = new Subject<{ title: string, data: SectionB1DataRow }>();

    BowelColostomy: FormGroup;
    Dialysis: FormGroup;
    IVS: FormGroup;
    ParenteralNutrition: FormGroup;
    TubeFeedings: FormGroup;
    Oxygen: FormGroup;
    DeepSuctioning: FormGroup;
    Tracheostomy: FormGroup;
    Ventilator: FormGroup;
    RespiratoryTreatment: FormGroup;
    RespiratoryTreatmentBiPAP: FormGroup;
    RespiratoryTreatmentIPPB: FormGroup;
    RespiratoryTreatmentChestPercussion: FormGroup;
    RespiratoryTreatmentAssistDevice: FormGroup;
    UrinaryCatheterSkilledTasks: FormGroup;
    SeizureManagement: FormGroup;
    GlucoseChecks: FormGroup;
    OtherSkilledNursingService: FormGroup;
    OtherSkilledRehabilitationService: FormGroup;

    ALOC_ID = '';
    showOtherSkilledRehabilitationServiceComp = false;
    showOtherSkilledNursingService = false;
    unsavedChangesPresent = false; //-- Save icon is not needed TFS# 2418
    mode: string;

    autoSaveClock: Subscription;

    @ViewChildren(SubSectionComponent)
    allSections: QueryList<SubSectionComponent>;

    @ViewChild('BowelorColostomyRow', { static: true })
    BowelorColostomyComp: SubSectionComponent;

    @ViewChild('DialysisRow', { static: true })
    DialysisComp: SubSectionComponent;

    @ViewChild('IVsPeripheralCentralLines', { static: true })
    IVsPeripheralCentralLinesComp: SubSectionComponent;

    @ViewChild('InjectionsComp', { static: true })
    InjectionsComp: SubSectionComponent;

    @ViewChild('TOTALPARENTERALNUTRITION', { static: true })
    TOTALPARENTERALNUTRITIONComp: SubSectionComponent

    @ViewChild('TubeFeedingsComp', { static: true })
    TubeFeedingsComp: SubSectionComponent

    @ViewChild('OxygenComp', { static: true })
    OxygenComp: SubSectionComponent;

    @ViewChild('DeepSuctioningComp', { static: true })
    DeepSuctioningComp: SubSectionComponent;

    @ViewChild('TRACHEOSTOMYComp', { static: true })
    TRACHEOSTOMYComp: SubSectionComponent;

    @ViewChild('VentilatorComp', { static: true })
    VentilatorComp: SubSectionComponent;

    @ViewChild('RespiratoryTreatmentComp', { static: true })
    RespiratoryTreatmentComp: SubSectionComponent;

    @ViewChild('RespiratoryTreatmentBiPAPComp', { static: true })
    RespiratoryTreatmentBiPAPComp: SubSectionComponent;

    @ViewChild('RESPIRATORYTREATMENTIPPBComp', { static: true })
    RESPIRATORYTREATMENTIPPBComp: SubSectionComponent;

    @ViewChild('RESPIRATORYTREATMENTCHESTPERCUSSIONComp', { static: true })
    RESPIRATORYTREATMENTCHESTPERCUSSIONComp: SubSectionComponent;

    @ViewChild('RespiratoryTreatmentAssistDeviceComp', { static: true })
    RespiratoryTreatmentAssistDeviceComp: SubSectionComponent;

    @ViewChild('UrinaryCatheterUrostomyRelatedSkilledTasksComp', { static: true })
    UrinaryCatheterUrostomyRelatedSkilledTasksComp: SubSectionComponent;

    @ViewChild('SeizureManagementComp', { static: true })
    SeizureManagementComp: SubSectionComponent;

    @ViewChild('GlucoseChecksComp', { static: true })
    GlucoseChecksComp: SubSectionComponent;

    @ViewChild('WoundCareComp', { static: true })
    WoundCareComp: SubSectionComponent;

    @ViewChild('SpecialSkinCareComp', { static: true })
    SpecialSkinCareComp: SubSectionComponent;

    @ViewChild('OtherSkilledNursingServiceComp', { static: true })
    OtherSkilledNursingServiceComp: SubSectionComponent;

    @ViewChild('PHYSICALTHERAPYComp', { static: true })
    PHYSICALTHERAPYComp: SubSectionComponent;

    @ViewChild('OccupationalTherapyComp', { static: true })
    OccupationalTherapyComp: SubSectionComponent;

    @ViewChild('SpeechLanguagePathologyComp', { static: true })
    SpeechLanguagePathologyComp: SubSectionComponent;

    @ViewChild('OtherSkilledRehabilitationServiceComp', { static: true })
    OtherSkilledRehabilitationServiceComp: SubSectionComponent;

    starttime: Date;

    //section drop down values
    public dropDownValues: SectionB1Dropdown;

    get isBowelColostomyValid() {
        let valid = (this.BowelorColostomyComp.sectionForm.valid && this.BowelColostomy.valid)
            || (this.BowelorColostomyComp.sectionForm.untouched && this.BowelColostomy.untouched);
        return valid;
    }
    get isDialysisValid() {
        return (this.DialysisComp.NotApplicableOption.value || (this.DialysisComp.sectionForm.valid && this.Dialysis.valid) ||
            (this.DialysisComp.sectionForm.untouched && this.Dialysis.untouched));
    }
    get isIVSValid() {
        return this.IVsPeripheralCentralLinesComp.NotApplicableOption.value || (this.IVsPeripheralCentralLinesComp.sectionForm.valid && this.IVS.valid) ||
            (this.IVsPeripheralCentralLinesComp.sectionForm.untouched && this.IVS.untouched);
    }
    get isInjectionsValid() {
        return this.InjectionsComp.NotApplicableOption.value || this.InjectionsComp.sectionForm.valid || this.InjectionsComp.sectionForm.untouched;
    }
    get isParenteralNutritionValid() {
        return (this.TOTALPARENTERALNUTRITIONComp.NotApplicableOption.value || (this.TOTALPARENTERALNUTRITIONComp.sectionForm.valid && this.ParenteralNutrition.valid) ||
            (this.TOTALPARENTERALNUTRITIONComp.sectionForm.untouched && this.ParenteralNutrition.untouched));
    }
    get isTubeFeedingsValid() {
        return (this.TubeFeedingsComp.NotApplicableOption.value || this.TubeFeedingsComp.sectionForm.valid && this.TubeFeedings.valid) ||
            (this.TubeFeedingsComp.sectionForm.untouched && this.TubeFeedings.untouched);
    }
    get isOxygenValid() {
        return this.OxygenComp.NotApplicableOption.value || (this.OxygenComp.sectionForm.valid && this.Oxygen.valid) ||
            (this.OxygenComp.sectionForm.untouched && this.Oxygen.untouched);
    }
    get isDeepSuctioningValid() {
        return this.DeepSuctioningComp.NotApplicableOption.value || (this.DeepSuctioningComp.sectionForm.valid && this.DeepSuctioning.valid) ||
            (this.DeepSuctioningComp.sectionForm.untouched && this.DeepSuctioning.untouched);
    }
    get isTRACHEOSTOMYValid() {
        return this.TRACHEOSTOMYComp.NotApplicableOption.value || this.TRACHEOSTOMYComp.sectionForm.valid || this.TRACHEOSTOMYComp.sectionForm.untouched;
    }
    get isVentilatorValid() {
        return this.VentilatorComp.NotApplicableOption.value || (this.VentilatorComp.sectionForm.valid && this.Ventilator.valid) ||
            (this.VentilatorComp.sectionForm.untouched && this.Ventilator.untouched);
    }
    get isRespiratoryTreatmentValid() {
        return this.RespiratoryTreatmentComp.NotApplicableOption.value || (this.RespiratoryTreatmentComp.sectionForm.valid && this.RespiratoryTreatment.valid) ||
            (this.RespiratoryTreatmentComp.sectionForm.untouched && this.RespiratoryTreatment.untouched);
    }
    get isRespiratoryTreatmentBiPAPValid() {
        return this.RespiratoryTreatmentBiPAPComp.NotApplicableOption.value || (this.RespiratoryTreatmentBiPAPComp.sectionForm.valid && this.RespiratoryTreatmentBiPAP.valid) ||
            (this.RespiratoryTreatmentBiPAPComp.sectionForm.untouched && this.RespiratoryTreatmentBiPAP.untouched);
    }
    get isRespiratoryTreatmentIPPBValid() {
        return (this.RESPIRATORYTREATMENTIPPBComp.NotApplicableOption.value || this.RESPIRATORYTREATMENTIPPBComp.sectionForm.valid && this.RespiratoryTreatmentIPPB.valid) ||
            (this.RESPIRATORYTREATMENTIPPBComp.sectionForm.untouched && this.RespiratoryTreatmentIPPB.untouched);
    }
    get isRespiratoryTreatmentChestPercussionValid() {
        return this.RESPIRATORYTREATMENTCHESTPERCUSSIONComp.NotApplicableOption.value || (this.RESPIRATORYTREATMENTCHESTPERCUSSIONComp.sectionForm.valid && this.RespiratoryTreatmentChestPercussion.valid) ||
            (this.RESPIRATORYTREATMENTCHESTPERCUSSIONComp.sectionForm.untouched && this.RespiratoryTreatmentChestPercussion.untouched);
    }
    get isRespiratoryTreatmentAssistDeviceValid() {
        return this.RespiratoryTreatmentAssistDeviceComp.NotApplicableOption.value || (this.RespiratoryTreatmentAssistDeviceComp.sectionForm.valid && this.RespiratoryTreatmentAssistDevice.valid) ||
            (this.RespiratoryTreatmentAssistDeviceComp.sectionForm.untouched && this.RespiratoryTreatmentAssistDevice.untouched);
    }
    get isUrinaryCatheterSkilledTasksValid() {
        return this.UrinaryCatheterUrostomyRelatedSkilledTasksComp.NotApplicableOption.value || (this.UrinaryCatheterUrostomyRelatedSkilledTasksComp.sectionForm.valid && this.UrinaryCatheterSkilledTasks.valid) ||
            (this.UrinaryCatheterUrostomyRelatedSkilledTasksComp.sectionForm.untouched && this.UrinaryCatheterSkilledTasks.untouched);
    }
    get isSeizureManagementValid() {
        return this.SeizureManagementComp.NotApplicableOption.value || (this.SeizureManagementComp.sectionForm.valid && this.SeizureManagement.valid) ||
            (this.SeizureManagementComp.sectionForm.untouched && this.SeizureManagement.untouched);
    }
    get isGlucoseChecksValid() {
        return this.GlucoseChecksComp.NotApplicableOption.value || (this.GlucoseChecksComp.sectionForm.valid && this.GlucoseChecks.valid) ||
            (this.GlucoseChecksComp.sectionForm.untouched && this.GlucoseChecks.untouched);
    }
    get isWoundCareCompValid() {
        return this.WoundCareComp.NotApplicableOption.value || this.WoundCareComp.sectionForm.valid || this.WoundCareComp.sectionForm.untouched;
    }
    get isSpecialSkinCareCompValid() {
        return this.SpecialSkinCareComp.NotApplicableOption.value || this.SpecialSkinCareComp.sectionForm.valid || this.SpecialSkinCareComp.sectionForm.untouched;
    }
    get isOtherSkilledNursingServiceValid() {
        return this.OtherSkilledNursingServiceComp.NotApplicableOption.value || (this.OtherSkilledNursingServiceComp.sectionForm.valid && this.OtherSkilledNursingService.valid) ||
            (this.OtherSkilledNursingServiceComp.sectionForm.untouched && this.OtherSkilledNursingService.untouched);
    }
    get isPHYSICALTHERAPYValid() {
        return this.PHYSICALTHERAPYComp.NotApplicableOption.value || this.PHYSICALTHERAPYComp.sectionForm.valid || this.PHYSICALTHERAPYComp.sectionForm.untouched;
    }
    get isOccupationalTherapyValid() {
        return this.OccupationalTherapyComp.NotApplicableOption.value ||
            this.OccupationalTherapyComp.sectionForm.valid || this.OccupationalTherapyComp.sectionForm.untouched;
    }
    get isSpeechLanguagePathologyValid() {
        return this.SpeechLanguagePathologyComp.NotApplicableOption.value ||
            this.SpeechLanguagePathologyComp.sectionForm.valid || this.SpeechLanguagePathologyComp.sectionForm.untouched;
    }
    get isOtherSkilledRehabilitationServiceValid() {
        return this.OtherSkilledRehabilitationServiceComp.NotApplicableOption.value
            || (this.OtherSkilledRehabilitationServiceComp.sectionForm.valid && this.OtherSkilledRehabilitationService.valid)
            || (this.OtherSkilledRehabilitationServiceComp.sectionForm.untouched && this.OtherSkilledRehabilitationService.untouched)
            ;
    }





    constructor(
        public secB1Service: SectionB1Service,
        private fb: FormBuilder,
        private secB1CompService: SectionB1ComponentServiceService,
        private dropDownService: SectionDropdownServiceService,
        private datePipe: DatePipe,
        private alertService: AlertsService,
        public dataService: DataService,
        private router: Router
    ) {
        this.dropDownValues = new SectionB1Dropdown();
        router.events
            .pipe(
                filter(
                    (event: NavigationEvent) => {
                        return (event instanceof NavigationStart);
                    }
                )
            ).subscribe((event: NavigationStart) => {
                //console.log(event);
                this.autoSaveReady = false;
            });

    }

    isSubmitting = false;
    isBowelSkilledTaskOtherSelected = false;
    isTypeofTubeFeedingOtherDisabled = true;
    isOxygenMethodOther = false;
    showTypeofCatheterDate = false;

    stackSectionChanges: { [index: string]: any } = {};
    waitforSectionUpdate = false;

    SectionB1Data = {
        BowelColostomy: new SectionB1DataRow(),
        Dialysis: new SectionB1DataRow(),
        IVS: new SectionB1DataRow(),
        Injections: new SectionB1DataRow(),
        ParenteralNutrition: new SectionB1DataRow(),
        TubeFeedings: new SectionB1DataRow(),
        Oxygen: new SectionB1DataRow(),
        DeepSuctioning: new SectionB1DataRow(),
        Tracheostomy: new SectionB1DataRow(),
        Ventilator: new SectionB1DataRow(),
        RespiratoryTreatment: new SectionB1DataRow(),
        RespiratoryTreatmentBiPAP: new SectionB1DataRow(),
        RespiratoryTreatmentIPPB: new SectionB1DataRow(),
        RespiratoryTreatmentChestPercussion: new SectionB1DataRow(),
        RespiratoryTreatmentAssistDevice: new SectionB1DataRow(),
        UrinaryCatheterSkilledTasks: new SectionB1DataRow(),
        SeizureManagement: new SectionB1DataRow(),
        GlucoseChecks: new SectionB1DataRow(),
        WoundCare: new SectionB1DataRow(),
        SpecialSkinCare: new SectionB1DataRow(),
        OtherSkilledNursingService: new SectionB1DataRow(),
        PhysicalTherapy: new SectionB1DataRow(),
        OccupationalTherapy: new SectionB1DataRow(),
        SpeechLanguagePathology: new SectionB1DataRow(),
        OtherSkilledRehabilitationService: new SectionB1DataRow()
    }




    ngOnInit() {
        this.starttime = new Date();

        this.initForm();

        this.loadData();

    }


    ngOnDestroy(): void {

        this.autoSaveClock.unsubscribe();
        this.autoSaveClock = null;
    }

    initForm() {
      //TFS 2440,2459,2461 Not Required fields changes Commented codebelow
        this.BowelColostomy = new FormGroup({
            SkilledTask: new FormControl(''),
            OtherInfo: new FormControl('default')
        });
        this.Dialysis = new FormGroup({
            TypeOfDialysis: new FormControl(''),
            Location: new FormControl('')
        });
        this.IVS = new FormGroup({
            TypeOfIV: new FormControl(''),
            Location: new FormControl('')
        });
        this.ParenteralNutrition = new FormGroup({
            kcalperday: new FormControl(''),
            mlkgday: new FormControl('')
        });
        this.TubeFeedings = new FormGroup({
            TypeofTubeFeeding: new FormControl(''),
            TypeofTubeFeedingOther: new FormControl(''),
            Duration: new FormControl(''),
            MethodofDelivery: new FormControl('')
        });
        this.Oxygen = new FormGroup({
            OxygenUse: new FormControl(''),
            Method: new FormControl(''),
            MethodOther: new FormControl(''),
            litersPerMinute: new FormControl(''),
            SpecialOrders: new FormControl('')
        });
        this.DeepSuctioning = new FormGroup({
            Types: new FormControl('')
        });
        this.Ventilator = new FormGroup({
            Duration: new FormControl('')
        });
        this.RespiratoryTreatment = new FormGroup({
            Duration: new FormControl('')
        });
        this.RespiratoryTreatmentBiPAP = new FormGroup({
            Duration: new FormControl('')
        });
        this.RespiratoryTreatmentIPPB = new FormGroup({
            Duration: new FormControl('')
        });
        this.RespiratoryTreatmentChestPercussion = new FormGroup({
            Duration: new FormControl('')
        });
        this.RespiratoryTreatmentAssistDevice = new FormGroup({
            Duration: new FormControl('')
        });
        this.UrinaryCatheterSkilledTasks = new FormGroup({
            TypeofCatheter: new FormControl(''),
            Dateoflastchange: new FormControl('')
        });
        this.SeizureManagement = new FormGroup({
            SeizureSeverity: new FormControl(''),
            Dateoflastseizure: new FormControl('')
        });
        this.GlucoseChecks = new FormGroup({
            TypeofGlucoseChecks: new FormControl('')
        });
        this.OtherSkilledNursingService = new FormGroup({
          Service: new FormControl('', Validators.required)
        });
        this.OtherSkilledRehabilitationService = new FormGroup({
          Service: new FormControl('', Validators.required)
        });


        // handle NA changes in each section
        handleNAchangesInSubSection(this);

        //configure dynamic valiation
        configureDynamicValidation(this);

        //wire custom form changes
        this.onSectionCustomFormUpdate();

        //event to capture section updates in stack
        this.formchanged.pipe().subscribe((section) => {
            //console.log(data);
            if (!this.autoSaveReady) { return };
            this.stackSectionChanges[section.title] = section.data;
            this.waitforSectionUpdate = true;
            if (document.title[0] != '*') {
                document.title = "*" + document.title;
            }
            this.unsavedChangesPresent = true;  //-- Save icon is not needed TFS# 2418
        });

        //auto save pool settings
        if (this.autoSaveClock == null) {

            this.autoSaveClock = interval(1000).subscribe(val => {
                if (this.waitforSectionUpdate) {
                    this.waitforSectionUpdate = false;
                    return;
                }
                let sectionFound = false;
                for (let sec in this.stackSectionChanges) {
                    sectionFound = true;
                    //console.log('saving section ', sec);
                    this.stackSectionChanges[sec].aB1_ALOC_ID = this.dataService.getSelectedDocument();

                    let saveObser = this.mode == "NEW" ? this.secB1Service.createSectionB1(this.stackSectionChanges[sec])
                        : this.secB1Service.updateSectionB1(this.stackSectionChanges[sec]);

                    saveObser.subscribe(resp => {
                        let msg = `${sec} Section B1 Saved`;
                        //console.log(msg, resp);
                        this.alertService.success(msg);
                        delete this.stackSectionChanges[sec];
                        this.mode == "EDIT";
                    }, err => {
                        let msg = `Error saving ${sec} !`;
                        //console.error(msg, err);
                        //this.alertService.error(msg);
                    });

                }
                if (!sectionFound) {
                    document.title = document.title.replace('*', '');
                    this.unsavedChangesPresent = false;  //-- Save icon is not needed TFS# 2418
                }

            });
        }


    }

    loadData() {
        this.loadDropdowns().subscribe(() => {
            //load b1 document
            this.loadSelectedDocument();
        });
    }


    //submitForm() {
    //    this.validateSections();

    //    let dataToPut = [];
    //    for (let sec in this.SectionB1Data) {
    //        dataToPut.push(this.SectionB1Data[sec]);
    //    }
    //    let saveStatus = {};
    //    // console.log(dataToPut);
    //    //console.log(JSON.stringify((dataToPut[0])));
    //    //return;
    //    for (let sec of dataToPut) {
    //        console.log('saving section ', sec);
    //        console.log(this.mode);
    //        let saveObserver = this.mode == "NEW" ? this.secB1Service.updateSectionB1(sec) : this.secB1Service.createSectionB1(sec);
    //        saveObserver.subscribe(resp => {
    //            console.log('successfully saved section ', resp);
    //            this.alertService.success('Section saved.!');
    //        }, err => {
    //            console.log('Error saving section.', err);
    //            this.alertService.error('Error saving section.');
    //        });
    //    }

    //}

    //saveSection(section: string): void {
    //    //console.log('this.autoSaveReady ', this.autoSaveReady);
    //    if (!this.autoSaveReady) { return };
    //    //switch (section) {
    //    //  case 'Bowel or Colostomy':
    //    //    if (!this.isBowelColostomyValid)
    //    //      return;
    //    //    break;
    //    //};
    //    console.log('saving section ', section);
    //    this.SectionB1Data[section].aB1_ALOC_ID = this.dataService.getSelectedDocument();
    //    console.log(this.mode);
    //    let saveObserver = this.mode == "NEW" ? this.secB1Service.createSectionB1(this.SectionB1Data[section]) : this.secB1Service.updateSectionB1(this.SectionB1Data[section]);
    //    saveObserver.subscribe(resp => {            
    //        let msg = `${section} successfully saved !`;
    //        console.log(msg, resp);
    //        this.alertService.success(msg);
    //        this.loadSelectedDocument();
    //    }, err => {
    //        let msg = `Error saving ${section} !`;
    //        console.log(msg, err);
    //        this.alertService.error(msg);
    //    }, () => {
    //        this.alertService.clearLoader();
    //    });
    //}

    validateSections() {

        this.BowelorColostomyComp.sectionForm.updateValueAndValidity();
        this.DialysisComp.sectionForm.updateValueAndValidity();
        this.IVsPeripheralCentralLinesComp.sectionForm.updateValueAndValidity();
        this.InjectionsComp.sectionForm.updateValueAndValidity();
        this.TOTALPARENTERALNUTRITIONComp.sectionForm.updateValueAndValidity();
        this.TubeFeedingsComp.sectionForm.updateValueAndValidity();
        this.OxygenComp.sectionForm.updateValueAndValidity();
        this.DeepSuctioningComp.sectionForm.updateValueAndValidity();
        this.TRACHEOSTOMYComp.sectionForm.updateValueAndValidity();
        this.VentilatorComp.sectionForm.updateValueAndValidity();
        this.RespiratoryTreatmentComp.sectionForm.updateValueAndValidity();
        this.RespiratoryTreatmentBiPAPComp.sectionForm.updateValueAndValidity();
        this.RESPIRATORYTREATMENTIPPBComp.sectionForm.updateValueAndValidity();
        this.RESPIRATORYTREATMENTCHESTPERCUSSIONComp.sectionForm.updateValueAndValidity();
        this.RespiratoryTreatmentAssistDeviceComp.sectionForm.updateValueAndValidity();
        this.UrinaryCatheterUrostomyRelatedSkilledTasksComp.sectionForm.updateValueAndValidity();
        this.SeizureManagementComp.sectionForm.updateValueAndValidity();
        this.GlucoseChecksComp.sectionForm.updateValueAndValidity();
        this.WoundCareComp.sectionForm.updateValueAndValidity();
        this.SpecialSkinCareComp.sectionForm.updateValueAndValidity();
        this.OtherSkilledNursingServiceComp.sectionForm.updateValueAndValidity();
        this.PHYSICALTHERAPYComp.sectionForm.updateValueAndValidity();
        this.OccupationalTherapyComp.sectionForm.updateValueAndValidity();
        this.SpeechLanguagePathologyComp.sectionForm.updateValueAndValidity();
        this.OtherSkilledRehabilitationServiceComp.sectionForm.updateValueAndValidity();


        this.BowelColostomy.updateValueAndValidity();
        this.Dialysis.updateValueAndValidity();
        this.IVS.updateValueAndValidity();
        this.ParenteralNutrition.updateValueAndValidity();
        this.TubeFeedings.updateValueAndValidity();
        this.Oxygen.updateValueAndValidity();
        this.DeepSuctioning.updateValueAndValidity();
        this.Ventilator.updateValueAndValidity();
        this.RespiratoryTreatment.updateValueAndValidity();
        this.RespiratoryTreatmentBiPAP.updateValueAndValidity();
        this.RespiratoryTreatmentIPPB.updateValueAndValidity();
        this.RespiratoryTreatmentChestPercussion.updateValueAndValidity();
        this.RespiratoryTreatmentAssistDevice.updateValueAndValidity();
        this.UrinaryCatheterSkilledTasks.updateValueAndValidity();
        this.SeizureManagement.updateValueAndValidity();
        this.GlucoseChecks.updateValueAndValidity();
        this.OtherSkilledNursingService.updateValueAndValidity();
        this.OtherSkilledRehabilitationService.updateValueAndValidity();

    }

    loadSelectedDocument() {
        let docid = this.dataService.getSelectedDocument();
        this.secB1Service.getSectionB1(docid).subscribe(alldata => {
            if (!Array.isArray(alldata) || alldata.length == 0) {
                this.alertService.error('No section b1 data found for this record . Please save section A first.!');

                //this.mode = 'NEW';
                this.ALOC_ID = docid;
                this.BowelorColostomyComp.isInitialLoadComplete = true;
                this.DialysisComp.isInitialLoadComplete = true;
                this.IVsPeripheralCentralLinesComp.isInitialLoadComplete = true;
                this.InjectionsComp.isInitialLoadComplete = true;
                this.TOTALPARENTERALNUTRITIONComp.isInitialLoadComplete = true;
                this.TubeFeedingsComp.isInitialLoadComplete = true;
                this.OxygenComp.isInitialLoadComplete = true;
                this.DeepSuctioningComp.isInitialLoadComplete = true;
                this.TRACHEOSTOMYComp.isInitialLoadComplete = true;
                this.VentilatorComp.isInitialLoadComplete = true;
                this.RespiratoryTreatmentComp.isInitialLoadComplete = true;
                this.RespiratoryTreatmentBiPAPComp.isInitialLoadComplete = true;
                this.RESPIRATORYTREATMENTIPPBComp.isInitialLoadComplete = true;
                this.RESPIRATORYTREATMENTCHESTPERCUSSIONComp.isInitialLoadComplete = true;
                this.RespiratoryTreatmentAssistDeviceComp.isInitialLoadComplete = true;
                this.UrinaryCatheterUrostomyRelatedSkilledTasksComp.isInitialLoadComplete = true;
                this.SeizureManagementComp.isInitialLoadComplete = true;
                this.GlucoseChecksComp.isInitialLoadComplete = true;
                this.WoundCareComp.isInitialLoadComplete = true;
                this.SpecialSkinCareComp.isInitialLoadComplete = true;
                this.showOtherSkilledNursingService = true;
                this.PHYSICALTHERAPYComp.isInitialLoadComplete = true;
                this.SpeechLanguagePathologyComp.isInitialLoadComplete = true;
                this.OccupationalTherapyComp.isInitialLoadComplete = true;
                this.OtherSkilledRehabilitationServiceComp.isInitialLoadComplete = true;
                this.showOtherSkilledRehabilitationServiceComp = true;
                this.alertService.clearLoader();
                //this.autoSaveReady = true;
                return;
            } else {
                this.ALOC_ID = alldata[0].aB1_ALOC_ID;
            }
            //console.log('all section b1 data', alldata);
            this.mode = 'EDIT';
            //console.log('this.ALOC_ID ', this.ALOC_ID);
            //assign value to sub section child compoent
            this.secB1CompService.InitSubSectionData(alldata);
            alldata.forEach((row: SectionB1DataRow) => {
                //console.log('row.skilL_ID', row.skilL_ID);
                switch (row.skilL_ID) {

                    //Bowel or Colostomy
                    case this.BowelorColostomyComp.skilL_ID:
                        this.BowelColostomy.get('OtherInfo').setValue(row.aB1_OPTION_COMMENTS);
                        this.BowelColostomy.get('SkilledTask').setValue(row.aB1_OPTION1);
                        this.BowelorColostomyComp.isInitialLoadComplete = true;

                        //this.BowelColostomy.markAsTouched();
                        //this.BowelColostomy.updateValueAndValidity();
                        //this.BowelorColostomyComp.sectionForm.markAsTouched();
                        //this.BowelorColostomyComp.sectionForm.updateValueAndValidity();
                        break;
                    //Dialysis: Hemodialysis or Peritoneal*
                    case this.DialysisComp.skilL_ID:
                        this.Dialysis.setValue({
                            'TypeOfDialysis': row.aB1_OPTION1,
                            'Location': row.aB1_OPTION2
                        });
                        this.DialysisComp.isInitialLoadComplete = true;

                        //this.Dialysis.markAsTouched();
                        //this.Dialysis.updateValueAndValidity();                        
                        //this.DialysisComp.sectionForm.markAsTouched();
                        //this.DialysisComp.sectionForm.updateValueAndValidity();
                        break;
                    //IVs: Peripheral or Central Lines*  IVsPeripheralCentralLinesComp
                    case this.IVsPeripheralCentralLinesComp.skilL_ID:
                        this.IVS.setValue({
                            'TypeOfIV': row.aB1_OPTION1,
                            'Location': row.aB1_OPTION2
                        });
                        this.IVsPeripheralCentralLinesComp.isInitialLoadComplete = true;

                        //this.IVS.markAsTouched();
                        //this.IVS.updateValueAndValidity();
                        //this.IVsPeripheralCentralLinesComp.sectionForm.markAsTouched();
                        //this.IVsPeripheralCentralLinesComp.sectionForm.updateValueAndValidity();
                        break;
                    //INJECTIONS (FOR INTRA-MUSCULAR (IM) ONLY)
                    case this.InjectionsComp.skilL_ID:
                        this.InjectionsComp.isInitialLoadComplete = true;

                        //this.InjectionsComp.sectionForm.markAsTouched();
                        //this.InjectionsComp.sectionForm.updateValueAndValidity();
                        break;
                    //Total Parenteral Nutrition* (TPN)
                    case this.TOTALPARENTERALNUTRITIONComp.skilL_ID:
                        this.ParenteralNutrition.setValue({
                            'kcalperday': row.aB1_OPTION1,
                            'mlkgday': row.aB1_OPTION2
                        });
                        this.TOTALPARENTERALNUTRITIONComp.isInitialLoadComplete = true;

                        //this.ParenteralNutrition.markAsTouched();
                        //this.ParenteralNutrition.updateValueAndValidity();
                        //this.TOTALPARENTERALNUTRITIONComp.sectionForm.markAsTouched();
                        //this.TOTALPARENTERALNUTRITIONComp.sectionForm.updateValueAndValidity();
                        break;
                    //TTUBE FEEDINGS
                    case this.TubeFeedingsComp.skilL_ID:
                        //console.log('tube feeding data ', row);
                        this.TubeFeedings.setValue({
                            'TypeofTubeFeeding': row.aB1_OPTION1,
                            'TypeofTubeFeedingOther': row.aB1_OPTION4,
                            'Duration': row.aB1_OPTION2,
                            'MethodofDelivery': row.aB1_OPTION3
                        });
                        this.TubeFeedingsComp.isInitialLoadComplete = true;

                        //this.TubeFeedings.markAsTouched();
                        //this.TubeFeedings.updateValueAndValidity();
                        //this.TubeFeedingsComp.sectionForm.markAsTouched();
                        //this.TubeFeedingsComp.sectionForm.updateValueAndValidity();
                        break;
                    //OxygenComp
                    case this.OxygenComp.skilL_ID:
                        //console.log('OxygenComp ', row);
                        this.Oxygen.setValue({
                            'OxygenUse': row.aB1_OPTION1,
                            'Method': row.aB1_OPTION2,
                            'MethodOther': row.aB1_OPTION_COMMENTS,
                            'litersPerMinute': row.aB1_OPTION4,
                            'SpecialOrders': row.aB1_OPTION3
                        });
                        this.OxygenComp.isInitialLoadComplete = true;

                        //this.Oxygen.markAsTouched();
                        //this.Oxygen.updateValueAndValidity();
                        //this.OxygenComp.sectionForm.markAsTouched();
                        //this.OxygenComp.sectionForm.updateValueAndValidity();

                        break;
                    //DeepSuctioningComp
                    case this.DeepSuctioningComp.skilL_ID:
                        this.DeepSuctioning.setValue({
                            'Types': row.aB1_OPTION1
                        });
                        this.DeepSuctioningComp.isInitialLoadComplete = true;
                        this.DeepSuctioningComp.skillLevelSettings = SkillLevelOptions.IndependentOnly;

                        //this.DeepSuctioning.markAsTouched();
                        //this.DeepSuctioning.updateValueAndValidity();
                        //this.DeepSuctioningComp.sectionForm.markAsTouched();
                        //this.DeepSuctioningComp.sectionForm.updateValueAndValidity();
                        break;
                    //TRACHEOSTOMY
                    case this.TRACHEOSTOMYComp.skilL_ID:
                        this.TRACHEOSTOMYComp.isInitialLoadComplete = true;

                        //this.TRACHEOSTOMYComp.sectionForm.markAsTouched();
                        //this.TRACHEOSTOMYComp.sectionForm.updateValueAndValidity();

                        break;
                    //VentilatorComp
                    case this.VentilatorComp.skilL_ID:
                        this.Ventilator.setValue({
                            'Duration': row.aB1_OPTION1
                        });
                        this.VentilatorComp.isInitialLoadComplete = true;

                        //this.VentilatorComp.sectionForm.markAsTouched();
                        //this.VentilatorComp.sectionForm.updateValueAndValidity();

                        break;
                    //RespiratoryTreatmentComp
                    case this.RespiratoryTreatmentComp.skilL_ID:
                        this.RespiratoryTreatment.setValue({
                            'Duration': row.aB1_OPTION1
                        });
                        this.RespiratoryTreatmentComp.isInitialLoadComplete = true;

                        //this.RespiratoryTreatment.markAsTouched();
                        //this.RespiratoryTreatment.updateValueAndValidity();
                        //this.RespiratoryTreatmentComp.sectionForm.markAsTouched();
                        //this.RespiratoryTreatmentComp.sectionForm.updateValueAndValidity();

                        break;
                    //RespiratoryTreatmentBiPAPComp
                    case this.RespiratoryTreatmentBiPAPComp.skilL_ID:
                        this.RespiratoryTreatmentBiPAP.setValue({
                            'Duration': row.aB1_OPTION1
                        });
                        this.RespiratoryTreatmentBiPAPComp.isInitialLoadComplete = true;

                        //this.RespiratoryTreatmentBiPAP.markAsTouched();
                        //this.RespiratoryTreatmentBiPAP.updateValueAndValidity();
                        //this.RespiratoryTreatmentBiPAPComp.sectionForm.markAsTouched();
                        //this.RespiratoryTreatmentBiPAPComp.sectionForm.updateValueAndValidity();

                        break;
                    //RESPIRATORYTREATMENTIPPBComp
                    case this.RESPIRATORYTREATMENTIPPBComp.skilL_ID:
                        this.RespiratoryTreatmentIPPB.setValue({
                            'Duration': row.aB1_OPTION1
                        });
                        this.RESPIRATORYTREATMENTIPPBComp.isInitialLoadComplete = true;

                        //this.RespiratoryTreatmentIPPB.markAsTouched();
                        //this.RespiratoryTreatmentIPPB.updateValueAndValidity();
                        //this.RESPIRATORYTREATMENTIPPBComp.sectionForm.markAsTouched();
                        //this.RESPIRATORYTREATMENTIPPBComp.sectionForm.updateValueAndValidity();


                        break;
                    //RESPIRATORYTREATMENTCHESTPERCUSSIONComp
                    case this.RESPIRATORYTREATMENTCHESTPERCUSSIONComp.skilL_ID:
                        this.RespiratoryTreatmentChestPercussion.setValue({
                            'Duration': row.aB1_OPTION1
                        });
                        this.RESPIRATORYTREATMENTCHESTPERCUSSIONComp.isInitialLoadComplete = true;

                        //this.RespiratoryTreatmentChestPercussion.markAsTouched();
                        //this.RespiratoryTreatmentChestPercussion.updateValueAndValidity();
                        //this.RESPIRATORYTREATMENTCHESTPERCUSSIONComp.sectionForm.markAsTouched();
                        //this.RESPIRATORYTREATMENTCHESTPERCUSSIONComp.sectionForm.updateValueAndValidity();

                        break;
                    //RespiratoryTreatmentAssistDeviceComp
                    case this.RespiratoryTreatmentAssistDeviceComp.skilL_ID:
                        this.RespiratoryTreatmentAssistDevice.setValue({
                            'Duration': row.aB1_OPTION1
                        });
                        this.RespiratoryTreatmentAssistDeviceComp.isInitialLoadComplete = true;

                        //this.RespiratoryTreatmentAssistDevice.markAsTouched();
                        //this.RespiratoryTreatmentAssistDevice.updateValueAndValidity();
                        //this.RespiratoryTreatmentAssistDeviceComp.sectionForm.markAsTouched();
                        //this.RespiratoryTreatmentAssistDeviceComp.sectionForm.updateValueAndValidity();


                        break;
                    //UrinaryCatheterUrostomyRelatedSkilledTasksComp
                    case this.UrinaryCatheterUrostomyRelatedSkilledTasksComp.skilL_ID:
                        //console.log('UrinaryCatheterUrostomyRelatedSkilledTasksComp ', row);
                        this.UrinaryCatheterSkilledTasks.setValue({
                            'TypeofCatheter': row.aB1_OPTION1,
                            'Dateoflastchange': row.aB1_OPTION2 != null ? new Date(row.aB1_OPTION2) : null
                        });
                        this.UrinaryCatheterUrostomyRelatedSkilledTasksComp.isInitialLoadComplete = true;

                        //this.UrinaryCatheterSkilledTasks.markAsTouched();
                        //this.UrinaryCatheterSkilledTasks.updateValueAndValidity();
                        //this.UrinaryCatheterUrostomyRelatedSkilledTasksComp.sectionForm.markAsTouched();
                        //this.UrinaryCatheterUrostomyRelatedSkilledTasksComp.sectionForm.updateValueAndValidity();

                        break;
                    //SeizureManagementComp
                    case this.SeizureManagementComp.skilL_ID:
                        //console.log('SeizureManagementComp ', row);
                        this.SeizureManagement.setValue({
                            'SeizureSeverity': row.aB1_OPTION1,
                            'Dateoflastseizure': row.aB1_OPTION2 != null ? new Date(row.aB1_OPTION2) : null
                        });
                        this.SeizureManagementComp.isInitialLoadComplete = true;
                        this.SeizureManagementComp.skillLevelSettings = SkillLevelOptions.IndependentOnly;

                        //this.SeizureManagement.markAsTouched();
                        //this.SeizureManagement.updateValueAndValidity();
                        //this.SeizureManagementComp.sectionForm.markAsTouched();
                        //this.SeizureManagementComp.sectionForm.updateValueAndValidity();

                        break;
                    //GlucoseChecksComp
                    case this.GlucoseChecksComp.skilL_ID:
                        //console.log('GlucoseChecksComp ', row);
                        this.GlucoseChecks.setValue({
                            'TypeofGlucoseChecks': row.aB1_OPTION1
                        });
                        this.GlucoseChecksComp.isInitialLoadComplete = true;

                        //this.GlucoseChecksComp.sectionForm.markAsTouched();
                        //this.GlucoseChecksComp.sectionForm.updateValueAndValidity();

                        break;
                    //Wound Care*
                    case this.WoundCareComp.skilL_ID:
                        //console.log('Wound Care ', row);
                        this.WoundCareComp.isInitialLoadComplete = true;

                        //this.WoundCareComp.sectionForm.markAsTouched();
                        //this.WoundCareComp.sectionForm.updateValueAndValidity();

                        break;
                    //Special Skin Care*
                    case this.SpecialSkinCareComp.skilL_ID:
                        //console.log('Special Skin Care*', row);
                        this.SpecialSkinCareComp.isInitialLoadComplete = true;

                        //this.SpecialSkinCareComp.sectionForm.markAsTouched();
                        //this.SpecialSkinCareComp.sectionForm.updateValueAndValidity();

                        break;
                    //Other Skilled Nursing Service*
                    case this.OtherSkilledNursingServiceComp.skilL_ID:
                        //console.log('Other Skilled Nursing Service*', row);
                        this.OtherSkilledNursingService.setValue({
                            Service: row.aB1_OPTION_COMMENTS
                        });
                        this.OtherSkilledNursingServiceComp.isInitialLoadComplete = true;
                        this.showOtherSkilledNursingService = true;

                        //this.OtherSkilledNursingService.markAsTouched();
                        //this.OtherSkilledNursingService.updateValueAndValidity();
                        //this.OtherSkilledNursingServiceComp.sectionForm.markAsTouched();
                        //this.OtherSkilledNursingServiceComp.sectionForm.updateValueAndValidity();

                        break;
                    //Physical Therapy*
                    case this.PHYSICALTHERAPYComp.skilL_ID:
                        this.PHYSICALTHERAPYComp.isInitialLoadComplete = true;
                        this.PHYSICALTHERAPYComp.skillLevelSettings = SkillLevelOptions.IndependentOnly;

                        //this.PHYSICALTHERAPYComp.sectionForm.markAsTouched();
                        //this.PHYSICALTHERAPYComp.sectionForm.updateValueAndValidity();

                        break;
                    //Occupational Therapy*
                    case this.OccupationalTherapyComp.skilL_ID:
                        this.OccupationalTherapyComp.isInitialLoadComplete = true;
                        this.OccupationalTherapyComp.skillLevelSettings = SkillLevelOptions.IndependentOnly;

                        //this.OccupationalTherapyComp.sectionForm.markAsTouched();
                        //this.OccupationalTherapyComp.sectionForm.updateValueAndValidity();

                        break;
                    //Speech-Language Pathology*
                    case this.SpeechLanguagePathologyComp.skilL_ID:
                        this.SpeechLanguagePathologyComp.isInitialLoadComplete = true;
                        this.SpeechLanguagePathologyComp.skillLevelSettings = SkillLevelOptions.IndependentOnly;

                        //this.SpeechLanguagePathologyComp.sectionForm.markAsTouched();
                        //this.SpeechLanguagePathologyComp.sectionForm.updateValueAndValidity();

                        break;
                    //Other Skilled Rehabilitation Service
                    case this.OtherSkilledRehabilitationServiceComp.skilL_ID:
                        this.OtherSkilledRehabilitationService.setValue({
                            Service: row.aB1_OPTION_COMMENTS
                        });
                        this.OtherSkilledRehabilitationServiceComp.skillLevelSettings = SkillLevelOptions.IndependentOnly;
                        this.OtherSkilledRehabilitationServiceComp.isInitialLoadComplete = true;
                        this.showOtherSkilledRehabilitationServiceComp = true;

                        //this.OtherSkilledRehabilitationService.markAsTouched();
                        //this.OtherSkilledRehabilitationService.updateValueAndValidity();
                        //this.OtherSkilledRehabilitationServiceComp.sectionForm.markAsTouched();
                        //this.OtherSkilledRehabilitationServiceComp.sectionForm.updateValueAndValidity();

                        break;


                    default:
                        //console.warn(' un identified row !!', row);
                        break;

                }

            });
            this.alertService.success('Section B1 Loaded');
        },
            error => {
                console.error('error loading section b1 data ', error);
                if (error.error.code) {
                    this.alertService.error(`Code :${error.error.code} - Message :${error.error.msg}`);
                } else {

                    this.alertService.error('Error Loading Section B1 ');
                }

            }, () => {
                const autoSaveEnableTimer = timer(1000);
                autoSaveEnableTimer.subscribe(val => {
                    this.autoSaveReady = true;
                    //console.log('auto save enabled');
                });
                console.log('B1 load time ', ((new Date()).getTime() - this.starttime.getTime() )/1000 ,' s');
            }

        );


    }

    onSectionUpdate($event: SectionB1DataRow, heading: string): void {
        switch (heading) {
            case 'Bowel or Colostomy':
                this.SectionB1Data.BowelColostomy = mapData.mapCommonSection($event, this.SectionB1Data.BowelColostomy);
                //this.saveSection('BowelColostomy');
                this.formchanged.next({ title: 'Bowel or Colostomy', data: this.SectionB1Data.BowelColostomy });
                //this.SectionB1Data.BowelColostomy.Custom = this.BowelColostomy.value;
                break;
            case 'Dialysis: Hemodialysis or Peritoneal':
                this.SectionB1Data.Dialysis = mapData.mapCommonSection($event, this.SectionB1Data.Dialysis);
                this.formchanged.next({ title: 'Dialysis: Hemodialysis or Peritoneal', data: this.SectionB1Data.Dialysis });
                //this.SectionB1Data.Dialysis.Custom = this.Dialysis.value;
                break;
            case 'IVS':
                this.SectionB1Data.IVS = mapData.mapCommonSection($event, this.SectionB1Data.IVS);
                this.formchanged.next({ title: 'IVS: PERIPHERAL OR CENTRAL LINES', data: this.SectionB1Data.IVS });
                //this.SectionB1Data.IVS.Custom = this.IVS.value;
                break;
            case 'Injections':
                this.SectionB1Data.Injections = mapData.mapCommonSection($event, this.SectionB1Data.Injections);
                this.formchanged.next({ title: 'INJECTIONS (FOR INTRA-MUSCULAR (IM) ONLY)', data: this.SectionB1Data.Injections });
                //this.SectionB1Data.Injections.Custom = null;
                break;
            case 'TPN':
                this.SectionB1Data.ParenteralNutrition = mapData.mapCommonSection($event, this.SectionB1Data.ParenteralNutrition);
                this.formchanged.next({ title: 'Total Parenteral Nutrition* (TPN)', data: this.SectionB1Data.ParenteralNutrition });
                //this.SectionB1Data.ParenteralNutrition.Custom = this.ParenteralNutrition.value;
                break;
            case 'TubeFeedings':
                this.SectionB1Data.TubeFeedings = mapData.mapCommonSection($event, this.SectionB1Data.TubeFeedings);
                this.formchanged.next({ title: 'Tube Feedings*', data: this.SectionB1Data.TubeFeedings });
                //this.SectionB1Data.TubeFeedings.Custom = this.TubeFeedings.value;
                break;
            case 'Oxygen':
                this.SectionB1Data.Oxygen = mapData.mapCommonSection($event, this.SectionB1Data.Oxygen);
                this.formchanged.next({ title: 'Oxygen*', data: this.SectionB1Data.Oxygen });
                //this.SectionB1Data.Oxygen.Custom = this.Oxygen.value;
                break;
            case 'Deep Suctioning':
                this.SectionB1Data.DeepSuctioning = mapData.mapCommonSection($event, this.SectionB1Data.DeepSuctioning);
                this.formchanged.next({ title: 'Deep Suctioning*', data: this.SectionB1Data.DeepSuctioning });
                //this.SectionB1Data.DeepSuctioning.Custom = this.DeepSuctioning.value;
                break;
            case 'Tracheostomy':
                this.SectionB1Data.Tracheostomy = mapData.mapCommonSection($event, this.SectionB1Data.Tracheostomy);
                this.formchanged.next({ title: 'Tracheostomy*', data: this.SectionB1Data.Tracheostomy });
                //this.SectionB1Data.Tracheostomy.Custom = null;
                break;
            case 'Ventilator':
                this.SectionB1Data.Ventilator = mapData.mapCommonSection($event, this.SectionB1Data.Ventilator);
                this.formchanged.next({ title: 'Ventilator', data: this.SectionB1Data.Ventilator });
                //this.SectionB1Data.Ventilator.Custom = this.Ventilator.value;
                break;
            case 'RespiratoryTreatment':
                this.SectionB1Data.RespiratoryTreatment = mapData.mapCommonSection($event, this.SectionB1Data.RespiratoryTreatment);
                this.formchanged.next({ title: 'Respiratory Treatment: C-PAP*', data: this.SectionB1Data.RespiratoryTreatment });
                //this.SectionB1Data.RespiratoryTreatment.Custom = this.RespiratoryTreatment.value;
                break;
            case 'RespiratoryTreatmentBiPAP':
                this.SectionB1Data.RespiratoryTreatmentBiPAP = mapData.mapCommonSection($event, this.SectionB1Data.RespiratoryTreatmentBiPAP);
                this.formchanged.next({ title: 'Respiratory Treatment: Bi-PAP*', data: this.SectionB1Data.RespiratoryTreatmentBiPAP });
                //this.SectionB1Data.RespiratoryTreatmentBiPAP.Custom = this.RespiratoryTreatmentBiPAP.value;
                break;
            case 'RespiratoryTreatmentIPPB':
                this.SectionB1Data.RespiratoryTreatmentIPPB = mapData.mapCommonSection($event, this.SectionB1Data.RespiratoryTreatmentIPPB);
                this.formchanged.next({ title: 'Respiratory Treatment: IPPB*', data: this.SectionB1Data.RespiratoryTreatmentIPPB });
                //this.SectionB1Data.RespiratoryTreatmentIPPB.Custom = this.RespiratoryTreatmentIPPB.value;
                break;
            case 'RespiratoryTreatmentChestPercussion':
                this.SectionB1Data.RespiratoryTreatmentChestPercussion = mapData.mapCommonSection($event, this.SectionB1Data.RespiratoryTreatmentChestPercussion);
                this.formchanged.next({ title: 'Respiratory Treatment: Chest Percussion*', data: this.SectionB1Data.RespiratoryTreatmentChestPercussion });
                //this.SectionB1Data.RespiratoryTreatmentChestPercussion.Custom = this.RespiratoryTreatmentChestPercussion.value;
                break;
            case 'RespiratoryTreatmentAssistDevice':
                this.SectionB1Data.RespiratoryTreatmentAssistDevice = mapData.mapCommonSection($event, this.SectionB1Data.RespiratoryTreatmentAssistDevice);
                this.formchanged.next({ title: 'Respiratory Treatment: Vest/Cough Assist Device*', data: this.SectionB1Data.RespiratoryTreatmentAssistDevice });
                //this.SectionB1Data.RespiratoryTreatmentAssistDevice.Custom = this.RespiratoryTreatmentAssistDevice.value;
                break;
            case 'UrinaryCatheterSkilledTasks':
                this.SectionB1Data.UrinaryCatheterSkilledTasks = mapData.mapCommonSection($event, this.SectionB1Data.UrinaryCatheterSkilledTasks);
                this.formchanged.next({ title: 'Urinary Catheter/Urostomy*', data: this.SectionB1Data.UrinaryCatheterSkilledTasks });
                //this.SectionB1Data.UrinaryCatheterSkilledTasks.Custom = this.UrinaryCatheterSkilledTasks.value;
                break;
            case 'SeizureManagement':
                this.SectionB1Data.SeizureManagement = mapData.mapCommonSection($event, this.SectionB1Data.SeizureManagement);
                this.formchanged.next({ title: 'Seizure Management*', data: this.SectionB1Data.SeizureManagement });
                //this.SectionB1Data.SeizureManagement.Custom = this.SeizureManagement.value;
                break;
            case 'GlucoseChecks':
                this.SectionB1Data.GlucoseChecks = mapData.mapCommonSection($event, this.SectionB1Data.GlucoseChecks);
                this.formchanged.next({ title: 'Glucose Checks*', data: this.SectionB1Data.GlucoseChecks });
                //this.SectionB1Data.GlucoseChecks.Custom = this.GlucoseChecks.value;
                break;
            case 'WoundCare':
                this.SectionB1Data.WoundCare = mapData.mapCommonSection($event, this.SectionB1Data.WoundCare);
                this.formchanged.next({ title: 'Wound Care*', data: this.SectionB1Data.WoundCare });
                //this.SectionB1Data.WoundCare.Custom = null;
                break;
            case 'SpecialSkinCare':
                this.SectionB1Data.SpecialSkinCare = mapData.mapCommonSection($event, this.SectionB1Data.SpecialSkinCare);
                this.formchanged.next({ title: 'Special Skin Care*', data: this.SectionB1Data.SpecialSkinCare });
                //this.SectionB1Data.SpecialSkinCare.Custom = null;
                break;
            case 'OtherSkilledNursingService':
                this.SectionB1Data.OtherSkilledNursingService = mapData.mapCommonSection($event, this.SectionB1Data.OtherSkilledNursingService);
                this.formchanged.next({ title: 'Other Skilled Nursing Service*', data: this.SectionB1Data.OtherSkilledNursingService });
                //this.SectionB1Data.OtherSkilledNursingService.Custom = this.OtherSkilledNursingService.value;
                break;
            //Physical Therapy*
            case 'PHYTHERAPY':
                this.SectionB1Data.PhysicalTherapy = mapData.mapCommonSection($event, this.SectionB1Data.PhysicalTherapy);
                this.formchanged.next({ title: 'Physical Therapy*', data: this.SectionB1Data.PhysicalTherapy });
                break;
            //Occupational Therapy*
            case 'OccupationalTherapy':
                this.SectionB1Data.OccupationalTherapy = mapData.mapCommonSection($event, this.SectionB1Data.OccupationalTherapy);
                this.formchanged.next({ title: 'Occupational Therapy*', data: this.SectionB1Data.OccupationalTherapy });
                break;
            //Speech-Language Pathology*
            case 'SpeechLanguagePathology':
                this.SectionB1Data.SpeechLanguagePathology = mapData.mapCommonSection($event, this.SectionB1Data.SpeechLanguagePathology);
                this.formchanged.next({ title: 'Speech-Language Pathology*', data: this.SectionB1Data.SpeechLanguagePathology });
                break;
            //Other Skilled Rehabilitation Service
            case 'OtherSkilledRehabilitationService':
                this.SectionB1Data.OtherSkilledRehabilitationService = mapData.mapCommonSection($event, this.SectionB1Data.OtherSkilledRehabilitationService);
                this.formchanged.next({ title: 'Other Skilled Rehabilitation Service', data: this.SectionB1Data.OtherSkilledRehabilitationService });
                break;

            default:
                break;
        }
        //console.log(this.SectionB1Data);
    }

    onSectionCustomFormUpdate() {
        //console.log('custom section update called');
        //Bowel or Colostomy
        this.BowelColostomy.valueChanges.subscribe(values => {
            this.SectionB1Data.BowelColostomy = mapData.mapBowelColostomyCustom(values, this.SectionB1Data.BowelColostomy);
            //this.saveSection('BowelColostomy');
            this.formchanged.next({ title: 'Bowel or Colostomy', data: this.SectionB1Data.BowelColostomy });
        });
        //Dialysis: Hemodialysis or Peritoneal*
        this.Dialysis.valueChanges.subscribe(values => {
            this.SectionB1Data.Dialysis = mapData.mapDialysisCustom(values, this.SectionB1Data.Dialysis);
            this.formchanged.next({ title: 'Dialysis: Hemodialysis or Peritoneal', data: this.SectionB1Data.Dialysis });
        });
        //IVs: Peripheral or Central Lines*
        this.IVS.valueChanges.subscribe(value => {
            this.SectionB1Data.IVS = mapData.mapIVSCustom(value, this.SectionB1Data.IVS);
            this.formchanged.next({ title: 'IVS: PERIPHERAL OR CENTRAL LINES', data: this.SectionB1Data.IVS });
        });

        //Total Parenteral Nutrition* (TPN)
        this.ParenteralNutrition.valueChanges.subscribe(value => {
            this.SectionB1Data.ParenteralNutrition = mapData.mapParenteralNutritionCustom(value, this.SectionB1Data.ParenteralNutrition);
            this.formchanged.next({ title: 'Total Parenteral Nutrition* (TPN)', data: this.SectionB1Data.ParenteralNutrition });
        });

        //Tube Feedings*
        this.TubeFeedings.valueChanges.subscribe(value => {
            this.SectionB1Data.TubeFeedings = mapData.mapTubeFeedingsCustom(value, this.SectionB1Data.TubeFeedings);
            this.formchanged.next({ title: 'Tube Feedings*', data: this.SectionB1Data.TubeFeedings });
        });
        //Oxygen*
        this.Oxygen.valueChanges.subscribe(value => {
            this.SectionB1Data.Oxygen = mapData.mapOxygenCustom(value, this.SectionB1Data.Oxygen);
            this.formchanged.next({ title: 'Oxygen*', data: this.SectionB1Data.Oxygen });
        });
        //Deep Suctioning*
        this.DeepSuctioning.valueChanges.subscribe((value: { Types: string }) => {
            this.SectionB1Data.DeepSuctioning.aB1_OPTION1 = value.Types;
            this.formchanged.next({ title: 'Deep Suctioning*', data: this.SectionB1Data.DeepSuctioning });
        });
        //Ventilator
        this.Ventilator.valueChanges.subscribe((value: { Duration: string }) => {
            this.SectionB1Data.Ventilator.aB1_OPTION1 = value.Duration;
            this.formchanged.next({ title: 'Ventilator', data: this.SectionB1Data.Ventilator });
        });
        //Respiratory Treatment: C-PAP*
        this.RespiratoryTreatment.valueChanges.subscribe((value: { Duration: string }) => {
            this.SectionB1Data.RespiratoryTreatment.aB1_OPTION1 = value.Duration;
            this.formchanged.next({ title: 'Respiratory Treatment: C-PAP*', data: this.SectionB1Data.RespiratoryTreatment });
        });
        //Respiratory Treatment: Bi-PAP*
        this.RespiratoryTreatmentBiPAP.valueChanges.subscribe((value: { Duration: string }) => {
            this.SectionB1Data.RespiratoryTreatmentBiPAP.aB1_OPTION1 = value.Duration;
            this.formchanged.next({ title: 'Respiratory Treatment: Bi-PAP*', data: this.SectionB1Data.RespiratoryTreatmentBiPAP });
        });
        //Respiratory Treatment: IPPB
        this.RespiratoryTreatmentIPPB.valueChanges.subscribe((value: { Duration: string }) => {
            this.SectionB1Data.RespiratoryTreatmentIPPB.aB1_OPTION1 = value.Duration;
            this.formchanged.next({ title: 'Respiratory Treatment: IPPB*', data: this.SectionB1Data.RespiratoryTreatmentIPPB });
        });
        //Respiratory Treatment: Chest Percussion*
        this.RespiratoryTreatmentChestPercussion.valueChanges.subscribe((value: { Duration: string }) => {
            this.SectionB1Data.RespiratoryTreatmentChestPercussion.aB1_OPTION1 = value.Duration;
            this.formchanged.next({ title: 'Respiratory Treatment: Chest Percussion*', data: this.SectionB1Data.RespiratoryTreatmentChestPercussion });
        });
        //Respiratory Treatment: Vest/Cough Assist Device*
        this.RespiratoryTreatmentAssistDevice.valueChanges.subscribe((value: { Duration: string }) => {
            this.SectionB1Data.RespiratoryTreatmentAssistDevice.aB1_OPTION1 = value.Duration;
            this.formchanged.next({ title: 'Respiratory Treatment: Vest/Cough Assist Device*', data: this.SectionB1Data.RespiratoryTreatmentAssistDevice });
        });
        //Urinary Catheter/Urostomy*
        this.UrinaryCatheterSkilledTasks.valueChanges.subscribe(value => {
            this.SectionB1Data.UrinaryCatheterSkilledTasks = mapData.mapUrinaryCatheterSkilledTasksCustom(value, this.SectionB1Data.UrinaryCatheterSkilledTasks);
            this.formchanged.next({ title: 'Urinary Catheter/Urostomy*', data: this.SectionB1Data.UrinaryCatheterSkilledTasks });
        });
        //Seizure Management*
        this.SeizureManagement.valueChanges.subscribe(value => {
            this.SectionB1Data.SeizureManagement = mapData.mapSeizureManagementCustom(value, this.SectionB1Data.SeizureManagement);
            this.formchanged.next({ title: 'Seizure Management*', data: this.SectionB1Data.SeizureManagement });
        });
        //Glucose Checks*
        this.GlucoseChecks.valueChanges.subscribe((value: { TypeofGlucoseChecks: string }) => {
            this.SectionB1Data.GlucoseChecks.aB1_OPTION1 = value.TypeofGlucoseChecks;
            this.formchanged.next({ title: 'Glucose Checks*', data: this.SectionB1Data.GlucoseChecks });
        });
        //Other Skilled Nursing Service*
        this.OtherSkilledNursingService.valueChanges.subscribe((value: { Service: string }) => {
            this.SectionB1Data.OtherSkilledNursingService.aB1_OPTION_COMMENTS = value.Service;
            this.formchanged.next({ title: 'Other Skilled Nursing Service*', data: this.SectionB1Data.OtherSkilledNursingService });
        });
        //Other Skilled Rehabilitation Service
        this.OtherSkilledRehabilitationService.valueChanges.subscribe((value: { Service: string }) => {
            this.SectionB1Data.OtherSkilledRehabilitationService.aB1_OPTION_COMMENTS = value.Service;
            this.formchanged.next({ title: 'Other Skilled Rehabilitation Service', data: this.SectionB1Data.OtherSkilledRehabilitationService });
        });

    }

    //load drop down values from service
    loadDropdowns(): EventEmitter<string> {

        let load_complete = new EventEmitter<string>();

        //load skilled task
        let SKILLED_TASK$ = this.dropDownService.GetDropDownValues('SKILLED_TASK');

        //load DialysisTypeOfDialysisValues
        let DIALYSIS_TYPE$ = this.dropDownService.GetDropDownValues('DIALYSIS_TYPE');

        //DialysisLocationValues
        let DIALYSIS_LOCATION$ = this.dropDownService.GetDropDownValues('DIALYSIS_LOCATION');

        //IV_TYPE
        let IV_TYPE$ = this.dropDownService.GetDropDownValues('IV_TYPE');

        //IV_LOCATION
        let IV_LOCATION$ = this.dropDownService.GetDropDownValues('IV_LOCATION');

        //TUBEFEED_TYPE TubeFeedingsTypeofTubeFeedingValues
        let TUBEFEED_TYPE$ = this.dropDownService.GetDropDownValues('TUBEFEED_TYPE');

        //TubeFeedingDurationValues
        let TUBEFEED_DURATION$ = this.dropDownService.GetDropDownValues('TUBEFEED_DURATION');

        //TubeFeedingMethodofDeliveryValues
        let TUBEFEED_METHOD$ = this.dropDownService.GetDropDownValues('TUBEFEED_METHOD');

        //OxygenOxygenUseValues
        let OXYGEN_USE$ = this.dropDownService.GetDropDownValues('OXYGEN_USE');
        //});

        //OxygenMethodValues
        let OXYGEN_METHOD$ = this.dropDownService.GetDropDownValues('OXYGEN_METHOD');

        //OxygenSpecialOrdersValues
        let OXYGEN_SPECIAL$ = this.dropDownService.GetDropDownValues('OXYGEN_SPECIAL');

        //DeepSuctioningTypesValues
        let DEEPSUC_TYPE$ = this.dropDownService.GetDropDownValues('DEEPSUC_TYPE');

        //VentilatorDurationValues
        let VENT_DURATION$ = this.dropDownService.GetDropDownValues('VENT_DURATION');

        //UrinaryCatheterSkilledTasksTypeofCatheterValues
        let CATHETER_TYPE$ = this.dropDownService.GetDropDownValues('CATHETER_TYPE');

        //SeizureManagementSeizureSeverityValues
        let SEIZURE_TYPE$ = this.dropDownService.GetDropDownValues('SEIZURE_TYPE');

        //GlucoseChecksTypeValues
        let GLUCOSE_TYPE$ = this.dropDownService.GetDropDownValues('GLUCOSE_TYPE');


        forkJoin(SKILLED_TASK$, DIALYSIS_TYPE$, DIALYSIS_LOCATION$, IV_TYPE$, IV_LOCATION$, TUBEFEED_TYPE$, TUBEFEED_DURATION$, TUBEFEED_METHOD$, OXYGEN_USE$, OXYGEN_METHOD$, OXYGEN_SPECIAL$, DEEPSUC_TYPE$, VENT_DURATION$, CATHETER_TYPE$, SEIZURE_TYPE$, GLUCOSE_TYPE$)
            .subscribe(([SKILLED_TASK, DIALYSIS_TYPE, DIALYSIS_LOCATION, IV_TYPE, IV_LOCATION, TUBEFEED_TYPE, TUBEFEED_DURATION, TUBEFEED_METHOD, OXYGEN_USE, OXYGEN_METHOD, OXYGEN_SPECIAL, DEEPSUC_TYPE, VENT_DURATION, CATHETER_TYPE, SEIZURE_TYPE, GLUCOSE_TYPE]) => {
                this.dropDownValues.BowelColostomySkilledTaskValues = SKILLED_TASK;
                this.dropDownValues.DialysisTypeOfDialysisValues = DIALYSIS_TYPE;
                this.dropDownValues.DialysisLocationValues = DIALYSIS_LOCATION;
                this.dropDownValues.IVSTypeOfIVValues = IV_TYPE;
                this.dropDownValues.IVSLocationValues = IV_LOCATION;
                this.dropDownValues.TubeFeedingsTypeofTubeFeedingValues = TUBEFEED_TYPE;
                this.dropDownValues.TubeFeedingDurationValues = TUBEFEED_DURATION;
                this.dropDownValues.TubeFeedingMethodofDeliveryValues = TUBEFEED_METHOD;
                this.dropDownValues.OxygenOxygenUseValues = OXYGEN_USE;
                this.dropDownValues.OxygenMethodValues = OXYGEN_METHOD;
                this.dropDownValues.OxygenSpecialOrdersValues = OXYGEN_SPECIAL;
                this.dropDownValues.DeepSuctioningTypesValues = DEEPSUC_TYPE;
                this.dropDownValues.VentilatorDurationValues = VENT_DURATION;
                this.dropDownValues.UrinaryCatheterSkilledTasksTypeofCatheterValues = CATHETER_TYPE;
                this.dropDownValues.SeizureManagementSeizureSeverityValues = SEIZURE_TYPE;
                this.dropDownValues.GlucoseChecksTypeValues = GLUCOSE_TYPE;
                load_complete.emit('done');
                load_complete.complete();
            }, error => {
                this.alertService.error('Error laoding dropdowns');
                console.error(error);
            });

        return load_complete;
    }

    @HostListener('window:beforeunload')
    canNavigateAway(): boolean {
      return !this.unsavedChangesPresent;
    }


    @HostListener('document:keypress', ['$event'])
    onCntrlQ(e: KeyboardEvent) {
        console.log(e);
        if (e.code == 'KeyQ' && e.ctrlKey) {
            this.allSections.forEach(sec => {
                //sec.sectionForm.updateValueAndValidity();
                if (sec.sectionForm.touched) {
                    sec.sectionForm.markAsUntouched();
                } else {
                    sec.sectionForm.markAllAsTouched();
                }                
            });
        }
    }

}
