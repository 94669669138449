
export class SectionB1Dropdown {
  // drop down values
  BowelColostomySkilledTaskValues: any;
  DialysisTypeOfDialysisValues: any;
  DialysisLocationValues: any;
  //IVS
  IVSTypeOfIVValues: any;
  IVSLocationValues: any;
  //Tube Feedings
  //TUBEFEED_TYPE
  TubeFeedingsTypeofTubeFeedingValues: any;
  TubeFeedingDurationValues: any;
  TubeFeedingMethodofDeliveryValues: any;
  //oxygen
  OxygenOxygenUseValues: any;
  OxygenMethodValues: any;
  OxygenSpecialOrdersValues: any;
  //DEEP SUCTION
  DeepSuctioningTypesValues: any;
  //Ventilator
  VentilatorDurationValues: any;
  //RespiratoryTreatment
  RespiratoryTreatmentDurationValues: any;
  //UrinaryCatheterSkilledTasks
  UrinaryCatheterSkilledTasksTypeofCatheterValues: any;
  //SeizureManagement
  SeizureManagementSeizureSeverityValues: any;
  //GlucoseChecks
  GlucoseChecksTypeValues: any;
}
