import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SectionAModel, CityState } from '../models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SectionAService {

  constructor(private apiService: ApiService) { }

  getSectionA(alocqId): Observable<SectionAModel> {
    return this.apiService.get('sectiona/' + alocqId);
  }

  getCounties(): Observable<any> {
    return this.apiService.get('sectiona/getCounties');
  }

  saveSection(formData): Observable<any> {
    return this.apiService.put('sectiona/', formData);
  }

  createSection(formData): Observable<any>{
    return this.apiService.post('sectiona/',formData);
  }

  getCityState(zipCode): Observable<CityState> {

    return this.apiService.get('sectiona/GetCityState', zipCode)
      .pipe(map(data => { return data; }));
  }


}
