import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { DocumentsDialogComponent } from './file-uploader/documents-dialog/documents-dialog.component';
import { MaterialModule } from '../../../material.module';

@NgModule({
  declarations: [FileUploaderComponent, DocumentsDialogComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    FileUploaderComponent, DocumentsDialogComponent],
  entryComponents: [DocumentsDialogComponent]
})
export class DocumentUploaderModuleModule { }
