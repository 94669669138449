import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {  SectionB2Model } from '../models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SectionB2Service {

  constructor(private apiService: ApiService) { }

  getSectionB2(alocqId) {
    return this.apiService.get('sectionB2/' + alocqId);
  }

  saveSection(SectionB2Model) {
    return this.apiService.put('SectionB2', SectionB2Model);
  }

  createSection(SectionB2Model) {
    return this.apiService.post('SectionB2', SectionB2Model);
  }

}
