import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SectionCRoutingModule } from './section-c-routing.module';
import { SectionCComponent } from './section-c/section-c.component';
import { AdlRowComponent } from './section-c/adl-row/adl-row.component';

@NgModule({
  declarations: [SectionCComponent, AdlRowComponent],
  imports: [
    CommonModule,
    SectionCRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SectionCModule { }
