import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { physicians } from '..';

@Injectable({
  providedIn: 'root'
})
export class SectionBService {

  constructor(private apiService: ApiService) { }

    getPhysicians(): Observable<physicians[]> {
        return this.apiService.get("SectionB/Physicians");
  }

  getDiagnosisCodes(): Observable<any> {
    return this.apiService.get("SectionB/getDiagnosisCodes/");
  }

  getSectionB(alocID: string): Observable<any> {
    return this.apiService.get(`SectionB/${alocID}`);
  }

  updateSectionb(formData): Observable<any> {
    return this.apiService.put('sectionb', formData);
  }

  createSectionb(formData): Observable<any> {
    return this.apiService.post('sectionb', formData);
  }

}
