import { AbstractControl } from "@angular/forms";
//To avoid waring 
//import * as moment from 'moment';
//To avoid waring 

export function validateFutureDate(cntrl: AbstractControl): null | { [key: string]: any } {
  let selected_date = (new Date(cntrl.value));
  //console.log('selected date ', selected_date);
  //console.log('current date ', (new Date()));
  //console.log(' selected greater ?', selected_date >= (new Date()));
  if (selected_date > (new Date())) {
    return {
      'futureDate': { value: selected_date, msg: 'future date not allowed ' }
    };
  }
  return null;
}
export function validate120Years(cntrl: AbstractControl): null | { [key: string]: any } {
  let minmumDate120 = new Date((new Date()).getFullYear() - 120, (new Date()).getMonth(), (new Date()).getDate());
  let maximumDate120 = new Date((new Date()).getFullYear() + 120, (new Date()).getMonth(), (new Date()).getDate());
  let selected_date = (new Date(cntrl.value));

  //console.log('Min ', minmumDate120);
  //console.log('Max ', maximumDate120);
  //console.log('Select', selected_date);

  if (selected_date < minmumDate120 || selected_date > maximumDate120) {
    return {
      'limitDateRange': { value: selected_date, msg: 'date cannot be more than 120 years prior to, or 120 years after, the current date' }
    };
  }
  return null;
}
export function validateAge(cntrl: AbstractControl): { [key: string]: any } | null {
    if (cntrl.value == null || cntrl.value == '') return null;
    //To avoid waring 
    //let selected_age = moment().diff(cntrl.value, 'years');
    //To avoid waring 
    let diff: number = Math.abs(Date.now() - cntrl.value);
    let selected_age = Number(new Date(diff).getUTCFullYear() - 1970);
  if (selected_age < 21 || selected_age > 120) {
    return {
      invalidAge: {
        value: selected_age,
        error: 'Age cannot be greater than 120 or less than or equal to zerro'
      }
    };
  } else {
    return null;
  }
}
