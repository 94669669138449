import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, Form, ValidatorFn, ValidationErrors } from '@angular/forms';
import { SectionAService, SectionAModel, AlertsService, DataService, SectionDropdownServiceService, UserService, getTextForDropdownValues } from '../../shared/index';
import { DatePipe } from '@angular/common';
import * as ALOCValidators from '../../shared/validators/AlocDateValidators';
import { timer } from 'rxjs';
import { isNullOrUndefined } from 'util';
import Swal from 'sweetalert2';
import { User } from '../../shared/models';
@Component({
  selector: 'app-section-a',
  templateUrl: './section-a.component.html',
  styleUrls: ['./section-a.component.css'],
  providers: [DatePipe]
})
export class SectionAComponent implements OnInit {
  profileUser: User;
  sectionAForm: FormGroup;
  isSubmitting = false;
  sectionAModel: SectionAModel = {} as SectionAModel;
  assessmentReasons: any[];
  assessmentsTypes: any[];
  assessorNames: any[];
  assessmentSources: any[];
  assessorQualifications: any[];
  assessorPlaces: any[];  
  reasonForLevelOfCare: any[];
  counties: any[];
  genders = [
    { Text: 'Male', Value: 'M' },
    { Text: 'Female', Value: 'F' }
  ];
  maritalStatus: any[];
  races: any[];

  militaryStatus: any[];

  show_aO_ASSESS_REASON_COMMENTS = false;
  show_aO_SOURCE_OTHER = false;
  show_aO_ASSESS_PLACE_COMMENTS = false;
  show_MCP_User = false;
  show_MCP_Req: string;
  strCounty: string;
  strCity: string;
  strZipcode: string;
  dropDownSelectText = getTextForDropdownValues;
  ssnValidator = /^(?!111-11-1111|222-22-2222|333-33-3333|444-44-4444|555-55-5555|666-66-6666|777-77-7777|888-88-8888|999-99-9999)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
  emailValidator = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  constructor(private fb: FormBuilder,
    private sectionAService: SectionAService,
    private alertsService: AlertsService,
    public dataService: DataService,
    private router: Router,
    private dropdownservice: SectionDropdownServiceService,
    private userService: UserService,
    private datePipe: DatePipe) {

    this.sectionAForm = this.fb.group({
      aA_SECTION_ID: new FormControl(''),
      aA_ALOC_ID: new FormControl(''),
      aA_FIRST_NAME: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(60), Validators.required]),
      aA_MIDDLE_INIT: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(2)]),
      aA_LAST_NAME: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(60), Validators.required]),
      aA_NICKNAME: new FormControl('', [Validators.maxLength(30)]),
      aA_ADDRESS: new FormControl('', [Validators.pattern("[A-Za-z0-9/:)(-@&*#,;.\. \'-]+"), Validators.maxLength(60), Validators.required]),
      aA_CITY: new FormControl('', [Validators.pattern(/^[A-Za-z ]+$/), Validators.maxLength(60), Validators.required]),
      aA_STATE: new FormControl('', [Validators.pattern(/^[A-Za-z ]+$/), Validators.maxLength(3), Validators.required]),
      aA_ZIP: new FormControl('', [Validators.pattern("[0-9]{5}"), Validators.maxLength(5), Validators.required]),
      aA_COUNTY_CODE: new FormControl('', Validators.required),
      aA_TELEPHONE_PRIMARY: new FormControl('', [Validators.minLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
      aA_TELEPHONE_SECONDARY: new FormControl('', [Validators.minLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
      aA_EMAIL_ADDRESS: new FormControl('', [Validators.maxLength(128), Validators.pattern(this.emailValidator)]),
      aA_SEX: new FormControl('', Validators.required),
      aA_DATE_OF_BIRTH: new FormControl('', {
        validators: [Validators.required, ALOCValidators.validateFutureDate, ALOCValidators.validateAge]
      }),
      //aA_AGE: new FormControl('', [Validators.maxLength(2), Validators.required]),
      aA_AGE: new FormControl(''),
      aA_SSN: new FormControl('', [Validators.required, Validators.pattern(this.ssnValidator)]),
      aA_MARITAL_STATUS: new FormControl('', Validators.required),
      aA_RACE_NATIVE: new FormControl(''),
      aA_RACE_ASIAN: new FormControl(''),
      aA_RACE_BLACK: new FormControl(''),
      aA_RACE_PACIFIC: new FormControl(''),
      aA_RACE_WHITE: new FormControl(''),
      aA_MEDICAID_BILLING_NUMBER: new FormControl('', [Validators.maxLength(12)]),
      aA_MEDICAID_LIABILITY: new FormControl('', [Validators.pattern(/^(\d*\.)?\d+$/), Validators.min(0), Validators.max(10000)]),
      aA_MEDICAID_SPEND_DOWN: new FormControl(''),
      aA_MEDICAID_BUY_IN: new FormControl('', [Validators.pattern(/^(\d*\.)?\d+$/), Validators.min(0), Validators.max(10000)]),
      aA_MEDICAID_MCP: new FormControl(''),
      aA_MEDICAID_MYCARE_PLAN: new FormControl('', [Validators.maxLength(32)]),
      aA_MEDICAID_A: new FormControl(''),
      aA_MEDICAID_B: new FormControl(''),
      aA_MEDICAID_D: new FormControl('', [Validators.maxLength(32)]),
      aA_MEDICAID_ADVANTAGE: new FormControl('', Validators.maxLength(32)),
      aA_COMM_CARRIER: new FormControl('', Validators.maxLength(32)),
      aA_COMM_POLICY_ID: new FormControl('', Validators.maxLength(32)),
      aA_COMM_PHONE: new FormControl('', [Validators.minLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
      aA_VETERAN: new FormControl('', {
        //validators: [Validators.required],
        updateOn: "change"
      }),
      //Veterans status is not required feild, but still can add/update data if user want to
      //aA_VA_HEALTHCARE_REGISTERED: new FormControl('', Validators.required),
      //aA_VA_HEALTHCARE_RECEIVED: new FormControl('', Validators.required),
      //aA_VA_HEALTHCARE_CLAIM: new FormControl('', Validators.required),
      aA_VA_HEALTHCARE_REGISTERED: new FormControl('', { updateOn: "change" }),      
      aA_VA_HEALTHCARE_RECEIVED: new FormControl('', { updateOn: "change" }),      
      aA_VA_HEALTHCARE_CLAIM: new FormControl('', { updateOn: "change" }),
      aO_CREATE_WORKER: new FormControl(''),
      aO_ASSESSOR_RN: new FormControl(''),
      aO_ASSESSOR_LSW: new FormControl(''),
      aO_ASSESSOR_LISW: new FormControl(''),
      aO_ASSESS_TYPE: new FormControl('', Validators.required),
      aO_ASSESS_REASON: new FormControl('', [Validators.required]),
      aO_ASSESS_REASON_NF: new FormControl(''),
      aO_ASSESS_REASON_COMMENTS: new FormControl('', [Validators.maxLength(200)]),
      aO_ASSESS_PLACE: new FormControl('', [Validators.required]),
      aO_ASSESS_PLACE_COMMENTS: new FormControl('', [Validators.maxLength(200)]),
      aO_SOURCE_INDIVIDUAL: new FormControl(''),
      aO_SOURCE_PRIMARY_CAREGIVER: new FormControl(''),
      aO_SOURCE_PROVIDER: new FormControl(''),
      aO_SOURCE_FAMILY: new FormControl(''),
      aO_SOURCE_GUARDIAN: new FormControl(''),
      aO_SOURCE_MED_RECORD: new FormControl(''),
      aO_SOURCE_PHYSICIAN: new FormControl(''),
      aO_SOURCE_OTHER: new FormControl(''),
      aO_SOURCE_OTHER_COMMENTS: new FormControl('', [Validators.maxLength(200)]),
      aO_ASSESS_BEGIN_DATE: new FormControl('', {
        validators: [Validators.required, ALOCValidators.validateFutureDate]
      }),
      aO_ASSESSOR: new FormControl('', {
        validators: [Validators.required]
      }),
      aO_CREATE_AGENCY: new FormControl({ value: '', disabled: true }),
      aO_NF_ADMISSION_DATE: new FormControl(''),//, ALOCValidators.validateFutureDate), // 
      aO_REQUESTED_LOC_DATE: new FormControl(''), // DateTime?
      submitteR_FIRST_NAME: new FormControl({ value: '', disabled: true }),
      submitteR_LAST_NAME: new FormControl({ value: '', disabled: true }),
      aA_INDIVIDUAL_RACE: new FormControl(''),
      aO_ASSESSOR_QUALIFICATION: new FormControl('', Validators.required),
      aO_ASSESS_SOURCE: new FormControl('', Validators.required)

    });
    let sec_a_form = this.sectionAForm;
    //this.sectionAForm.get('aA_VETERAN').setValidators(this.validate_A_VETERAN(sec_a_form)); -- Veterans is not required feild
    this.sectionAForm.get('aA_MARITAL_STATUS').valueChanges.subscribe(val => {
      this.sectionAForm.get('aA_VETERAN').updateValueAndValidity();
    });

  }

  ngOnInit() {

    //load drop down fields
    this.loadDropdownService();

    //configure validations
    this.configDynValidations();
    this.onChanges();

   // this.userDetails = this.userService.getCurrentUser();
    //console.log('context user ', this.userService.getCurrentUser());

    

  }

  configDynValidations() {

    this.sectionAForm.get('aO_ASSESS_REASON').valueChanges.subscribe(value => {
      if (value === 'OTHER') {
        this.show_aO_ASSESS_REASON_COMMENTS = true;
        this.sectionAForm.get('aO_ASSESS_REASON_COMMENTS').setValidators([Validators.required]);
      } else {
        this.show_aO_ASSESS_REASON_COMMENTS = false;
        this.sectionAForm.get('aO_ASSESS_REASON_COMMENTS').clearValidators();
      }
    });


    this.sectionAForm.get('aO_ASSESS_SOURCE').valueChanges.subscribe((value: string[]) => {
      // reset other field
      this.sectionAForm.get('aO_SOURCE_OTHER').setValue(null);
      this.show_aO_SOURCE_OTHER = false;
      this.sectionAForm.get('aO_SOURCE_OTHER_COMMENTS').clearValidators();
      this.sectionAForm.get('aO_SOURCE_OTHER_COMMENTS').updateValueAndValidity();

      //console.log(value);
      if (value.indexOf('CAREGIVER') > -1) {
        this.sectionAForm.get('aO_SOURCE_PRIMARY_CAREGIVER').setValue('Y');
      } else {
        this.sectionAForm.get('aO_SOURCE_PRIMARY_CAREGIVER').setValue(null);
      }
      if (value.indexOf('PROVIDER') > -1) {
        this.sectionAForm.get('aO_SOURCE_PROVIDER').setValue('Y');
      } else {
        this.sectionAForm.get('aO_SOURCE_PROVIDER').setValue(null);
      }
      if (value.indexOf('FAMILY') > -1) {
        this.sectionAForm.get('aO_SOURCE_FAMILY').setValue('Y');
      } else {
        this.sectionAForm.get('aO_SOURCE_FAMILY').setValue(null);
      }
      if (value.indexOf('LEGALGUARD') > -1) {
        this.sectionAForm.get('aO_SOURCE_GUARDIAN').setValue('Y');
      } else {
        this.sectionAForm.get('aO_SOURCE_GUARDIAN').setValue(null);
      }
      if (value.indexOf('MEDRECORD') > -1) {
        this.sectionAForm.get('aO_SOURCE_MED_RECORD').setValue('Y');
      } else {
        this.sectionAForm.get('aO_SOURCE_MED_RECORD').setValue(null);
      }
      if (value.indexOf('PHYSICIAN') > -1) {
        this.sectionAForm.get('aO_SOURCE_PHYSICIAN').setValue('Y');
      } else {
        this.sectionAForm.get('aO_SOURCE_PHYSICIAN').setValue(null);
      }
      if (value.indexOf('OTHER') > -1) {
        this.sectionAForm.get('aO_SOURCE_OTHER').setValue('Y');
        this.show_aO_SOURCE_OTHER = true;
        this.sectionAForm.get('aO_SOURCE_OTHER_COMMENTS').setValidators([Validators.required]);
      }
      if (value.indexOf('INDIVIDUAL') > -1) {
        this.sectionAForm.get('aO_SOURCE_INDIVIDUAL').setValue('Y');
      } else {
        this.sectionAForm.get('aO_SOURCE_INDIVIDUAL').setValue(null);
      }

    });
    //
    this.sectionAForm.get('aO_ASSESS_PLACE').valueChanges.subscribe(value => {
      //console.log(value);
      if (value === 'ASSMNTOTH') {
        this.show_aO_ASSESS_PLACE_COMMENTS = true;
        this.sectionAForm.get('aO_ASSESS_PLACE_COMMENTS').setValidators([Validators.required]);
      } else {
        this.show_aO_ASSESS_PLACE_COMMENTS = false;
        this.sectionAForm.get('aO_ASSESS_PLACE_COMMENTS').clearValidators();
      }
    });

    //configure params for saving correctly
    this.sectionAForm.get('aO_ASSESSOR_QUALIFICATION').valueChanges.subscribe((value: string[]) => {
      //console.log(value);
      this.sectionAForm.get('aO_ASSESSOR_RN').setValue(null);
      if (value.indexOf('RN') > -1) {
        this.sectionAForm.get('aO_ASSESSOR_RN').setValue('Y');
      }
      this.sectionAForm.get('aO_ASSESSOR_LSW').setValue(null);
      if (value.indexOf('LSW') > -1) {
        this.sectionAForm.get('aO_ASSESSOR_LSW').setValue('Y');
      }
      this.sectionAForm.get('aO_ASSESSOR_LISW').setValue(null);
      if (value.indexOf('LISW') > -1) {
        this.sectionAForm.get('aO_ASSESSOR_LISW').setValue('Y');
      }
    });

    //configure params to save data correctly
    this.sectionAForm.get('aA_INDIVIDUAL_RACE').valueChanges.subscribe((value: string[]) => {
      if (value.indexOf('AMINDIAN') > -1) {
        this.sectionAForm.get('aA_RACE_NATIVE').setValue('Y');
      } else {
        this.sectionAForm.get('aA_RACE_NATIVE').setValue(null);
      }
      if (value.indexOf('BLACK') > -1) {
        this.sectionAForm.get('aA_RACE_BLACK').setValue('Y');
      } else {
        this.sectionAForm.get('aA_RACE_BLACK').setValue(null);
      }
      if (value.indexOf('WHITE') > -1) {
        this.sectionAForm.get('aA_RACE_WHITE').setValue('Y');
      } else {
        this.sectionAForm.get('aA_RACE_WHITE').setValue(null);
      }
      if (value.indexOf('ASIAN') > -1) {
        this.sectionAForm.get('aA_RACE_ASIAN').setValue('Y');
      } else {
        this.sectionAForm.get('aA_RACE_ASIAN').setValue(null);
      }
      if (value.indexOf('HAWAIIAN') > -1) {
        this.sectionAForm.get('aA_RACE_PACIFIC').setValue('Y');
      } else {
        this.sectionAForm.get('aA_RACE_PACIFIC').setValue(null);
      }
    });  
        
  }

  validate_A_VETERAN(theform: FormGroup): ValidatorFn {
    
    return (cntrl: AbstractControl): ValidationErrors | null => {
      //console.log('veteran validation called');
      if (theform == null) return null;
      let maritial_status: string = theform.get('aA_MARITAL_STATUS').value;
      let maritial_status_values = ['SINGLE'];
      if (maritial_status_values.indexOf(maritial_status) > -1) {
        let A_VETERAN_sel_val = cntrl.value;
        let A_VETERAN_values = ['BOTH', 'SPOUSE'];
        if (A_VETERAN_values.indexOf(A_VETERAN_sel_val) > -1) {
          return { 'checkMaritalStatus': { msg: 'Please check Maritial status' } };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }





  onSave(event) {
    //console.log('form data', this.sectionAForm.value);
   
    if (!this.sectionAForm.valid) {
      for (let cnt in this.sectionAForm.controls) {
        if (this.sectionAForm.controls[cnt].invalid) {
          //console.log(cnt, this.sectionAForm.controls[cnt]);
        }
      }
      return;
    }

    //format dates
    let formData = this.sectionAForm.value;
    if (formData['aO_ASSESS_BEGIN_DATE'] != null)
      formData['aO_ASSESS_BEGIN_DATE'] = this.datePipe.transform(formData['aO_ASSESS_BEGIN_DATE'], 'MM/dd/yyyy');
    //aO_ASSESS_BEGIN_DATE
    if (formData['aO_REQUESTED_LOC_DATE'] != null)
      formData['aO_REQUESTED_LOC_DATE'] = this.datePipe.transform(formData['aO_REQUESTED_LOC_DATE'], 'MM/dd/yyyy');
    //aA_DATE_OF_BIRTH
    if (formData['aA_DATE_OF_BIRTH'] != null) {
      formData['aA_DATE_OF_BIRTH'] = this.datePipe.transform(formData['aA_DATE_OF_BIRTH'], 'MM/dd/yyyy');
    }

    //aO_NF_ADMISSION_DATE
    if (this.sectionAForm.value['aO_NF_ADMISSION_DATE'] != null) {
      formData['aO_NF_ADMISSION_DATE'] = this.datePipe.transform(formData['aO_NF_ADMISSION_DATE'], 'MM/dd/yyyy');
    }    

    formData['aA_MEDICAID_A'] = formData['aA_MEDICAID_A'] ? 'Y' : 'N';
    formData['aA_MEDICAID_B'] = formData['aA_MEDICAID_B'] ? 'Y' : 'N';
    //console.log('form data ', formData);
    //create new
    if (this.dataService.getSelectedDocument() == null) {

      this.sectionAService.createSection(formData).subscribe(
        resp => {
          //console.log('section a created ', resp);
          this.alertsService.success('Section A created');
          let new_aloc_id = resp.details.outputParams.aa_aloc_id_out;
          if (new_aloc_id == null) {
            this.alertsService.error('Error fetching new aloc id');
            return;
          }
          //console.log('new aloc id ', new_aloc_id);

          this.sectionAService.getSectionA(new_aloc_id).subscribe(resp => {
            this.dataService.saveSelectedDocument({
              dB_ID: new_aloc_id,
              name: `${resp.aA_LAST_NAME} ,${resp.aA_FIRST_NAME}`
            });
            this.router.navigate(['section-a']);
            this.alertsService.clearLoader();
          }, error => {
            //console.error('error loading the created document ', new_aloc_id);
            this.alertsService.error(`Error loading finding newly created ALOC Document ${new_aloc_id}`);

          });


        },
        error => {
          //console.log('Error creating section a ', error);
          this.alertsService.error('Error creating Section A !');
        },
        () => {
          this.alertsService.clearLoader();
        });
      return;
    }

    if (this.dataService.getSelectedDocument() == null) {
      return;
    }
    //update existing
    //console.log('section a data ', formData);
    this.sectionAService.saveSection(formData).subscribe(resp => {
      //console.log('resp ', resp);
      this.alertsService.success('Section A Saved');
      //console.log('section a save response ', resp);
      this.loadSectionAdataForSelected();
    }, error => {
      console.error(error);
      this.alertsService.error('Error saving section A');
    }, () => {
      this.alertsService.clearLoader();
      this.loadSectionAdataForSelected();
    });
  }

  loadDropdownService() {
    //aO_ASSESS_TYPE ASSESSMENT_TYPE
    this.dropdownservice.GetDropDownValues('ASSESSMENT_TYPE').subscribe((data: any[]) => {
      this.assessmentsTypes = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });
    //aO_ASSESS_REASON  ALOC_ASSESSMENT_REASON
    this.dropdownservice.GetDropDownValues('ALOC_ASSESSMENT_REASON').subscribe((data: any[]) => {
      this.assessmentReasons = data;
      //console.log('assessment reason ', data);
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });
    //load paa users
    let cntxuser = this.userService.getCurrentUser();    
    this.userService.getAssessorNames(cntxuser.loginId, cntxuser.userType).subscribe((data: any[]) => {
      this.assessorNames = data;
      this.alertsService.clearLoader();
      //console.log('this.assessorNames ', this.assessorNames);
      //timer(500).subscribe(() => {
      //  if (isNullOrUndefined(this.sectionAForm.get('aO_ASSESSOR').value) || this.sectionAForm.get('aO_ASSESSOR').value == '' || this.sectionAForm.get('aO_ASSESSOR').value == null) {
      //    this.sectionAForm.get('aO_ASSESSOR').setValue(this.userService.getCurrentUser().loginId);
      //  }

     // });
    }, error => {
      this.alertsService.error('Error fetching assessor names');
      console.error(error);
    });

    //get assessor source
    this.dropdownservice.GetDropDownValues('ASST_SOURCE').subscribe(data => {
      this.assessmentSources = data;
      this.alertsService.clearLoader();
    }, error => {

      this.alertsService.error(error);
    });

    //get assessor qualification
    this.dropdownservice.GetDropDownValues('ASSESSOR_QUALIFICATIONS').subscribe(data => {
      this.assessorQualifications = data;
      this.alertsService.clearLoader();
    }, error => {

      this.alertsService.error(error);
    });

    //get assessor places
    this.dropdownservice.GetDropDownValues('ASSESSMENT_PLACE').subscribe(data => {
      this.assessorPlaces = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });

    //get reason for level of care determination
    this.dropdownservice.GetDropDownValues('ASSESSMENT_NFA').subscribe(data => {
      this.reasonForLevelOfCare = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });

    //load counties
    this.sectionAService.getCounties().subscribe(data => {
      this.counties = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });

    //load maritial status
    this.dropdownservice.GetDropDownValues('MARITAL_STATUS').subscribe(data => {
      this.maritalStatus = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });

    //load race
    this.dropdownservice.GetDropDownValues('RACE').subscribe(data => {
      this.races = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });

    //load military
    this.dropdownservice.GetDropDownValues('VETERAN_STATUS').subscribe(data => {
      this.militaryStatus = data;
      this.alertsService.clearLoader();
    }, error => {
      this.alertsService.error(error);
    });

    //load  record
    this.loadSectionAdataForSelected();

  }

  loadSectionAdataForSelected() {
    //load section a record
    let cntc_aloc = null;
    if (this.dataService.getSelectedDocument() == null) {
      this.loadUserInfo();
      this.newDocument();
      this.strCounty = null;
      this.strCity = null;
      this.strZipcode = null;
      return;
    } else {
      cntc_aloc = this.dataService.getSelectedDocument();
    };
    this.sectionAService.getSectionA(cntc_aloc).subscribe(data => {      
      this.strCounty = data.aA_COUNTY_CODE;
      this.strCity = data.aA_CITY;
      this.strZipcode = data.aA_ZIP;
      this.dataService.saveSelectedDocument({
        dB_ID: data.aA_ALOC_ID,
        name: `${data.aA_LAST_NAME} ,${data.aA_FIRST_NAME}`
      });

      this.sectionAForm.patchValue(data);
      
      this.setFormData(data);
      this.mcpEdit();
      this.alertsService.success('Section A loaded.');      
    }, error => {
      this.alertsService.error(error);
    
    });    
  }

  setFormData(data) {

    if (this.datePipe.transform(data['aO_ASSESS_BEGIN_DATE'] != null))
      this.sectionAForm.get('aO_ASSESS_BEGIN_DATE').setValue(new Date(data['aO_ASSESS_BEGIN_DATE']));

    if (data['aO_NF_ADMISSION_DATE'] != null) {
      this.sectionAForm.get('aO_NF_ADMISSION_DATE').setValue(new Date(data['aO_NF_ADMISSION_DATE']));
    }

    if (data['aO_REQUESTED_LOC_DATE'] != null)
      this.sectionAForm.get('aO_REQUESTED_LOC_DATE').setValue(new Date(data['aO_REQUESTED_LOC_DATE']));

    if (data['aA_DATE_OF_BIRTH'] != null)
      this.sectionAForm.get('aA_DATE_OF_BIRTH').setValue(new Date(data['aA_DATE_OF_BIRTH']));

    //set assessor src
    let ass_src: string[] = [];
    if (data['aO_SOURCE_PRIMARY_CAREGIVER'] == 'Y') {
      ass_src.push('CAREGIVER');
    }
    if (data['aO_SOURCE_PROVIDER'] == 'Y') {
      ass_src.push('PROVIDER');
    }
    if (data['aO_SOURCE_FAMILY'] == 'Y') {
      ass_src.push('FAMILY');
    }
    if (data['aO_SOURCE_GUARDIAN'] == 'Y') {
      ass_src.push('LEGALGUARD');
    }
    if (data['aO_SOURCE_MED_RECORD'] == 'Y') {
      ass_src.push('MEDRECORD');
    }
    if (data['aO_SOURCE_PHYSICIAN'] == 'Y') {
      ass_src.push('PHYSICIAN');
    }
    if (data['aO_SOURCE_OTHER'] == 'Y') {
      ass_src.push('OTHER');
    }
    if (data['aO_SOURCE_INDIVIDUAL'] == 'Y') {
      ass_src.push('INDIVIDUAL');
    }
    this.sectionAForm.get('aO_ASSESS_SOURCE').setValue(ass_src);

    //set aO_ASSESSOR_QUALIFICATION
    let ass_qual: string[] = [];
    if (data['aO_ASSESSOR_RN'] == 'Y') {
      ass_qual.push('RN');
    }
    if (data['aO_ASSESSOR_LSW'] == 'Y') {
      ass_qual.push('LSW');
    }
    if (data['aO_ASSESSOR_LISW'] == 'Y') {
      ass_qual.push('LISW');
    }
    this.sectionAForm.get('aO_ASSESSOR_QUALIFICATION').setValue(ass_qual);

    //set aA_INDIVIDUAL_RACE
    let race_opt: string[] = [];
    if (data['aA_RACE_NATIVE'] == 'Y') {
      race_opt.push('AMINDIAN');
    }
    if (data['aA_RACE_ASIAN'] == 'Y') {
      race_opt.push('ASIAN');
    }
    if (data['aA_RACE_BLACK'] == 'Y') {
      race_opt.push('BLACK');
    }
    if (data['aA_RACE_PACIFIC'] == 'Y') {
      race_opt.push('HAWAIIAN');
    }
    if (data['aA_RACE_WHITE'] == 'Y') {
      race_opt.push('WHITE');
    }
    this.sectionAForm.get('aA_INDIVIDUAL_RACE').setValue(race_opt);

    //assess reasons
    let assess_reason_fnd = false;
    if (this.assessmentReasons != null) {
      this.assessmentReasons.forEach((row) => {
        if (data['aO_ASSESS_REASON'] == row.dV_VALUE) {
          assess_reason_fnd = true;
          return;
        }
      });
    }

    if (!assess_reason_fnd) {
      this.sectionAForm.get('aO_ASSESS_REASON').reset();
    }

    //assess place
    if (this.assessorPlaces != null) {
      let assess_place = false;
      this.assessorPlaces.forEach((row) => {
        if (data['aO_ASSESS_PLACE'] == row.dV_VALUE) {
          assess_place = true;
          return;
        }
      });
      if (!assess_place) {
        this.sectionAForm.get('aO_ASSESS_PLACE').reset();
      }
    }

    //aA_MEDICAID_B
    this.sectionAForm.get('aA_MEDICAID_A').setValue(data['aA_MEDICAID_A'] == 'Y');
    this.sectionAForm.get('aA_MEDICAID_B').setValue(data['aA_MEDICAID_B'] == 'Y');


  }//setFormData(data)

    private calculateAge(birthdate): any {
        let diff: number = Math.abs(Date.now() - birthdate);       
        return Number(new Date(diff).getUTCFullYear() - 1970);
  }


  onChanges(): void {

      this.sectionAForm.get('aA_DATE_OF_BIRTH').valueChanges.subscribe((dob: Date) => {
        //  console.log(dob);
      this.sectionAForm.patchValue({ aA_AGE: this.calculateAge(dob) });
    });

    //this.sectionAForm.get('aA_AGE').valueChanges.subscribe(agelimit => {
    //  if (agelimit > 120 || agelimit < 30) {
    //    this.sectionAForm.get('aA_AGE').setErrors({ 'incorrect': true });
    //  }
    //});
    this.sectionAForm.get('aA_ZIP').valueChanges.subscribe(zipcode => {
      if (zipcode != undefined) {
        let city = this.strCity;
        let county = this.strCounty
        let zipcode1 = this.strZipcode;
        let zip = { ZipCode: this.sectionAForm.get('aA_ZIP').value };
        if (zip.ZipCode.length == 5) {
          this.sectionAService.getCityState(zip).subscribe(
            cityState => {
              if (zip.ZipCode == zipcode1) {
                if (cityState.city != city && city != null) {
                  this.sectionAForm.patchValue({ aA_CITY: city });
                } else {
                  this.sectionAForm.patchValue({ aA_CITY: cityState.city });
                }
                if (cityState.countY_CODE != county && county != null) {
                  this.sectionAForm.patchValue({ aA_COUNTY_CODE: county });
                } else {
                  this.sectionAForm.patchValue({ aA_COUNTY_CODE: cityState.countY_CODE });
                }
                this.sectionAForm.patchValue({ aA_STATE: (zipcode.substring(0, 2) == '43' || zipcode.substring(0, 2) == '44' || zipcode.substring(0, 2) == '45') ? 'OH' : '' });
              } else {
                this.sectionAForm.patchValue({ aA_CITY: cityState.city });
                this.sectionAForm.patchValue({ aA_STATE: (zipcode.substring(0, 2) == '43' || zipcode.substring(0, 2) == '44' || zipcode.substring(0, 2) == '45') ? 'OH' : '' });
                this.sectionAForm.patchValue({ aA_COUNTY_CODE: cityState.countY_CODE });
              }
             
              this.alertsService.clearLoader();
            }, err => {
              this.alertsService.error(err);
            });
        }
        else {
          this.sectionAForm.patchValue({ aA_CITY: '' });
          this.sectionAForm.patchValue({ aA_STATE: '' });
          this.sectionAForm.patchValue({ aA_COUNTY_CODE: '' });
        }
      }
      else {
        this.sectionAForm.patchValue({ aA_CITY: '' });
        this.sectionAForm.patchValue({ aA_STATE: '' });
        this.sectionAForm.patchValue({ aA_COUNTY_CODE: '' });
      }
    }, err => {
      this.alertsService.error(err);
    });

  }

  ClearForm(e) { 
    this.onClear();
  }

  showReview() {
    this.router.navigate(['summary']);
  }
  newDocument() {

    let userInfo = this.userService.getCurrentUser();
    if (userInfo.userType == 'MCP') {
      this.show_MCP_User = true;     
      this.show_MCP_Req = 'Reason for Assessment';
      this.sectionAForm.get('aO_ASSESSOR').clearValidators();
      this.sectionAForm.get('aO_ASSESS_SOURCE').clearValidators();
      this.sectionAForm.get('aO_ASSESSOR_QUALIFICATION').clearValidators();
      this.sectionAForm.get('aO_ASSESS_PLACE').clearValidators();
      this.sectionAForm.patchValue({ submitteR_FIRST_NAME: userInfo.userFirstName, submitteR_LAST_NAME: userInfo.userLastName, aO_ASSESS_REASON: 'MCOW' });
    } else {      
      this.show_MCP_User = false;         
      this.show_MCP_Req = 'Reason for Assessment*';
     this.sectionAForm.patchValue({ submitteR_FIRST_NAME: userInfo.userFirstName, submitteR_LAST_NAME: userInfo.userLastName });
     
    }    
  
  }
  mcpEdit() {

    let userInfo = this.userService.getCurrentUser();
    if (userInfo.userType == 'MCP') {
      this.show_MCP_User = true;
      this.show_MCP_Req = 'Reason for Assessment';
      this.sectionAForm.get('aO_ASSESSOR').clearValidators();
      this.sectionAForm.get('aO_ASSESS_SOURCE').clearValidators();
      this.sectionAForm.get('aO_ASSESSOR_QUALIFICATION').clearValidators();
      this.sectionAForm.get('aO_ASSESS_PLACE').clearValidators();
      this.sectionAForm.patchValue({ aO_ASSESS_REASON: 'MCOW' });
    } else {
      this.show_MCP_User = false;
      this.show_MCP_Req = 'Reason for Assessment*';      
    }

  }
  loadUserInfo() {
    this.userService.currentUser.subscribe((userData: User) => { this.profileUser = userData; });
    this.sectionAForm.get('aO_CREATE_AGENCY').setValue(this.profileUser.userAgencyName);
    this.alertsService.clearLoader();
  }

  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      let userInfo = this.userService.getCurrentUser();
      if (result.value) {
        if (userInfo.userType == 'MCP') {
          this.sectionAForm.reset({ aO_ASSESS_REASON: 'MCOW' });
        } else {
          this.sectionAForm.reset();
        }
        
      }
    });

  }
}
