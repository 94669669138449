import { ViewChild, Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import {
    FormGroup, FormControl, Validators, ValidatorFn, CheckboxRequiredValidator, AbstractControl
} from '@angular/forms';
import { MatExpansionPanel, MatInputModule, MatInput } from '@angular/material';
import { Observable, from, fromEvent, of } from 'rxjs';
import { filter, isEmpty, debounceTime } from 'rxjs/operators';
import { SectionB1Service, AlertsService } from '../../../shared/index';
import { SectionB1ComponentServiceService } from '../section-b1-component-service.service';
import { isNullOrUndefined } from 'util';
import { SectionB1DataRow } from '../section-b1-data-row';
import { validateFreqOptions } from './subsection-form-validators';
import { SkillLevelOptions } from '../section-b1-util';

@Component({
    selector: 'app-sub-section',
    templateUrl: './sub-section.component.html',
    styleUrls: ['./sub-section.component.css']
})
export class SubSectionComponent implements OnInit, AfterViewInit {

    sectionForm = new FormGroup({
        NotApplicable: new FormControl(true),
        SkillLevel: new FormControl('', [Validators.required]),
        FrequencyOptions: new FormControl(''),
        WeeklyOptions: new FormGroup({
            SUN: new FormControl(''),
            MON: new FormControl(''),
            TUE: new FormControl(''),
            WED: new FormControl(''),
            THU: new FormControl(''),
            FRI: new FormControl(''),
            SAT: new FormControl('')
        }),
        FrequencyOthers: new FormControl(''),
        Comments: new FormControl('')
    });

    originalRowData: SectionB1DataRow;

    @ViewChild(MatExpansionPanel, { static: false })
    sectionPanel: MatExpansionPanel;

    @ViewChild('sectionFormEle', { static: false })
    formElement: ElementRef;

    @ViewChild('FrequencyOtherstxt', { static: false })
    FrequencyOtherstxt: ElementRef;

    isRowEnabled: boolean;
    isFrequencyDisable: boolean;
    isInitialLoadComplete: boolean;
    //isThisNewRecord = true;

    @Input()
    heading: string;

    @Input()
    hint: string;

    @Input()
    skilL_ID: string;


    //sectionForm: FormGroup;

    @Input()
    CustomSection: FormGroup;

    @Output()
    FormChanged = new EventEmitter<any>();

    isFrqWeekly: boolean;
    isFrqOthers: boolean;

    _skillLevelSettings: SkillLevelOptions;
    set skillLevelSettings(setting: SkillLevelOptions) {
        this._skillLevelSettings = setting;
        this.updateSkillLevelSetting();
    }
    get skillLevelSettings(): SkillLevelOptions {
        return this._skillLevelSettings;
    }

    get NotApplicableOption(): FormControl {
        return this.sectionForm.get('NotApplicable') as FormControl;
    }

    get IsWeeklyOptionsValid() {
        return this.sectionForm.get('WeeklyOptions').invalid;
    }

    get SkillLevelOption() {
        return this.sectionForm.get('SkillLevel');
    }

    get FreqOptions() {
        return this.sectionForm.get('FrequencyOptions');
    }

    get FreqOther() {
        return this.sectionForm.get('FrequencyOthers');
    }

    get CommentsTxt() {
        return this.sectionForm.get('Comments');
    }


    constructor(
        private secB1CompService: SectionB1ComponentServiceService) { }


    ngOnInit() {

        this.initVariables();

        this.loadDataWhenAvailable();

        this.configDynamicFormSettings();

    }

    ngAfterViewInit(): void {

        //console.log(this.FrequencyOtherstxt.nativeElement);
        //fromEvent(this.formElement.nativeElement, 'onblur').subscribe((d) => {
        //  console.log(d);
        //});;

    }

    private configDynamicFormSettings() {

        this.sectionForm.get('SkillLevel').valueChanges.subscribe(value => {

            if (this.isInitialLoadComplete && this.sectionPanel != null && value != '') {
                this.sectionPanel.open();
            }
            //this.sectionForm.get('FrequencyOptions').reset();
            


            //this.sectionForm.get('WeeklyOptions').clearValidators();
            //this.sectionForm.get('WeeklyOptions').updateValueAndValidity();

            ///independent
            if (value === 'I') {
                this.isFrqOthers = false;
                this.isFrqWeekly = false;
                this.isFrequencyDisable = true;

                this.sectionForm.get('FrequencyOptions').reset();
                this.sectionForm.get('FrequencyOptions').disable();
                this.sectionForm.get('FrequencyOptions').clearValidators();
                this.sectionForm.get('FrequencyOptions').updateValueAndValidity();

                this.sectionForm.get('WeeklyOptions').reset();
                this.sectionForm.get('WeeklyOptions').clearValidators();
                this.sectionForm.get('WeeklyOptions').updateValueAndValidity();
                this.sectionForm.get('WeeklyOptions').disable();
            } else if (value === 'F') //frequency
            {
                this.isFrequencyDisable = false;
                this.sectionForm.get('FrequencyOptions').enable();
                this.sectionForm.get('FrequencyOptions').setValidators([Validators.required]);
                this.sectionForm.get('FrequencyOptions').updateValueAndValidity();
                //console.log('FrequencyOptions' , this.sectionForm.get('FrequencyOptions'))      
            } else if (value == null) {

                this.isFrqOthers = false;
                this.isFrqWeekly = false;
                this.isFrequencyDisable = true;
                this.sectionForm.get('FrequencyOptions').reset();
                this.sectionForm.get('FrequencyOptions').disable();
                this.sectionForm.get('FrequencyOptions').clearValidators();
                this.sectionForm.get('FrequencyOptions').updateValueAndValidity();

                this.sectionForm.get('WeeklyOptions').reset();
                this.sectionForm.get('WeeklyOptions').clearValidators();
                this.sectionForm.get('WeeklyOptions').updateValueAndValidity();
                this.sectionForm.get('WeeklyOptions').disable();

            }
            //this.isInitialLoadComplete = true;
            //this.sectionForm.get('Comments').setValidators([Validators.required]);
        });

        this.sectionForm.get('FrequencyOptions').valueChanges.subscribe(value => {
            
            switch (value) {
                case 'WEEKLY':
                    this.isFrqWeekly = true;
                    this.sectionForm.get('WeeklyOptions').enable();
                    this.sectionForm.get('WeeklyOptions').setValidators(validateFreqOptions);
                    this.sectionForm.get('WeeklyOptions').updateValueAndValidity();
                    break;
                case 'OTHER':
                    this.isFrqOthers = true;
                    this.sectionForm.get('FrequencyOthers').enable();
                    this.sectionForm.get('FrequencyOthers').setValidators([Validators.required]);
                    this.sectionForm.get('FrequencyOthers').updateValueAndValidity();
                    //this.sectionForm.get('FrequencyOthers').markAsTouched();
                    break;
                default:
                    this.sectionForm.get('FrequencyOthers').clearValidators();
                    this.sectionForm.get('FrequencyOthers').updateValueAndValidity();
                    this.sectionForm.get('FrequencyOthers').disable();
                    this.sectionForm.get('FrequencyOthers').reset();

                    this.sectionForm.get('WeeklyOptions').disable();
                    this.sectionForm.get('WeeklyOptions').clearValidators();
                    this.sectionForm.get('WeeklyOptions').reset();
                    this.sectionForm.get('WeeklyOptions').updateValueAndValidity();
                    
                    this.isFrqOthers = false;
                    this.isFrqWeekly = false;
                    break;
            }
        });

        this.sectionForm.get('NotApplicable').valueChanges.subscribe(value => {
            if (value) {
                this.resetForm();
                this.isRowEnabled = true;
                for (let cntrl in this.sectionForm.controls) {
                    if (cntrl == 'NotApplicable')
                        continue;
                    this.sectionForm.controls[cntrl].disable();
                }
                this.isFrequencyDisable = true;
                //close the panel if user checked NA & initial data load complete
                if (this.isInitialLoadComplete) {
                    this.sectionPanel.close();
                }
            }
            //applicable
            if (!value) {
                for (let cntrl in this.sectionForm.controls) {
                    if (cntrl == 'NotApplicable')
                        continue;
                    this.sectionForm.controls[cntrl].enable();
                }
                this.sectionForm.get('SkillLevel').setValidators([Validators.required]);
                this.sectionForm.get('SkillLevel').updateValueAndValidity();
                this.sectionForm.get('FrequencyOptions').setValidators([Validators.required]);
                //this.sectionForm.get('Comments').setValidators([Validators.required]);
                //open it if the data load is complete & user un checked NA
                if (this.isInitialLoadComplete) {
                    this.sectionPanel.open();
                }
                //this.sectionForm.markAllAsTouched();
            }
            //keep it collpased  if the data is not complete
            if (!this.isInitialLoadComplete) {
                this.sectionPanel.close();
            }

            this.updateSkillLevelSetting();

        });

        

    }

    private loadDataWhenAvailable() {
        //wired to intera component service
        this.secB1CompService.subsectionDataEmitter
            .subscribe(b1data => {
                for (let i in b1data) {
                    let rowData = b1data[i];
                    if (rowData['skilL_ID'] === this.skilL_ID) {
                        this.originalRowData = Object.assign({}, rowData);
                        let form_data = {
                            'NotApplicable': rowData.aB1_SKILL_LEVEL == 'N',
                            'SkillLevel': rowData.aB1_SKILL_LEVEL,
                            'FrequencyOptions': rowData.aB1_FREQ,
                            'WeeklyOptions': this.getWeeklyOptionsFromSrc(rowData.aB1_FREQ_DAYS),
                            'FrequencyOthers': rowData.aB1_FREQ_COMMENT,
                            'Comments': rowData.aB1_SKILL_COMMENT
                        };

                        this.sectionForm.patchValue(form_data);
                        this.sectionForm.get('NotApplicable').updateValueAndValidity();
                    } else {
                        //console.log('skill id did not match');
                        //console.log(rowData['skilL_ID'], ' - ', this.skilL_ID);
                    }
                }

            });
    }

    private initVariables() {
        this.isInitialLoadComplete = false;
        this.isFrqWeekly = false;
        this.isFrqOthers = false;
        this.isFrequencyDisable = true;

        //form change event -> return the original row after updating new changes
        this.sectionForm.valueChanges.pipe(debounceTime(500)).subscribe(changes => {
            if (this.originalRowData == null) return;
            this.originalRowData.aB1_FREQ = this.sectionForm.get('FrequencyOptions').value;
            this.originalRowData.aB1_FREQ_DAYS = this.getSelectedWeeklyOptnValues()
            this.originalRowData.aB1_FREQ_COMMENT = this.sectionForm.get('FrequencyOthers').value;
            this.originalRowData.aB1_SKILL_LEVEL = this.sectionForm.get('NotApplicable').value == true ? 'N' : this.sectionForm.get('SkillLevel').value;
            this.originalRowData.aB1_SKILL_COMMENT = this.sectionForm.get('Comments').value;

            this.FormChanged.emit(this.originalRowData);
        });
    }

    resetForm() {

        this.sectionForm.get('SkillLevel').clearValidators();
        this.sectionForm.get('SkillLevel').reset();

        this.sectionForm.get('FrequencyOptions').clearValidators();
        this.sectionForm.get('FrequencyOptions').reset();

        this.sectionForm.get('WeeklyOptions').reset();

        this.sectionForm.get('WeeklyOptions').get('SUN').clearValidators();
        this.sectionForm.get('WeeklyOptions').get('SUN').setValue('');

        this.sectionForm.get('WeeklyOptions').get('MON').clearValidators();
        this.sectionForm.get('WeeklyOptions').get('MON').setValue('');

        this.sectionForm.get('WeeklyOptions').get('TUE').clearValidators();
        this.sectionForm.get('WeeklyOptions').get('TUE').setValue('');

        this.sectionForm.get('WeeklyOptions').get('WED').clearValidators();
        this.sectionForm.get('WeeklyOptions').get('WED').setValue('');

        this.sectionForm.get('WeeklyOptions').get('THU').clearValidators();
        this.sectionForm.get('WeeklyOptions').get('THU').setValue('');

        this.sectionForm.get('WeeklyOptions').get('FRI').clearValidators();
        this.sectionForm.get('WeeklyOptions').get('FRI').setValue('');

        this.sectionForm.get('WeeklyOptions').get('SAT').clearValidators();
        this.sectionForm.get('WeeklyOptions').get('SAT').setValue('');

        this.sectionForm.get('FrequencyOthers').clearValidators();
        //this.sectionForm.get('FrequencyOthers').setValue('');
        this.sectionForm.get('FrequencyOthers').reset();

        //this.sectionForm.get('Comments').clearValidators();
        //this.sectionForm.get('Comments').setValue('');
        this.sectionForm.get('Comments').reset();
        this.sectionForm.updateValueAndValidity();


    }

    private getWeeklyOptionsFromSrc(wklyOpt: string) {
        if (!isNullOrUndefined(wklyOpt)) {
            let days = wklyOpt.split(",");
            return {
                'SUN': days.indexOf('SUN') > -1,
                'MON': days.indexOf('MON') > -1,
                'TUE': days.indexOf('TUE') > -1,
                'WED': days.indexOf('WED') > -1,
                'THU': days.indexOf('THU') > -1,
                'FRI': days.indexOf('FRI') > -1,
                'SAT': days.indexOf('SAT') > -1
            }
        }
        return {
            'SUN': false,
            'MON': false,
            'TUE': false,
            'WED': false,
            'THU': false,
            'FRI': false,
            'SAT': false
        }
    }

    private getSelectedWeeklyOptnValues() {
        //console.log('WeeklyOptions', this.sectionForm.get('WeeklyOptions').value);
        let weeklyoptn = this.sectionForm.get('WeeklyOptions').value;
        let weeklyoptnarr = [];
        for (let key in weeklyoptn) {
            //console.log(key, weeklyoptn[key]);
            if (weeklyoptn[key]) {
                weeklyoptnarr.push(key);
            }
        }
        //console.log(weeklyoptnarr.join(','));
        return weeklyoptnarr.join(',');
    }

    updateSkillLevelSetting() {
        if (this._skillLevelSettings != null && this._skillLevelSettings == SkillLevelOptions.IndependentOnly) {
            if (!this.sectionForm.get('NotApplicable').value) {
                this.sectionForm.get('SkillLevel').setValue('F');
                this.sectionForm.get('SkillLevel').disable();
            }
        }
    }

}
