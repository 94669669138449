import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SectionBRoutingModule } from './section-b-routing.module';
import { SectionBComponent } from './section-b/section-b.component';
import { PhysicianSelectorComponent } from './section-b/physician-selector/physician-selector.component';
import { SharedModule } from '../shared';

@NgModule({
  declarations: [SectionBComponent, PhysicianSelectorComponent],
  imports: [
    CommonModule,
    SectionBRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SectionBModule { }
