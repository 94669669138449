import { SectionB1Component } from './section-b1.component';
import { Validators } from '@angular/forms';
import { validateFutureDate } from '../../shared/validators/AlocDateValidators';

export function handleNAchangesInSubSection(form: SectionB1Component) {

    //sub section na notification
  //TFS 2440,2459,2461 Not Required changes Commented codebelow
    form.BowelorColostomyComp.NotApplicableOption.valueChanges.subscribe(value => {
        //console.log(form.BowelorColostomyComp.sectionForm);
        //console.log(form.BowelColostomy);
        if (value) {
            //section form
            form.BowelColostomy.reset();
            form.BowelColostomy.get('SkilledTask').disable();
            form.BowelColostomy.get('SkilledTask').clearValidators();
            form.isBowelSkilledTaskOtherSelected = false;
        } else {
            form.BowelColostomy.get('SkilledTask').enable();
            //form.BowelColostomy.get('SkilledTask').setValidators([Validators.required]);
        }
        form.BowelColostomy.get('SkilledTask').updateValueAndValidity();
    });

    //sub section na notification
    form.DialysisComp.NotApplicableOption.valueChanges.subscribe(value => {
        //console.log(form.DialysisComp.sectionForm);
        //console.log(form.Dialysis);
        if (value) {
            //section form
            //form.Dialysis.reset();
            form.Dialysis.get('TypeOfDialysis').setValue('');
            form.Dialysis.get('TypeOfDialysis').disable();
            form.Dialysis.get('TypeOfDialysis').clearValidators();

            form.Dialysis.get('Location').setValue('');
            form.Dialysis.get('Location').disable();
            form.Dialysis.get('Location').clearValidators();
        } else {
            form.Dialysis.get('TypeOfDialysis').enable();
            //TFS 2440 Not Required changes Commented codebelow
            //form.Dialysis.get('TypeOfDialysis').setValidators([Validators.required]);

            form.Dialysis.get('Location').enable();
            //form.Dialysis.get('Location').setValidators([Validators.required]);
        }
        //console.log(form.DialysisComp.sectionForm);
        //console.log(form.Dialysis);
        form.Dialysis.get('TypeOfDialysis').updateValueAndValidity();
        form.Dialysis.get('Location').updateValueAndValidity();
    });

    //sub section na notification
    form.IVsPeripheralCentralLinesComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.IVS.reset();
            form.IVS.get('TypeOfIV').setValue('');
            form.IVS.get('TypeOfIV').clearValidators();
            form.IVS.get('TypeOfIV').disable();

            form.IVS.get('Location').setValue('');
            form.IVS.get('Location').clearValidators();
            form.IVS.get('Location').disable();

        } else {
            form.IVS.get('TypeOfIV').enable();
            //form.IVS.get('TypeOfIV').setValidators([Validators.required]);

            form.IVS.get('Location').enable();
            //form.IVS.get('Location').setValidators([Validators.required]);
        }
        form.IVS.get('TypeOfIV').updateValueAndValidity();
        form.IVS.get('Location').updateValueAndValidity();
    });



    //sub section na notification
    form.TOTALPARENTERALNUTRITIONComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.ParenteralNutrition.reset();
            form.ParenteralNutrition.get('kcalperday').disable();
            form.ParenteralNutrition.get('kcalperday').clearValidators();
            form.ParenteralNutrition.get('mlkgday').clearValidators();
            form.ParenteralNutrition.get('mlkgday').disable();
        } else {
            form.ParenteralNutrition.get('kcalperday').enable();
            //form.ParenteralNutrition.get('kcalperday').setValidators([Validators.required]);
            form.ParenteralNutrition.get('mlkgday').enable();
            //form.ParenteralNutrition.get('mlkgday').setValidators([Validators.required]);
        }
        form.ParenteralNutrition.get('kcalperday').updateValueAndValidity();
        form.ParenteralNutrition.get('mlkgday').updateValueAndValidity();
    });

    //sub section na notification
    form.TubeFeedingsComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.TubeFeedings.reset();
            form.TubeFeedings.get('TypeofTubeFeeding').disable();
            form.TubeFeedings.get('TypeofTubeFeeding').clearValidators();

            form.TubeFeedings.get('TypeofTubeFeedingOther').clearValidators();
            form.TubeFeedings.get('TypeofTubeFeedingOther').disable();

            form.TubeFeedings.get('Duration').clearValidators();
            form.TubeFeedings.get('Duration').disable();

            form.TubeFeedings.get('MethodofDelivery').clearValidators();
            form.TubeFeedings.get('MethodofDelivery').disable();
        } else {

            form.TubeFeedings.get('TypeofTubeFeeding').enable();
            //form.TubeFeedings.get('TypeofTubeFeeding').setValidators([Validators.required]);

            form.TubeFeedings.get('TypeofTubeFeedingOther').enable();

            form.TubeFeedings.get('Duration').enable();
            //form.TubeFeedings.get('Duration').setValidators([Validators.required]);

            form.TubeFeedings.get('MethodofDelivery').enable();
            //form.TubeFeedings.get('MethodofDelivery').setValidators([Validators.required]);

        }
        form.TubeFeedings.get('TypeofTubeFeeding').updateValueAndValidity();
        form.TubeFeedings.get('Duration').updateValueAndValidity();
        form.TubeFeedings.get('MethodofDelivery').updateValueAndValidity();
    });

    //sub section na notification
    form.OxygenComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
          form.Oxygen.disable();
          // Object .values is not supporting by IE 11 so we used object.keys

            //Object.values(form.Oxygen.controls).forEach(cntrl => {
            //    cntrl.reset();
            //    cntrl.clearValidators();
            //});
          Object.keys(form.Oxygen.controls).forEach(cntrl => {
            form.Oxygen.controls[cntrl].reset();
            form.Oxygen.controls[cntrl].clearValidators();            
          });
            //form.Oxygen.reset();
            //form.Oxygen.get('OxygenUse').disable();
            //form.Oxygen.get('OxygenUse').clearValidators();

            //form.Oxygen.get('Method').clearValidators();
            //form.Oxygen.get('Method').disable();

            //form.Oxygen.get('MethodOther').clearValidators();
            //form.Oxygen.get('MethodOther').disable();

            //form.Oxygen.get('SpecialOrders').clearValidators();
            //form.Oxygen.get('SpecialOrders').disable();
        } else {

            form.Oxygen.enable();
            //Object.values(form.Oxygen.controls).forEach(cntrl => {
            //    cntrl.setValidators([Validators.required]);
            //});

            //form.Oxygen.get('OxygenUse').enable();
            //form.Oxygen.get('OxygenUse').setValidators([Validators.required]);     

            //form.Oxygen.get('Method').enable();
            //form.Oxygen.get('Method').setValidators([Validators.required]);      

            //form.Oxygen.get('SpecialOrders').enable();
            //form.Oxygen.get('SpecialOrders').setValidators([Validators.required]);      

            //form.Oxygen.get('MethodOther').enable();
        }
        form.Oxygen.get('OxygenUse').updateValueAndValidity();
        form.Oxygen.get('Method').updateValueAndValidity();
        form.Oxygen.get('SpecialOrders').updateValueAndValidity();
    });

    //sub section na notification
    form.DeepSuctioningComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.DeepSuctioning.reset();
            form.DeepSuctioning.get('Types').disable();
            form.DeepSuctioning.get('Types').clearValidators();
        } else {
            form.DeepSuctioning.get('Types').enable();
            //form.DeepSuctioning.get('Types').setValidators([Validators.required]);
        }
        form.DeepSuctioning.get('Types').updateValueAndValidity();
    });

    //sub section na notification
    form.VentilatorComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.Ventilator.reset();
            form.Ventilator.get('Duration').disable();
            form.Ventilator.get('Duration').clearValidators();
        } else {
            form.Ventilator.get('Duration').enable();
            //form.Ventilator.get('Duration').setValidators([Validators.required]);
        }
        form.Ventilator.get('Duration').updateValueAndValidity();
    });

    //sub section na notification
    form.RespiratoryTreatmentBiPAPComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.RespiratoryTreatment.reset();
            form.RespiratoryTreatment.get('Duration').disable();
            form.RespiratoryTreatment.get('Duration').clearValidators();

        } else {

            form.RespiratoryTreatment.get('Duration').enable();
            //form.RespiratoryTreatment.get('Duration').setValidators([Validators.required]);
        }
        form.RespiratoryTreatment.get('Duration').updateValueAndValidity();
    });

    //sub section na notification
    form.RespiratoryTreatmentBiPAPComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.RespiratoryTreatmentBiPAP.reset();
            form.RespiratoryTreatmentBiPAP.get('Duration').disable();
            form.RespiratoryTreatmentBiPAP.get('Duration').clearValidators();
        } else {
            form.RespiratoryTreatmentBiPAP.get('Duration').enable();
            //form.RespiratoryTreatmentBiPAP.get('Duration').setValidators([Validators.required]);
        }
        form.RespiratoryTreatmentBiPAP.get('Duration').updateValueAndValidity();
    });

    //sub section na notification
    form.RESPIRATORYTREATMENTIPPBComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.RespiratoryTreatmentIPPB.reset();
            form.RespiratoryTreatmentIPPB.get('Duration').disable();
            form.RespiratoryTreatmentIPPB.get('Duration').clearValidators();
        } else {
            form.RespiratoryTreatmentIPPB.get('Duration').enable();
            //form.RespiratoryTreatmentIPPB.get('Duration').setValidators([Validators.required]);
        }
        form.RespiratoryTreatmentIPPB.get('Duration').updateValueAndValidity();
    });

    //sub section na notification
    form.RESPIRATORYTREATMENTCHESTPERCUSSIONComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.RespiratoryTreatmentChestPercussion.reset();
            form.RespiratoryTreatmentChestPercussion.get('Duration').disable();
            form.RespiratoryTreatmentChestPercussion.get('Duration').clearValidators();
        } else {
            form.RespiratoryTreatmentChestPercussion.get('Duration').enable();
            //form.RespiratoryTreatmentChestPercussion.get('Duration').setValidators([Validators.required]);
        }
        form.RespiratoryTreatmentChestPercussion.get('Duration').updateValueAndValidity();
    });

    //sub section na notification
    form.RespiratoryTreatmentAssistDeviceComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.RespiratoryTreatmentAssistDevice.reset();
            form.RespiratoryTreatmentAssistDevice.get('Duration').disable();
            form.RespiratoryTreatmentAssistDevice.get('Duration').clearValidators();
        } else {
            form.RespiratoryTreatmentAssistDevice.get('Duration').enable();
            //form.RespiratoryTreatmentAssistDevice.get('Duration').setValidators([Validators.required]);
        }
        form.RespiratoryTreatmentAssistDevice.get('Duration').updateValueAndValidity();
    });

    //sub section na notification
    form.UrinaryCatheterUrostomyRelatedSkilledTasksComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.UrinaryCatheterSkilledTasks.reset();
            form.UrinaryCatheterSkilledTasks.get('TypeofCatheter').disable();
            form.UrinaryCatheterSkilledTasks.get('TypeofCatheter').clearValidators();
            form.UrinaryCatheterSkilledTasks.get('Dateoflastchange').disable();
            form.UrinaryCatheterSkilledTasks.get('Dateoflastchange').clearValidators();
        } else {
            form.UrinaryCatheterSkilledTasks.get('TypeofCatheter').enable()
            //form.UrinaryCatheterSkilledTasks.get('TypeofCatheter').setValidators([Validators.required]);
            form.UrinaryCatheterSkilledTasks.get('Dateoflastchange').enable();
            form.UrinaryCatheterSkilledTasks.get('Dateoflastchange').setValidators([validateFutureDate]);
        }
        form.UrinaryCatheterSkilledTasks.get('TypeofCatheter').updateValueAndValidity();
        form.UrinaryCatheterSkilledTasks.get('Dateoflastchange').updateValueAndValidity();
    });

    //sub section na notification
    form.SeizureManagementComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.SeizureManagement.reset();
            form.SeizureManagement.get('SeizureSeverity').disable();
            form.SeizureManagement.get('SeizureSeverity').clearValidators();
            form.SeizureManagement.get('Dateoflastseizure').disable();
            form.SeizureManagement.get('Dateoflastseizure').clearValidators();
        } else {
            form.SeizureManagement.get('SeizureSeverity').enable()
            //form.SeizureManagement.get('SeizureSeverity').setValidators([Validators.required]);
            form.SeizureManagement.get('Dateoflastseizure').enable()
            form.SeizureManagement.get('Dateoflastseizure').setValidators([validateFutureDate]);
        }
        form.SeizureManagement.get('SeizureSeverity').updateValueAndValidity();
        form.SeizureManagement.get('Dateoflastseizure').updateValueAndValidity();
    });

    //Glucose Checks*
    form.GlucoseChecksComp.NotApplicableOption.valueChanges.subscribe(value => {
        if (value) {
            //section form
            form.GlucoseChecks.reset();
            form.GlucoseChecks.get('TypeofGlucoseChecks').disable();
            form.GlucoseChecks.get('TypeofGlucoseChecks').clearValidators();
        } else {
            form.GlucoseChecks.get('TypeofGlucoseChecks').enable();
            //form.GlucoseChecks.get('TypeofGlucoseChecks').setValidators([Validators.required]);
        }
        form.GlucoseChecks.get('TypeofGlucoseChecks').updateValueAndValidity();
    });

    //Other Skilled Nursing Service
    form.OtherSkilledNursingServiceComp.NotApplicableOption.valueChanges.subscribe(value => {
      if (value) {
        //section form
        form.OtherSkilledNursingService.reset();
        form.OtherSkilledNursingService.get('Service').disable();
        form.OtherSkilledNursingService.get('Service').clearValidators();
      } else {
        form.OtherSkilledNursingService.get('Service').enable();
        form.OtherSkilledNursingService.get('Service').setValidators([Validators.required]);
      }
      form.OtherSkilledNursingService.get('Service').updateValueAndValidity();
    });

    //Other Skilled Rehabilitation Service
    form.OtherSkilledRehabilitationServiceComp.NotApplicableOption.valueChanges.subscribe(value => {
      if (value) {
        //section form
        form.OtherSkilledRehabilitationService.reset();
        form.OtherSkilledRehabilitationService.get('Service').disable();
        form.OtherSkilledRehabilitationService.get('Service').clearValidators();
      } else {
        form.OtherSkilledRehabilitationService.get('Service').enable();
        form.OtherSkilledRehabilitationService.get('Service').setValidators([Validators.required]);
      }
      form.OtherSkilledRehabilitationService.get('Service').updateValueAndValidity();
    });

}
