import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SummaryResponse, TopSummary } from '../models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SummaryService {

  constructor(private apiService: ApiService) { }

  getSummary(alocqId): Observable<SummaryResponse> {
    //console.log(alocqId);
      return this.apiService.get('summary/', alocqId).pipe(map(data =>
      {
          //console.log(data);
          return data;
      }));
  }
 
  PostLOCDetermination(LOCformData) {

    return this.apiService.post('summary/PostLOCDetermination', LOCformData);
  }
  PostMCPReview(MCPformData) {
    return this.apiService.post('summary/PostMCPReview', MCPformData);
    }

    withDraw(alocqId): Observable<any> {
        return this.apiService.put('summary', alocqId).pipe(map(data => { return data; }));
    }
}
