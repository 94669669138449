import { SectionB1Component } from './section-b1.component';
import { Validators, AbstractControl } from '@angular/forms';

export function configureDynamicValidation(form: SectionB1Component) {

  //BowelColostomy
  //TFS 2459,2461 Not Required changes Commented codebelow
  form.BowelColostomy.get('SkilledTask').valueChanges.subscribe(task => {
    //console.log('SkilledTask', task);
    if (task === 'OTHER') {
      form.isBowelSkilledTaskOtherSelected = true;
      //form.BowelColostomy.get('OtherInfo').setValidators([Validators.required]);
      form.BowelColostomy.get('OtherInfo').updateValueAndValidity();
    } else {
      form.BowelColostomy.get('OtherInfo').clearValidators();
      form.isBowelSkilledTaskOtherSelected = false;
    }
  });

  //Oxygen -> Method
  
  form.Oxygen.get('Method').valueChanges.subscribe(value => {
    if (value == 'OTHER') {
      form.isOxygenMethodOther = true;
      //form.Oxygen.get('MethodOther').setValidators([Validators.required]); -- TFS 2440 Required field changes
      form.Oxygen.get('MethodOther').updateValueAndValidity();
    } else {
      form.isOxygenMethodOther = false;
      form.Oxygen.get('MethodOther').clearValidators();
      form.Oxygen.get('MethodOther').updateValueAndValidity();
    }
  });
  // update sub section based on this field
  let oxygenUseToggled = false;
  let oxySkillLevelToggle = false;

  form.Oxygen.get('OxygenUse').valueChanges.subscribe((value) => {
    if (oxySkillLevelToggle) {
      oxySkillLevelToggle = false;
      return;
    }
    oxygenUseToggled = true;
    if (value == 'INTER') {
      form.OxygenComp.sectionForm.get('SkillLevel').setValue('F');
    } else if (value == 'CONT') {
      form.OxygenComp.sectionForm.get('SkillLevel').setValue('I');
    }
  });
  // update back vice versa ;)
  
  form.OxygenComp.sectionForm.get('SkillLevel').valueChanges.subscribe((value) => {   
    if (oxygenUseToggled) {
      oxygenUseToggled = false;
      return;
    }
    oxySkillLevelToggle = true;
    if (value == 'I') {
      form.Oxygen.get('OxygenUse').setValue('CONT');
    } else if(value == 'F' ){
      form.Oxygen.get('OxygenUse').setValue('INTER');
    }

  });

  //TubeFeedings-> TypeofTubeFeeding : other field
  form.TubeFeedings.get('TypeofTubeFeeding').valueChanges.subscribe(value => {
    if (value === 'OTHER') {
      form.isTypeofTubeFeedingOtherDisabled = true;
      //form.TubeFeedings.get('TypeofTubeFeedingOther').setValidators([Validators.required]); -- TFS 2440 Required field changes
      form.TubeFeedings.get('TypeofTubeFeedingOther').updateValueAndValidity();
      //console.log('tube feeding other selected', this.TubeFeedings.get('TypeofTubeFeedingOther'));
    } else {
      form.TubeFeedings.get('TypeofTubeFeedingOther').clearValidators();
      form.isTypeofTubeFeedingOtherDisabled = false;
    }
  });

  //URINARY CATHETER/UROSTOMY -
  form.UrinaryCatheterSkilledTasks.get('TypeofCatheter').valueChanges.subscribe(value => {
    form.showTypeofCatheterDate = value == 'INDWELLING';
    form.UrinaryCatheterSkilledTasks.get('Dateoflastchange').updateValueAndValidity();
  });





}


