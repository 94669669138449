import { Component, OnInit, Inject } from '@angular/core';
import { DataService, AlertsService } from '../../shared';
import { ALOCDocumentService } from '../../shared/services/aloc-document.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-submit-document',
    templateUrl: './submit-document.component.html',
    styleUrls: ['./submit-document.component.css']
})
export class SubmitDocumentComponent implements OnInit {

    constructor(
        private alertsService: AlertsService,
        public dataService: DataService,
        private alocdocService: ALOCDocumentService,
        private dialog: MatDialog) { }

    enable_print: boolean = false;

    ngOnInit() {
        this.loadDocument();
    }

    private loadDocument() {
        //console.log('context document', this.dataService.getSelectedDocument());
        this.alocdocService.getALOC(parseInt(this.dataService.getSelectedDocument())).subscribe((data: any[]) => {
            if (data.length > 0) {
                let context_doc = data[0];
                this.enable_print = context_doc['aO_ALOC_STATUS'] == 'SUBMITTED';
            }
            this.alertsService.clearLoader();
        }, error => console.error(error));
    }

    onCertifySubmit() {
        this.alocdocService.submitAlocDocument(parseInt(this.dataService.getSelectedDocument())).subscribe(resp => {
            //console.log('submit respose', resp);
            this.alertsService.success('ALOCQ Document submitted');
            this.loadDocument();
        }, error => {
            this.alertsService.clearLoader();
            console.error('error Submitting ALOCQ Document', error);
            let msg = error.error.details.oracleMsg.split("\n");
            //console.log(msg);
            if (msg.length > 0) {
                let sec_errors: string[] = msg[0].replace(/\\n/g, "").split('**');
                let sec_errors_filtered = new Array<string>();
                sec_errors.forEach(err => {
                    if (err.startsWith(" ")) {
                        sec_errors_filtered.push(err.trim());
                    }
                });
                //console.log('validation error', sec_errors_filtered);
                this.dialog.open(ShowValidationMessages, {
                    minWidth: '300px',
                    maxWidth: '900px',
                    data: sec_errors_filtered
                });

            }

        });
    }
}


@Component({
    selector: 'show-validation-messages',
    templateUrl: 'show-validation-messages.html',
})
export class ShowValidationMessages {

    constructor(
        public dialogRef: MatDialogRef<ShowValidationMessages>,
        @Inject(MAT_DIALOG_DATA) public validations: string[]) { }

    close() {
        this.dialogRef.close();
    }


}
