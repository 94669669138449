import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SectionCComponent } from './section-c/section-c.component';
import { AuthGuard } from '../shared';
import { AdlsUnsaveGuardGuard } from './adls-unsave-guard.guard';

const routes: Routes = [
    {
        path: '',
        component: SectionCComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AdlsUnsaveGuardGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SectionCRoutingModule { }
