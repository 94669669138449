import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { MaterialModule } from '../material.module';
import { SectionARoutingModule } from './section-a-routing.module';
import { SectionAComponent } from './section-a/section-a.component';
@NgModule({
  declarations: [SectionAComponent],
  imports: [
    CommonModule,
    SectionARoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SectionAModule { }
