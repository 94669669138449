import { Component, OnInit, ViewChild, EventEmitter, OnDestroy, HostListener, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { SectionCService, SectionCModel, AlertsService, DataService, ADLSIADSL, SectionCFormModel } from '../../shared/index';
import { MatTableDataSource } from '@angular/material/table';
import { of, forkJoin, Subscription, interval } from "rxjs";
import {
    debounceTime,
    map,
    distinctUntilChanged,
    filter
} from "rxjs/operators";
import { fromEvent } from 'rxjs';
import { KeyedCollection } from '../../shared/common/Dictionary';
import { AdlRowComponent } from './adl-row/adl-row.component';

@Component({
    selector: 'app-section-c',
    templateUrl: './section-c.component.html',
    styleUrls: ['./section-c.component.css']
})
export class SectionCComponent implements OnInit, OnDestroy{

    constructor(private sectionCService: SectionCService,
        private cntxInfoService: DataService,
        private alerts: AlertsService) {
    }
    @ViewChildren(AdlRowComponent)
    allSections: QueryList<AdlRowComponent>;
    documentReferance: { alocid: string, sectionid: string, name: string };
    sectionCData: ADLSIADSL[];
    sectionChangesStack = new KeyedCollection<SectionCFormModel>();
    autoSaveSub: Subscription;
    autoSaveInProgress = false;
    autoSaveError = false;
    get unsavedChangedPresent(): boolean {
        return this.sectionChangesStack.Count() > 0;
    }

    ngOnInit() {
        this.documentReferance = { alocid: '', sectionid: '', name: '' };
        this.documentReferance.alocid = this.cntxInfoService.getSelectedDocument();
        this.documentReferance.name = this.cntxInfoService.getSelectedDocumentName();

        this.loadData().subscribe(() => {
            this.autoSave();
        });

    }

    ngOnDestroy(): void {
        this.autoSaveSub.unsubscribe();
    }

    loadData(): EventEmitter<string> {
        let load_complete = new EventEmitter<string>();
        let sectionC$ = this.sectionCService.getAdlsIadls(this.documentReferance.alocid);
        let adlText$ = this.sectionCService.getADLText();

        forkJoin(sectionC$, adlText$).subscribe(([sectionC, ADLText]) => {            
            sectionC.forEach(sec => {
                sec.adl_rows.forEach(adl => {
                    let txt = ADLText.find(t => t.adl == adl.adl_val);
                    if (txt != null) {
                        adl.adl_optns = txt;
                    }
                });
            });
            this.sectionCData = sectionC;
            //console.log(this.sectionCData);
            if (sectionC.length <= 0) {
                this.alerts.error('No section c data found !');
            } else {
                this.alerts.success(`Section C loaded successfully`);
            }
            
            load_complete.emit('load_done');
        }, error => {
            console.error(error);
            this.alerts.error('Error loading data');
        });

        return load_complete;
    }

    autoSave() {
        if (this.autoSaveSub == null) {

            this.autoSaveSub = interval(2000).subscribe(() => {
                for (let section of this.sectionChangesStack.Values()) {
                    if (section.updated) {
                        section.updated = false;
                        continue;
                    }
                    if (this.autoSaveInProgress)
                        return;
                    //rest service call
                    this.autoSaveInProgress = true;
                    this.sectionCService.saveADLIADL(section).subscribe(resp => {
                        this.alerts.success(section.adl_title.toLowerCase() + ' saved successfully !');
                        this.sectionChangesStack.Remove(section.adl_val);
                        this.autoSaveInProgress = false;
                    }, error => {
                        this.alerts.error(`Error saving data.`);
                        console.error(error);
                        this.autoSaveError = true;
                    });
                    return;
                }

            });
        }
    }

    onSectionUpdate(data: SectionCFormModel) {
        data.updated = true;
        this.sectionChangesStack.Add(data.adl_val, data);
        //console.log(data);
        if (this.autoSaveError && this.autoSaveInProgress) {
            this.autoSaveError = false;
            this.autoSaveInProgress = false;
        }
    }

    @HostListener('window:beforeunload')
    canNavigateAway() {
        return !this.unsavedChangedPresent;
    }

    @HostListener('document:keypress', ['$event'])
    onCntrlQ(e: KeyboardEvent) {
        console.log(e);
        if (e.code == 'KeyQ' && e.ctrlKey) {
            this.allSections.forEach(sec => {
                //sec.sectionForm.updateValueAndValidity();
                if (sec.sectionForm.touched) {
                    sec.sectionForm.markAsUntouched();
                } else {
                    sec.sectionForm.markAllAsTouched();
                }
                
            });
        }
    }

}

