import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService, AlertsService, SectionB2Service, SectionB2Model, SectionB1Model, toTitleCase } from '../../shared/index';
import { Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-section-b2',
  templateUrl: './section-b2.component.html',
  styleUrls: ['./section-b2.component.css']
})
export class SectionB2Component implements OnInit {
  sectionB2Form: FormGroup;
  isSubmitting = false;
  options: string[] = ['Y', 'N'];
  documentMode: string;
  documentReference: { alocid: string, sectionid: string } = { alocid: '', sectionid: '' };

  constructor(
    private fb: FormBuilder,
    public dataService: DataService,
    private alertsService: AlertsService,
    private sectioB2Service: SectionB2Service,
    private router: Router
  ) {

    this.router.events.pipe(filter(e => e instanceof RouterEvent)).subscribe(e => {
      //this.alertsService.clearLoader();
    });

    this.sectionB2Form = new FormGroup({
      aB_SECTION_ID: new FormControl(''),
      aB_ALOC_ID: new FormControl(''),
      aB_MONITORING_IND: new FormControl('', {
        validators: [Validators.required], updateOn: 'change'
      }),
      aB_MONITORING_COMMENT: new FormControl(''),
      aB_TESTING_IND: new FormControl('', {
        validators: [Validators.required], updateOn: 'change'
      }),
      aB_TESTING_COMMENT: new FormControl(''),
      aB_INTERVENTION_IND: new FormControl('', {
        validators: [Validators.required], updateOn: 'change'
      }),
      aB_INTERVENTION_COMMENT: new FormControl(''),
      aB_ONGOING_IND: new FormControl('', {
        validators: [Validators.required], updateOn: 'change'
      }),
      aB_ONGOING_COMMENT: new FormControl(''),
      aB_MONTHLY_IND: new FormControl('', {
        validators: [Validators.required], updateOn: 'change'
      }),
      aB_MONTHLY_COMMENT: new FormControl(''),
      aB_NECESSARY_IND: new FormControl('', {
        validators: [Validators.required], updateOn: 'change'
      }),
      aB_NECESSARY_COMMENT: new FormControl(''),
    });




  }

  ngOnInit() {
    //console.log(this.dataService.getSelectedDocument());
    this.loadSection();
  }

  loadSection() {
    if (this.dataService.getSelectedDocument() == null) return;
    this.documentReference.alocid = this.dataService.getSelectedDocument();

    this.sectioB2Service.getSectionB2(this.documentReference.alocid).subscribe(data => {

      data.aB_DIAG_COMMENT = data.aB_DIAG_COMMENT;//toTitleCase(data.aB_DIAG_COMMENT);
      data.aB_INTERVENTION_COMMENT = data.aB_INTERVENTION_COMMENT;//toTitleCase(data.aB_INTERVENTION_COMMENT);
      data.aB_MED_ADMIN_COMMENT = data.aB_MED_ADMIN_COMMENT;//toTitleCase(data.aB_MED_ADMIN_COMMENT);
      data.aB_MONITORING_COMMENT = data.aB_MONITORING_COMMENT;//toTitleCase(data.aB_MONITORING_COMMENT);
      data.aB_NECESSARY_COMMENT = data.aB_NECESSARY_COMMENT;//toTitleCase(data.aB_NECESSARY_COMMENT);
      data.aB_ONGOING_COMMENT = data.aB_ONGOING_COMMENT;//toTitleCase(data.aB_ONGOING_COMMENT);
      data.aB_TESTING_COMMENT = data.aB_TESTING_COMMENT;//toTitleCase(data.aB_TESTING_COMMENT);
      this.documentReference.sectionid = data.aB_SECTION_ID;

      this.sectionB2Form.patchValue(data);

      //console.log("Section b2 data ", data);
      this.alertsService.success("Section B2 Loaded");
      if (data["aB_ALOC_ID"] == null || data["aB_ALOC_ID"] == 0) {
        this.documentMode = 'add';
        this.sectionB2Form.get('aB_ALOC_ID').setValue(this.dataService.getSelectedDocument());
      } else {
        this.documentMode = 'update';
      }
    }, err => {
      this.alertsService.error(err);
    });
  }


  saveSection() {
    this.sectionB2Form.updateValueAndValidity();
    if (!this.sectionB2Form.valid) {
      //console.log('invalid controls');
      for (let cntrl in this.sectionB2Form.controls) {
        if (this.sectionB2Form.controls[cntrl].invalid) {
          //console.log(cntrl, this.sectionB2Form.controls[cntrl]);
        }
      }
      return;
    }
    let formData = this.sectionB2Form.value;
    formData.aB_SECTION_ID = this.documentReference.sectionid;
    formData.aB_ALOC_ID = this.documentReference.alocid;
    if (this.documentMode == 'add') {
      //console.log('this.documentMode ', this.documentMode);
      this.sectioB2Service.createSection(formData).subscribe(resp => {
        //console.log('section b2 created ', resp);
        this.alertsService.success('Section B2 Saved');
        this.loadSection();
        
      }, error => {
          this.alertsService.error('Error Loading Section B2');
        console.error('Error saving section b2 ', error);
      });
    } else {
      this.sectioB2Service.saveSection(formData).subscribe(resp => {
        this.alertsService.success('Section B2 Saved');
        //console.log('Section b2 saved ', resp)
        this.loadSection();
      }, error => {
          this.alertsService.error('Error Loading Section A');
        console.error('Error updating section b2', error);
      });
    }

  }

  clearSection() {
   // this.sectionB2Form.reset();
    this.onClear();
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.sectionB2Form.reset();
      }
    });

  }

}
