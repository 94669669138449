import { Injectable } from '@angular/core';
import { ApiService } from '.';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ALOCDocumentService {

  constructor(private apiService: ApiService) { }

  deleteDocument(alocID: number): Observable<any> {
    return this.apiService.delete(`/ALOCBase/${alocID}`);
  }

  deletePASRRDocument(PASRRID: number): Observable<any> {
    return this.apiService.delete(`/ALOCBase/PASRR/${PASRRID}`);
  }

  deleteJFS700Document(JFS7000ID: number): Observable<any> {
    return this.apiService.delete(`/ALOCBase/Exemption/${JFS7000ID}`);
  }

  submitAlocDocument(ALOCID: number) {
    return this.apiService.post(`/ALOCBase/submitALOC/`, { ALOCID: ALOCID});
  }

  getALOC(ALOCID: number) {
    return this.apiService.get(`/ALOCBase/${ALOCID}`)
  }

}
