import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SearchResultModel, UserService } from 'src/app/shared';
import { SearchService, SearchModel, AlertsService, DataService } from '../../shared';
import { ALOCDocumentService } from '../../shared/services/aloc-document.service';
import { timer, from } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmActionComponent } from '../confirm-action/confirm-action.component';
import { DatePipe } from '@angular/common';
import * as ALOCValidators from '../../shared/validators/AlocDateValidators';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {
    searchForm: FormGroup;
    isSubmitting = false;
    documentTypes: any = [{ value: 'ALOC', text: 'ALOCQ' }];
    dodd_document_status: any = [{ value: 'STARTED', text: 'STARTED' }, { value: 'REFERRED', text: 'NOT STARTED' }, { value: 'COMPLETE', text: 'COMPLETE' }];
    nf_document_status: any = [{ value: 'CREATED', text: 'CREATED' }, { value: 'RECEIVED', text: 'RECEIVED' }];
    document_status: { value: string, text: string }[] = [{ value: 'INPROCESS', text: 'IN PROCESS' }, { value: 'SUBMITTED', text: 'SUBMITTED' }, { value: 'COMPLETE', text: 'COMPLETE' },
    { value: 'PIMSLOADED', text: 'PIMS LOADED' }, { value: 'DETERM', text: 'DETERM' }];
    isAdmin: any;
    isActive: any;
    dataSource: any;
    minmumDate120 = new Date((new Date()).getFullYear() - 120, (new Date()).getMonth(), (new Date()).getDate());
    maximumDate120 = new Date((new Date()).getFullYear() + 120, (new Date()).getMonth(), (new Date()).getDate());

    searchModel: SearchModel = {} as SearchModel;
    searchResultModel: SearchResultModel[] = {} as SearchResultModel[];
    ssnmask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    dateMask = [/\d/, /\d/, '//', /\d/, /\d/, '//', /\d/, /\d/, /\d/, /\d/];

    constructor(private fb: FormBuilder,
        private searchService: SearchService,
        private router: Router,
        private alertsService: AlertsService,
        private dataService: DataService,
        private alocDocService: ALOCDocumentService,
        public confirmDialog: MatDialog,
        private userService: UserService) {
        ///set user
        this.searchForm = this.fb.group({
            SESSION_USER: new FormControl(this.userService.getCurrentUser().loginId),
            SESSION_ID: new FormControl(),//this.userService.getCurrentUser().jti
            BEGIN_DATE: new FormControl('', { validators: [ALOCValidators.validate120Years] }),
            END_DATE: new FormControl('', { validators: [ALOCValidators.validate120Years] }),
            DB_ID: new FormControl('', {
                validators: [Validators.pattern(/^\d{1,9}$/)]
            }),
            TYPE: new FormControl(''),
            SSN: new FormControl('', [Validators.pattern(/\d{3}-\d{2}-\d{4}/)]),
            MEDICAID_NUMBER: new FormControl('', [Validators.pattern(/^\d{1,12}$/)]),
            FIRST_NAME: new FormControl('', [Validators.pattern('^[a-zA-Z][0-9a-zA-Z .,\' -%]*$'), Validators.maxLength(60)]),
            LAST_NAME: new FormControl('', [Validators.pattern('^[a-zA-Z][0-9a-zA-Z .,\' -%]*$'), Validators.maxLength(60)]),
            CREATE_FACILITY_ID: new FormControl(''),
            CREATE_WORKER: new FormControl(''),
            RECEIVE_FACILITY_ID: new FormControl(''),
            CLIENT_NUMBER: new FormControl('', [Validators.pattern(/^\d{1,10}$/)]),
            PIMS_CLIENT_NUMBER: new FormControl('', [Validators.pattern(/^\d{1,9}$/)]),
            LEVEL_II: new FormControl(''),
            DATE_OF_BIRTH: new FormControl('', { validators: [ALOCValidators.validate120Years] }),
            NF_STATUS: new FormControl(''),
            CATEGORICAL: new FormControl(''),
            DODD_STATUS: new FormControl(''),
            DOCUMENT_STATUS: new FormControl('')

        });



    }


    displayedColumns: string[] = ['Delete', 'Edit', 'Print', 'Review', 'DocumentID', 'Categorical', 'Status', 'Name', 'SubmitDate', 'Reviewer', 'Assessor', 'SubmitterFacility', 'CreateDate', 'MedicaidNumber', 'SSN', 'DateOfBirth', 'Sex', 'PsychDischarge', 'OdmhStatus', 'DoddStatus', 'LocalStatus', 'PimsClientNumber', 'CountyCode', 'Type'];


    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    ngOnInit() {
        //this.loadDefaultSearch();
        this.onChanges();

        //console.log('logged in user', this.userService.getCurrentUser());
        if (this.dataService.getSearchFilter()) {
            this.searchForm.patchValue(this.dataService.getSearchFilter());
        }
        this.onSearch();
    }

    private loadDefaultSearch() {
        this.searchService.getSearchLoad(false).subscribe(response => {
            //console.log('search response ', response);
            this.searchResultModel = response;
            this.dataSource = new MatTableDataSource<SearchResultModel>(this.searchResultModel);
            this.dataSource.paginator = this.paginator;
            this.alertsService.success('Data Loaded');
        }, error => {
            this.alertsService.error(error);
            console.error(error);
        });
    }

    onChanges() {
        //this.searchForm.get('P_AS_SSN').valueChanges.subscribe(ssn => {
        //  console.log(ssn.length);
        //  //if (ssn.length != 10) {
        //  //  this.searchForm.get('P_AS_SSN').setErrors(errors)
        //  //}
        //});
    }

    onDelete(doc) {
        //console.log(doc);
        const confDel = this.confirmDialog.open(ConfirmActionComponent, {
            data: doc, minWidth: '300px'
        });
        confDel.afterClosed().subscribe(result => {
            //console.log(result);
            if (result == 'yes') {
                this.deleteDocument(doc);
            } else {
                //console.log('delete cancelled by user');
            }
        });

        return;


    }

    private deleteDocument(doc): void {
        //console.log(doc);
        if (doc.status === 'INPROCESS') {
            let docType = doc.dB_TYPE;
            switch (docType.trim()) {
                case 'ALOC':
                    this.alocDocService.deleteDocument(doc.dB_ID).subscribe(resp => {
                        this.alertsService.success('ALOC Document deleted successfully');
                        timer(1000).subscribe(t => {
                            this.loadDefaultSearch();
                        });
                    }, error => {
                        console.error(error);
                        this.alertsService.error('Error deleteing ALOC document');
                    });
                    break;
                case 'PASRR':
                    this.alocDocService.deletePASRRDocument(doc.dB_ID).subscribe(resp => {
                        this.alertsService.success('PASRR Document deleted');
                        timer(1000).subscribe(t => {
                            this.loadDefaultSearch();
                        });
                    }, error => {
                        console.error(error);
                        this.alertsService.error('Error deleteing PASRR document ');
                    });
                    break;
                case 'JFS7000':
                    this.alocDocService.deleteJFS700Document(doc.dB_ID).subscribe(resp => {
                        this.alertsService.success('Exemption deleted successfully');
                        timer(1000).subscribe(t => {
                            this.loadDefaultSearch();
                        });
                    }, error => {
                        console.error(error);
                        this.alertsService.error('Error Deleting Exemption ');
                    });
                    break;


                default:
                    console.error('unidentified doc type ', docType);
            }
        }
    }

    onEdit(document) {
        //  console.log(documentId);
        this.dataService.saveSelectedDocument(document);
        this.router.navigate(['section-a']);
        //console.log(this.dataService.getSelectedDocument());
    }

    onPrint(documentId) {
        //   console.log(documentId);

    }

    levelIIChange($event) {
        this.searchForm.get('LEVEL_II').setValue($event.checked ? 'Y' : '');
    }

    categoricalChange($event) {
        this.searchForm.get('CATEGORICAL').setValue($event.checked ? 'Y' : '');
    }

    onReset() {
        this.searchForm.reset();
        this.dataService.clearSearchFilter();
        this.onSearch();
    }

    onSearch() {
        try {
            this.searchForm.updateValueAndValidity();
            if (this.searchForm.invalid) {
                return;
            }
            let search_params = this.searchForm.value;
           // this.dataService.setSearchFilter(search_params);
            search_params['DATE_OF_BIRTH'] = search_params['DATE_OF_BIRTH'] != null ? (new DatePipe('en-us')).transform(search_params['DATE_OF_BIRTH'], 'MM/dd/yyyy') : null;
            search_params['SSN'] = search_params['SSN'] != null ? search_params['SSN'].replace(/-/g, '') : null;
            //console.log('search_params',search_params);

            this.dataSource = null;
            this.searchService.searchDocuments(search_params).subscribe(resp => {
                //console.log('search resp', resp);
                this.dataSource = new MatTableDataSource<SearchResultModel>(resp);
                this.dataSource.paginator = this.paginator;
                this.alertsService.clearLoader();
            }, error => {
                console.error('error in search', error);
                this.alertsService.clearLoader();
            });
        } catch (e) {
            console.error('error searching ', e);
        }
    }

    onReview(document) {
        this.dataService.saveSelectedDocument(document);
        this.router.navigate(['summary']);
    }

    getStatusLabel(status) {
        for (let st of this.document_status) {
            if (st.value == status) {
                return st.text;
            }
        }
    }
}
