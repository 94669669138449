import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SectionB1ComponentServiceService {

  public subsectionDataEmitter = new EventEmitter<any>();

  constructor() {
    
  }

  InitSubSectionData(value: any) {
    this.subsectionDataEmitter.emit(value);
  }
}
