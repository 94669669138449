import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ADLSIADSL, ADLOptions, SectionCFormModel } from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SectionCService {
  constructor(private apiService: ApiService) { }

    getAdlsIadls(ALOCID): Observable<ADLSIADSL[]> {
        return this.apiService.get(`sectionc/ADLSIADLS/${ALOCID}`);
    }

    getADLText(): Observable<ADLOptions[]> {
        return this.apiService.get(`sectionc/ADLTEXT`);
    }

    saveADLIADL(formData: SectionCFormModel): Observable<any> {
        return this.apiService.putNoLoader(`sectionc/SaveADLIADL`, formData);
    }

}

