import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { LoaderComponent } from './layout/loader/loader.component';
import { MaterialModule } from '../material.module';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { SsnMaskDirective } from './directives/ssn-mask.directive';
import { DocumentUploaderModuleModule } from './components/document-uploader-module/document-uploader-module.module';
import { OnlylettersDirective } from './directives/onlyletters.directive';
import { OnlyLettersAndNumbersDirective } from './directives/only-letters-and-numbers.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { AuthComponent } from './components/auth/auth.component';
import { OnlyDecimalDirective } from './directives/only-decimal.directive';
import { ShowAuthDirective } from './directives/show-auth.directive';
import { FilterCharsDirective } from './directives/filter-chars.directive';
//import { BlockPasteDirective } from './directives/BlockPaste.directive';


@NgModule({
    declarations: [
        LoaderComponent,
        PhoneMaskDirective,
        SsnMaskDirective,
        OnlylettersDirective,
        OnlyLettersAndNumbersDirective,
        OnlyNumbersDirective,
        AuthComponent,
        OnlyDecimalDirective,
        ShowAuthDirective,
        FilterCharsDirective
        //BlockPasteDirective       
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        MaterialModule,
        DocumentUploaderModuleModule
    ],
    exports: [
        LoaderComponent,
        PhoneMaskDirective,
        SsnMaskDirective,
        DocumentUploaderModuleModule,
        OnlylettersDirective,
        OnlyLettersAndNumbersDirective,
        OnlyNumbersDirective,
        OnlyDecimalDirective,
        ShowAuthDirective,
        AuthComponent,
        FilterCharsDirective
        //BlockPasteDirective
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true }]
})
export class SharedModule { }
