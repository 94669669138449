import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AlertsService, SectionDropdownServiceService, SectionBService, DataService, physicians, getTextForDropdownValues } from '../../shared/index';
import { PhysicianSelectorComponent } from './physician-selector/physician-selector.component';
import { FileUploaderComponent } from '../../shared/components/document-uploader-module/file-uploader/file-uploader.component';
import Swal from 'sweetalert2';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-section-b',
    templateUrl: './section-b.component.html',
    styleUrls: ['./section-b.component.css']
})
export class SectionBComponent implements OnInit, AfterViewInit {
    dropDownSelectText = getTextForDropdownValues;
    sectionBForm: FormGroup;
    isSubmitting = false;
    genders: any;
    assessmentsTypes: any;
    primaryDiagnosesPresent: boolean;
    //drop down value
    diagnosisSources: any[];
    diagnosisCodes: any[];
    medicalAdminSources: any[];

    documentMode: string;



    isSrcDiagOther: boolean;

    physicianData: physicians[];

    @ViewChild('otherDiagSource', { static: true })
    diagnosisSrcOther: ElementRef;

    //@ViewChild('physicianSelector', { static: true })
    //physicianSelector: PhysicianSelectorComponent;


    constructor(
        private alertService: AlertsService,
        private sectionbservice: SectionBService,
        private fb: FormBuilder,
        private dropdownSerice: SectionDropdownServiceService,
        public dataService: DataService) {
    }

    ngOnInit() {

        //main form
        this.sectionBForm = new FormGroup({
            AA_ALOC_ID: new FormControl(''),
            AB_SECTION_ID: new FormControl(''),
            AB_ALOC_ID: new FormControl(''),
            AB_PH_CREDENTIALID: new FormControl(null),
          AB_PH_FIRST_NAME: new FormControl('', [Validators.required, Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(60)]),
          AB_PH_MIDDLE_NAME: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(1)]),
          AB_PH_LAST_NAME: new FormControl('', [Validators.pattern("[A-Za-z0-9\. \'-]+"), Validators.maxLength(60), Validators.required]),
            //AB_PH_PHONE: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]\d{2}-\d{3}-\d{4}$/)]),
            //AB_PH_PHONE2: new FormControl('', [Validators.pattern(/^[0-9]\d{2}-\d{3}-\d{4}$/)]),
          AB_PH_PHONE: new FormControl('', [Validators.required, Validators.minLength(12), Validators.pattern("[0-9]{3}-[0-9]{3}-[0-9]{4}")]),
            AB_PH_PHONE2: new FormControl(''),
            AB_PH_FAX: new FormControl(''),
          AB_PH_EMAIL: new FormControl('', [Validators.maxLength(60), Validators.pattern(/^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/)]),
            AB_DIAG_IND: new FormControl('N', Validators.required),
            AB_DIAG_SOURCE_FAMILY: new FormControl(''),
            AB_DIAG_SOURCE_MEDREC: new FormControl(''),
            AB_DIAG_SOURCE_CAREGIVER: new FormControl(''),
            AB_DIAG_SOURCE_INDIVIDUAL: new FormControl(''),
            AB_DIAG_SOURCE_PHYSICIAN: new FormControl(''),
            AB_DIAG_SOURCE_OTHER: new FormControl(''),
            AB_DIAG_SOURCE_OTHER_DESC: new FormControl(''),
            AB_DIAG_PRIMARY: new FormControl(''),
            AB_DIAG_COMMENT: new FormControl(''),
            AB_MED_ADMIN: new FormControl('', Validators.required),
            AB_MED_ADMIN_COMMENT: new FormControl(''),
            AB_DIAG_SOURCE: new FormControl('')
        });
        //load diagnosis src
        let diasrc$ = this.dropdownSerice.GetDropDownValues('DIAG_SOURCE');
        //load medical admin src drop down
        let med_adm$ = this.dropdownSerice.GetDropDownValues('MED_ADM');
        //load diagnosis codes
        let dia_code$ = this.sectionbservice.getDiagnosisCodes();

        forkJoin(diasrc$, med_adm$, dia_code$).subscribe(([diasrc, medadm, diacode]) => {
            this.diagnosisSources = diasrc;
            this.medicalAdminSources = medadm;
          this.diagnosisCodes = diacode;
         // this.diagnosisCodes.sort((a, b) => a.dC_DESCRIPTION.localeCompare(b.dC_DESCRIPTION));
          this.diagnosisCodes = this.diagnosisCodes.sort((a, b) => a.dC_DESCRIPTION.localeCompare(b.dC_DESCRIPTION));
          
        }, error => {
            this.alertService.error('Error loading dropdowns');
            console.log(error);
        });


        //load physicians data
        //this.sectionbservice.getPhysicians().subscribe(data => {
        //    this.physicianSelector.loadPhysicians(data);
        //    this.physicianData = data;
        //    this.sectionBForm.get('AB_PH_CREDENTIALID').clearValidators();
        //    this.sectionBForm.get('AB_PH_CREDENTIALID').setValidators([Validators.required, this.validatePhysicianField(this.physicianData)]);
        //    //console.log(this.sectionBForm.get('AB_PH_CREDENTIALID'));
        //    this.loadSelectedAlocID();
        //}, error => {
        //    console.error('error loading physicians ', error);
        //    this.alertService.error('Error loading physicians names');
        //});

      this.loadSelectedAlocID();
        //dynamic validation
        this.configDynValidations();

    }

    private configDynValidations() {
        //validations diagnoses present
        this.sectionBForm.get('AB_DIAG_IND').valueChanges.subscribe(value => {
            this.primaryDiagnosesPresent = value === 'Y';
            if (this.primaryDiagnosesPresent) {
                this.sectionBForm.get('AB_DIAG_PRIMARY').setValidators([Validators.required]);
                //this.sectionBForm.get('AB_DIAG_SOURCE').setValidators([Validators.required]);
                this.sectionBForm.get('AB_DIAG_PRIMARY').updateValueAndValidity();
                this.sectionBForm.get('AB_DIAG_SOURCE').updateValueAndValidity();
            } else {
                this.sectionBForm.get('AB_DIAG_PRIMARY').clearValidators();
                this.sectionBForm.get('AB_DIAG_PRIMARY').setValue('');
                this.sectionBForm.get('AB_DIAG_PRIMARY').updateValueAndValidity();
                this.sectionBForm.get('AB_DIAG_SOURCE').clearValidators();
                this.sectionBForm.get('AB_DIAG_SOURCE').setValue([]);
                this.sectionBForm.get('AB_DIAG_SOURCE').updateValueAndValidity();
            }
        });

        //validations diagnosis src dyn validation
        this.sectionBForm.get('AB_DIAG_SOURCE').valueChanges.subscribe((value: string[]) => {
            if (value == null) return;
            if (value.indexOf('OTHER') > -1 && this.primaryDiagnosesPresent) {
                this.isSrcDiagOther = true;
                this.sectionBForm.get('AB_DIAG_SOURCE_OTHER_DESC').setValidators([Validators.maxLength(200)]);
                this.sectionBForm.get('AB_DIAG_SOURCE_OTHER_DESC').enable();
                this.sectionBForm.get('AB_DIAG_SOURCE_OTHER_DESC').updateValueAndValidity();
                //this.diagnosisSrcOther.nativeElement.focus();
            } else {
                this.isSrcDiagOther = false;
                this.sectionBForm.get('AB_DIAG_SOURCE_OTHER_DESC').clearValidators();
                this.sectionBForm.get('AB_DIAG_SOURCE_OTHER_DESC').disable();
                this.sectionBForm.get('AB_DIAG_SOURCE_OTHER_DESC').updateValueAndValidity();
            }
        });
    }

    loadSelectedAlocID() {
        let alocid = this.dataService.getSelectedDocument();
        this.sectionbservice.getSectionB(alocid).subscribe(data => {
            if (data.aB_ALOC_ID == null || data.aB_ALOC_ID == 0) {
                this.documentMode = 'add';
            } else {
                this.documentMode = 'update';
            }
            //console.log('section b data ', data);
            this.sectionBForm.patchValue({
                AA_ALOC_ID: data.aA_ALOC_ID,
                AB_SECTION_ID: data.aB_SECTION_ID,
              AB_PH_CREDENTIALID: null,
              AB_PH_FIRST_NAME: data.aB_PH_FIRST_NAME,
              AB_PH_MIDDLE_NAME: data.aB_PH_MIDDLE_NAME,
              AB_PH_LAST_NAME: data.aB_PH_LAST_NAME,
                AB_PH_PHONE: data.aB_PH_PHONE,
                AB_PH_PHONE2: data.aB_PH_PHONE2,
                AB_PH_FAX: data.aB_PH_FAX,
                AB_PH_EMAIL: data.aB_PH_EMAIL,
                AB_DIAG_IND: data.aB_DIAG_IND,
                AB_DIAG_SOURCE_FAMILY: data.aB_DIAG_SOURCE_FAMILY,
                AB_DIAG_SOURCE_MEDREC: data.aB_DIAG_SOURCE_MEDREC,
                AB_DIAG_SOURCE_CAREGIVER: data.aB_DIAG_SOURCE_CAREGIVER,
                AB_DIAG_SOURCE_INDIVIDUAL: data.aB_DIAG_SOURCE_INDIVIDUAL,
                AB_DIAG_SOURCE_PHYSICIAN: data.aB_DIAG_SOURCE_PHYSICIAN,
                AB_DIAG_SOURCE_OTHER: data.aB_DIAG_SOURCE_OTHER,
                AB_DIAG_PRIMARY: data.aB_DIAG_PRIMARY,
                AB_DIAG_COMMENT: data.aB_DIAG_COMMENT,
                AB_MED_ADMIN: data.aB_MED_ADMIN,
                AB_MED_ADMIN_COMMENT: data.aB_MED_ADMIN_COMMENT,
                AB_DIAG_SOURCE: this.getDiagnosisSource(data),
                AB_DIAG_SOURCE_OTHER_DESC: data.aB_DIAG_SOURCE_OTHER_DESC
            });
            // set correct aloc id !
            if (data.aA_ALOC_ID != null || data.aA_ALOC_ID != 0) {
                this.sectionBForm.get('AB_ALOC_ID').setValue(data.aA_ALOC_ID);
            } else {
                this.sectionBForm.get('AB_ALOC_ID').setValue(alocid);
            }
            this.alertService.success('Section B Loaded');
        }, error => {
            //console.log(`error in loading sectionb aloc id: ${alocid}`, error);
            this.alertService.error('Error Loading Section B');
        });
    }

    getDiagnosisSource(data) {
        let selected_value = [];
        if (data.aB_DIAG_SOURCE_FAMILY == 'Y') {
            selected_value.push('FAMILY');
        }
        if (data.aB_DIAG_SOURCE_MEDREC == 'Y') {
            selected_value.push('MED_RECORD');
        }
        if (data.aB_DIAG_SOURCE_CAREGIVER == 'Y') {
            selected_value.push('CAREGIVER');
        }
        if (data.aB_DIAG_SOURCE_INDIVIDUAL == 'Y') {
            selected_value.push('INDIVIDUAL');
        }
        if (data.aB_DIAG_SOURCE_PHYSICIAN == 'Y') {
            selected_value.push('PHYSICIAN');
        }
        if (data.aB_DIAG_SOURCE_OTHER == 'Y') {
            selected_value.push('OTHER');
        }
        return selected_value;
    }

    ngAfterViewInit(): void {


    }

    submitForm() {
        this.sectionBForm.markAllAsTouched();
        //console.log('selected physician ', this.physicianSelector.selectedPhysician);
        this.sectionBForm.updateValueAndValidity();
       // this.sectionBForm.get('AB_PH_CREDENTIALID').updateValueAndValidity();
        //console.log('form ', this.sectionBForm);
        if (!this.sectionBForm.valid) {
            //this.alertService.error("please check the inputs");
            return;
        }

        //console.log(JSON.stringify(this.getSelectedFormValue()));
        let data = this.getSelectedFormValue();
        let selectedDiagPrimary = this.diagnosisCodes.find(p => p.dC_DESCRIPTION == data['AB_DIAG_PRIMARY']);
        if (selectedDiagPrimary != null) {
            data['AB_DIAG_PRIMARY'] = this.diagnosisCodes.find(p => p.dC_DESCRIPTION == data['AB_DIAG_PRIMARY']).dC_CODE;
        }
        //diag source update
        data['aB_DIAG_SOURCE_FAMILY'] = 'N';
        data['aB_DIAG_SOURCE_MEDREC'] = 'N';
        data['aB_DIAG_SOURCE_CAREGIVER'] = 'N';
        data['aB_DIAG_SOURCE_INDIVIDUAL'] = 'N';
        data['aB_DIAG_SOURCE_PHYSICIAN'] = 'N';
        data['ab_DIAG_SOURCE_OTHER'] = 'N';
        let diag_src: [] = data['AB_DIAG_SOURCE'];
        if (Array.isArray(diag_src)) {
            diag_src.forEach(value => {
                switch (value) {
                    case 'FAMILY':
                        data['aB_DIAG_SOURCE_FAMILY'] = 'Y';
                        break;
                    case 'MED_RECORD':
                        data['aB_DIAG_SOURCE_MEDREC'] = 'Y';
                        break;
                    case 'CAREGIVER':
                        data['aB_DIAG_SOURCE_CAREGIVER'] = 'Y';
                        break;
                    case 'INDIVIDUAL':
                        data['aB_DIAG_SOURCE_INDIVIDUAL'] = 'Y';
                        break;
                    case 'PHYSICIAN':
                        data['aB_DIAG_SOURCE_PHYSICIAN'] = 'Y';
                        break;
                    case 'OTHER':
                        data['ab_DIAG_SOURCE_OTHER'] = 'Y';
                        break;
                    default:
                        break;
                }
            });
        }
        //console.log('section b form data', data);
        if (this.documentMode == 'add') {
            this.sectionbservice.createSectionb(data).subscribe(resp => {
                //  console.log('section b create response ', resp);
                this.alertService.success("Section B Saved");
                this.loadSelectedAlocID();
            }, error => {
                //  console.error('error updating section b', error);
                this.alertService.error("Error Saving Section B");
            }
            );
        } else {
            this.sectionbservice.updateSectionb(data).subscribe(resp => {
                //console.log('section b update response ', resp);
                this.alertService.success("Section B Saved");
                this.loadSelectedAlocID();
            }, error => {
                console.error('error updating section b', error);
                this.alertService.error("Error Saving Section B");
            }
            );
        }


    }

    getSelectedFormValue() {
        let formData = this.sectionBForm.value;
      //  formData.AB_PH_CREDENTIALID = this.physicianSelector.selectedPhysician.lic;
        return formData;
    }


    resetForm() {

        //let aa_aloc_id = this.sectionBForm.get('AA_ALOC_ID').value;
        //let section_id = this.sectionBForm.get('AB_SECTION_ID').value;

        // this.sectionBForm.reset();
        this.onClear();
        //this.physicianSelector.val = '';

        //this.sectionBForm.get('AA_ALOC_ID').setValue(aa_aloc_id);
        //this.sectionBForm.get('AB_ALOC_ID').setValue(aa_aloc_id);

        //this.sectionBForm.get('AB_SECTION_ID').setValue(section_id);

        //this.sectionBForm.markAllAsTouched();


    }

    //onphysicianDataLoaded(data: Physician[]) {
    //  //console.log('data received in the parent component ', data);
    //  this.physicianData = data;
    //  this.sectionBForm.get('AB_PH_CREDENTIALID').setValidators([Validators.required, this.validatePhysicianField(this.physicianData)]);

    //  //load selected aloc id
    //  //this.loadSelectedAlocID(400119959);
    //  this.loadSelectedAlocID(400312712);


    //}

    //getPhysicianName(license: string) {
    //    for (let i = 0; i < this.physicianData.length; i++) {
    //        if (this.physicianData[i].lic == license) {
    //            this.physicianSelector.selectedPhysician = this.physicianData[i];
    //            return `${this.physicianData[i].lname} ,${this.physicianData[i].fname}`.toUpperCase();
    //        }
    //    }
    //    return license;
    //}

    //validatePhysicianSelected() {
    //    this.sectionBForm.get('AB_PH_CREDENTIALID').updateValueAndValidity();
    //}

    validatePhysicianField(physicians: physicians[]): ValidatorFn {
        //console.log('py validator called', physicians);
        return (cntrl: AbstractControl): null | { [key: string]: any } => {

            if (cntrl.value == null || cntrl.value == '') {
                return {
                    'empty': { msg: 'physician field cannot be empty' }
                }
            }
            let flname = cntrl.value.split(' ,');
            let phy = physicians.find(p => {
                if (p == null) return false;
                return p.fname == flname[1] && p.lname == flname[0];

            });
            if (phy != null) {
                return null;
            }
            //for (let i = 0; i < physicians.length; i++) {
            //    let fullname = `${physicians[i].fname} ,${physicians[i].lname}`.toUpperCase();
            //    if (fullname == cntrl.value.toUpperCase()) {
            //        return null;
            //    }
            //}

            return {
                'wrongname': { msg: 'wrong name for physician' }
            };
        }
    }
    onClear() {
        Swal.fire({
            title: 'Are you sure?',
            text: "Entered information will be erased. Press 'Ok' to proceed.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.value) {
                let aa_aloc_id = this.sectionBForm.get('AA_ALOC_ID').value;
                let section_id = this.sectionBForm.get('AB_SECTION_ID').value;

                this.sectionBForm.reset();

                //this.physicianSelector.val = '';

                this.sectionBForm.get('AA_ALOC_ID').setValue(aa_aloc_id);
                this.sectionBForm.get('AB_ALOC_ID').setValue(aa_aloc_id);

                this.sectionBForm.get('AB_SECTION_ID').setValue(section_id);

                this.sectionBForm.markAllAsTouched();
            }
        });

    }


}
