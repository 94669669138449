import { Component, OnInit } from '@angular/core';
import { SectionEService, AlertsService, DataService, SectionDropdownServiceService } from '../../shared/index';
import { FormGroup, FormControl, FormBuilder, Validators, FormControlName } from '@angular/forms';
import { FileUploaderComponent } from '../../shared/components/document-uploader-module/file-uploader/file-uploader.component';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-section-e',
    templateUrl: './section-e.component.html',
    styleUrls: ['./section-e.component.css']
})
export class SectionEComponent implements OnInit {

    SupportingDocOptions: any[];
    documentMode: string;
  show_Questions = false;  
    SectionForms: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private sectionEService: SectionEService,
        private alertsService: AlertsService,
        public dataService: DataService,
        private dropdownSerice: SectionDropdownServiceService
    ) { }

    ngOnInit() {

        //init form
        this.initForm();

        //load doc type
        this.dropdownSerice.GetDropDownValues('SUPPORT_DOC').subscribe((data: any[]) => {
            //console.log('supporting doc ', data);
            this.SupportingDocOptions = data;
            data.forEach((val, indx) => {
                this.SectionForms.addControl(`doc${val.dV_VALUE}`, new FormControl(''));
            });

            //conf cntrl change
            this.SectionForms.get('aE_DIAGNOSES_SUPPORTED').valueChanges.subscribe(value => {
                this.SupportingDocOptions.forEach((row) => {
                    if (value == 'Y') {
                        this.SectionForms.get(`doc${row.dV_VALUE}`).enable();
                    } else {
                        this.SectionForms.get(`doc${row.dV_VALUE}`).setValue(false);
                        this.SectionForms.get(`doc${row.dV_VALUE}`).disable();
                    }
                });
            });
          this.SectionForms.get('aE_DD_DIAGNOSIS').valueChanges.subscribe(value => {
            
            if (this.SectionForms.get('aE_ID_DIAGNOSIS').value == 'Y' || this.SectionForms.get('aE_DD_DIAGNOSIS').value == 'Y') {
              this.show_Questions = false;
              this.SectionForms.get('aE_ADL_ASSISTANCE_IND').setValidators([Validators.required]);
              this.SectionForms.get('aE_ADL_ASSISTANCE_IND').updateValueAndValidity();

              this.SectionForms.get('aE_DIAGNOSES_SUPPORTED').setValidators([Validators.required]);
              this.SectionForms.get('aE_DIAGNOSES_SUPPORTED').updateValueAndValidity();
              
            } else {
              this.show_Questions = true;
              this.SectionForms.get('aE_ADL_ASSISTANCE_IND').clearValidators();
              this.SectionForms.get('aE_ADL_ASSISTANCE_IND').setValue(null);
              this.SectionForms.get('aE_ADL_ASSISTANCE_IND').updateValueAndValidity();

              this.SectionForms.get('aE_DIAGNOSES_SUPPORTED').clearValidators();
              this.SectionForms.get('aE_DIAGNOSES_SUPPORTED').setValue(null);
              this.SectionForms.get('aE_DIAGNOSES_SUPPORTED').updateValueAndValidity();

              this.SectionForms.get('aE_DIAGNOSES_THERAPY').setValue(null);
              this.SectionForms.get('aE_DIAGNOSES_THERAPY').updateValueAndValidity();

              this.SectionForms.get('aE_DIAGNOSES_PSYCH_EVAL').setValue(null);
              this.SectionForms.get('aE_DIAGNOSES_PSYCH_EVAL').updateValueAndValidity();

              this.SectionForms.get('aE_DIAGNOSES_OTHER_DA').setValue(null);
              this.SectionForms.get('aE_DIAGNOSES_OTHER_DA').updateValueAndValidity();
             
            }
          });
          this.SectionForms.get('aE_ID_DIAGNOSIS').valueChanges.subscribe(value => {
            
            if (this.SectionForms.get('aE_ID_DIAGNOSIS').value == 'Y' || this.SectionForms.get('aE_DD_DIAGNOSIS').value == 'Y') {
              this.show_Questions = false;
              this.SectionForms.get('aE_ADL_ASSISTANCE_IND').setValidators([Validators.required]);
              this.SectionForms.get('aE_ADL_ASSISTANCE_IND').updateValueAndValidity();

              this.SectionForms.get('aE_DIAGNOSES_SUPPORTED').setValidators([Validators.required]);
              this.SectionForms.get('aE_DIAGNOSES_SUPPORTED').updateValueAndValidity();

            } else {
              this.show_Questions = true;
              this.SectionForms.get('aE_ADL_ASSISTANCE_IND').clearValidators();
              this.SectionForms.get('aE_ADL_ASSISTANCE_IND').setValue(null);
              this.SectionForms.get('aE_ADL_ASSISTANCE_IND').updateValueAndValidity();

              this.SectionForms.get('aE_DIAGNOSES_SUPPORTED').clearValidators();
              this.SectionForms.get('aE_DIAGNOSES_SUPPORTED').setValue(null);
              this.SectionForms.get('aE_DIAGNOSES_SUPPORTED').updateValueAndValidity();

              this.SectionForms.get('aE_DIAGNOSES_THERAPY').setValue(null);
              this.SectionForms.get('aE_DIAGNOSES_THERAPY').updateValueAndValidity();

              this.SectionForms.get('aE_DIAGNOSES_PSYCH_EVAL').setValue(null);
              this.SectionForms.get('aE_DIAGNOSES_PSYCH_EVAL').updateValueAndValidity();

              this.SectionForms.get('aE_DIAGNOSES_OTHER_DA').setValue(null);
              this.SectionForms.get('aE_DIAGNOSES_OTHER_DA').updateValueAndValidity();

            }
          });


            //load section E
            this.loadSection();

        }, error => {
            this.alertsService.error('Error loading Section E supporting docs');
            console.error('Error loading Section E supporting docs.', error);
        });

    
    }

    initForm() {
        this.SectionForms = this.formBuilder.group({
            aA_ALOC_ID: [''],
            aE_ADL_ASSISTANCE_IND: [''],
            aE_ALOC_ID: ['', Validators.required],
            aE_DD_DIAGNOSIS: ['', Validators.required],
            aE_DIAGNOSES_INDEFINITE: [''],
            aE_DIAGNOSES_OTHER_DA: [''],
            aE_DIAGNOSES_PRIOR_22: [''],
            aE_DIAGNOSES_PSYCH_EVAL: [''],
            aE_DIAGNOSES_SUPPORTED: [''],
            aE_DIAGNOSES_THERAPY: [''],
            aE_ID_DIAGNOSIS: ['', Validators.required],
            aE_SECTION_ID: ['']
        });
    }

    loadSection() {
        //load section e record
      this.sectionEService.getSectionE(this.dataService.getSelectedDocument()).subscribe(data => {
        if (data.aE_DD_DIAGNOSIS == 'Y' || data.aE_ID_DIAGNOSIS == 'Y') {
          this.show_Questions = false;
        } else {
          this.show_Questions = true;
        }
            this.SectionForms.patchValue(data);         
            this.SectionForms.get('docTHERAPY').setValue(data.aE_DIAGNOSES_THERAPY == 'Y');
            this.SectionForms.get('docPSYCHOLOGY').setValue(data.aE_DIAGNOSES_PSYCH_EVAL == 'Y');
            this.SectionForms.get('docOTHER').setValue(data.aE_DIAGNOSES_OTHER_DA == 'Y');
          this.alertsService.success('Section E Loaded');
            //document mode
            if (data.aE_ALOC_ID == null || (data.aE_ALOC_ID == 0)) {
                this.documentMode = 'add';
                this.SectionForms.get('aE_ALOC_ID').setValue(data.aA_ALOC_ID);
            } else {
                this.documentMode = 'update';
            }
        }, error => {
            console.error('Error loading section E', error);
            this.alertsService.error('Error Loading Section E');
        });
    }

    onSave() {
        this.SectionForms.updateValueAndValidity();
        if (!this.SectionForms.valid) {
            for (let cntrl in this.SectionForms.controls) {
                if (this.SectionForms.controls[cntrl].invalid) {
                    //this.alertsService.error('Invalid field');
                    console.log('invalid field ', cntrl, this.SectionForms.controls[cntrl]);
                }
            }
            return;
        }
        //console.log(JSON.stringify(this.SectionForms.value));
        let formData = this.SectionForms.value;
        this.SupportingDocOptions.forEach((row) => {
            switch (row.dV_VALUE) {
                case 'THERAPY':
                    formData['aE_DIAGNOSES_THERAPY'] = this.SectionForms.get(`doc${row.dV_VALUE}`).value ? 'Y' : 'N';
                    break;
                case 'PSYCHOLOGY':
                    formData['aE_DIAGNOSES_PSYCH_EVAL'] = this.SectionForms.get(`doc${row.dV_VALUE}`).value ? 'Y' : 'N';
                    break;
                case 'OTHER':
                    formData['aE_DIAGNOSES_OTHER_DA'] = this.SectionForms.get(`doc${row.dV_VALUE}`).value ? 'Y' : 'N';
                    break;
                default:
                    break;
            }

        });
        //console.log(formData);
        if (this.documentMode == 'add') {
            this.sectionEService.createSection(formData).subscribe(data => {
                //console.log('section e created ', data);
              this.alertsService.success('Section E Saved');
                //load section E
                this.loadSection();
            }, error => {
                this.alertsService.error('Error Saving Section E');
                    console.error('error saving section d', error);
            });
        } else {
            this.sectionEService.updateSectionE(formData).subscribe(data => {
                //console.log('Setion E updated successfully.', data);
              this.alertsService.success('Section E Saved');
                //load section E
                this.loadSection();
            }, error => {
                this.alertsService.error('Error Saving Section E');
                console.error('error saving section d', error);
            });
        }

    }

  Reset() {
    this.onClear();
        //this.SectionForms.reset();
        //this.SectionForms.get('aE_ALOC_ID').setValue(this.dataService.getSelectedDocument());
        //this.SectionForms.get('aA_ALOC_ID').setValue(this.dataService.getSelectedDocument());
    }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.SectionForms.reset();
        this.SectionForms.get('aE_ALOC_ID').setValue(this.dataService.getSelectedDocument());
        this.SectionForms.get('aA_ALOC_ID').setValue(this.dataService.getSelectedDocument());
      }
    });

  }
}
