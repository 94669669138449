import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormControlName } from '@angular/forms';
import { isNumber } from 'util';
import { SectionDService, AlertsService, DataService, User, UserService } from '../../shared/index';
import { FileUploaderComponent } from '../../shared/components/document-uploader-module/file-uploader/file-uploader.component';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-section-d',
    templateUrl: './section-d.component.html',
    styleUrls: ['./section-d.component.css']
})
export class SectionDComponent implements OnInit {
  currentUser: User = {} as User;
  sectionForm: FormGroup;
  show_AccessMsg = false;
    AD_TOTAL_SCORE = 0;
    documentMode: string;
    documentReference: { alocid: string, sectionid: string } = { alocid: '', sectionid: '' };

  constructor(
       private userService: UserService,
        private formBuilder: FormBuilder,
        private alertsService: AlertsService,
        public secDService: SectionDService, public dataService: DataService) {
    }

    ngOnInit() {
        this.alertsService.clearLoader();
      Object.assign(this.currentUser, this.userService.getCurrentUser());

      if (this.currentUser.userAgencyType == 'PAA') {
        this.show_AccessMsg = false;
      }
      else if (this.currentUser.userAgencyType == 'MCP') {
        this.show_AccessMsg = false;
      }
      else if (this.currentUser.userAgencyType == 'AGENCY' && this.currentUser.userAgencyName == 'ODA') {
        this.show_AccessMsg = false;
      } else {
        this.show_AccessMsg = true;
      }


        this.sectionForm = this.formBuilder.group({
            aD_ALOC_ID: [''],
            aD_SECTION_ID: [''],
            aD_ORIENTATION: [''
                , [
                    Validators.required,
                    Validators.max(6),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_IMMEDIATE_VERBAL_RECALL: [''
                , [
                    Validators.required,
                    Validators.max(4),
                    Validators.min(0),
                    Validators.maxLength(1)]
            ],
            aD_VISUAL_RECOGNITION_NAMING: [''
                , [
                    Validators.required,
                    Validators.max(3),
                    Validators.min(0),
                    Validators.maxLength(1)]
            ],
            aD_ATTN_LETTER_LIST: [''
                , [
                    Validators.required,
                    Validators.max(1),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_ATTN_MC_COUNT_BACKWARD: [''
                , [
                    Validators.required,
                    Validators.max(1),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_ATTN_DAYS_OF_WEEK_BACKWARD: [''
                , [
                    Validators.required,
                    Validators.max(1),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_ATTN_DIGITS_FORWARD: [''
                , [
                    Validators.required,
                    Validators.max(2),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_ATTN_DIGITS_BACKWARD: [''
                , [
                    Validators.required,
                    Validators.max(2),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_ABSTRACTION: [''
                , [
                    Validators.required,
                    Validators.max(3),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_LANG_REPEAT: [''
                , [
                    Validators.required,
                    Validators.max(1),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_LANG_FLUENCY: [''
                , [
                    Validators.required,
                    Validators.max(2),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_EXEC_COGNITIVE_SHIFTING: [''
                , [
                    Validators.required,
                    Validators.max(2),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_EXEC_ARITHMETIC_REASONING: [''
                , [
                    Validators.required,
                    Validators.max(1),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_EXEC_JUDGEMENT: [''
                , [
                    Validators.required,
                    Validators.max(1),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_VISUOSPATIAL_DESIGN: [''
                , [
                    Validators.required,
                    Validators.max(2),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_VISUOSPATIAL_CLOCK: [''
                , [
                    Validators.required,
                    Validators.max(2),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_DELAYED_VERBAL_RECALL: [''
                , [
                    Validators.required,
                    Validators.max(4),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_IMMEDIATE_STORY_RECALL: [''
                , [
                    Validators.required,
                    Validators.max(2),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_DELAYED_VISUAL_MEMORY: [''
                , [
                    Validators.required,
                    Validators.max(3),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_DELAYED_STORY_RECALL: [''
                , [
                    Validators.required,
                    Validators.max(2),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_STORY_RECOGNITION: [''
                , [
                    Validators.required,
                    Validators.max(5),
                    Validators.min(0),
                    Validators.maxLength(1)
                ]
            ],
            aD_TOTAL_SCORE: ['', [Validators.max(50)]],
            aA_ALOC_ID: [''],
            aA_LAST_NAME: [''],
            aA_FIRST_NAME: ['']
        });


        //this.sectionForm = this.formBuilder.group({
        //  aD_ALOC_ID: [''],
        //  aD_SECTION_ID: [''],
        //  aD_TOTAL_SCORE: [''],
        //  aA_ALOC_ID: [''],
        //  aA_LAST_NAME: [''],
        //  aA_FIRST_NAME: ['']
        //});


        this.sectionForm.valueChanges.subscribe(frmValues => {
            let total = 0;
            Object.keys(frmValues).forEach(fld => {
                if (['aA_ALOC_ID', 'aD_TOTAL_SCORE', 'aD_ALOC_ID', 'aD_SECTION_ID'].indexOf(fld) == -1) {
                    let fld_val = parseInt(frmValues[fld]);
                    if (isNumber(fld_val) && !isNaN(fld_val)) {
                        total += parseInt(frmValues[fld]);
                    }
                }
            });
            this.AD_TOTAL_SCORE = total;
            //console.log(`AD_TOTAL_SCORE = ${this.AD_TOTAL_SCORE}`);

        });

        // load initial data
        this.loadData();


    }

    loadData() {
        //console.log(' aloc id ', this.dataService.getSelectedDocument());
        if (this.dataService.getSelectedDocument() == null) {
            console.error('context document id is null');
            return;
        }
        this.documentReference.alocid = this.dataService.getSelectedDocument();
        this.secDService.getSectionD(this.dataService.getSelectedDocument()).subscribe(data => {
            //console.log('section d ', data);
            this.documentReference.sectionid = data.aD_SECTION_ID;
            if (data != null) {
                if (data.aD_ALOC_ID == null) {
                    this.documentMode = 'add';
                    data.aD_ALOC_ID = data.aA_ALOC_ID;
                } else {
                    this.documentMode = 'update';
                }
            }
            this.sectionForm.patchValue(data);
            //console.log("section d", data);
            this.alertsService.success("Section D Loaded");
        }, error => {
            this.alertsService.error("Error Loading Section D");
        });
    }

    onSave() {
        this.sectionForm.updateValueAndValidity();
        if (!this.sectionForm.valid) {
            for (let cntrl in this.sectionForm.controls) {
                if (this.sectionForm.controls[cntrl].invalid) {
                    //console.log('invalid field ', cntrl, this.sectionForm.controls[cntrl]);
                }
            }
            return;
        }
        else {

            let formData = Object.assign({}, this.sectionForm.value);
            //formData['aD_TOTAL_SCORE'] = this.AD_TOTAL_SCORE;
            //console.log('form data', formData);
            formData.aD_ALOC_ID = this.documentReference.alocid;
            formData.aD_SECTION_ID = this.documentReference.sectionid;
            if (this.documentMode == 'add') {

                this.secDService.createSection(formData).subscribe(resp => {
                    //console.log('create resp', resp);
                    this.alertsService.success('Section D Saved successfully');
                    this.loadData();
                }, error => {
                    console.error(error);
                    this.alertsService.error('Error Saving Section D');
                });

            } else {
                this.secDService.saveSection(formData).subscribe(resp => {
                    //console.log(resp);
                    this.alertsService.success('Section D Saved successfully');
                    this.loadData();
                }, error => {
                    console.error(error);
                    this.alertsService.error('Error Saving Section D');
                });
            }


        }

    }

    ClearForm() {
      //this.sectionForm.reset();
      this.onClear();
  }
  onClear() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Entered information will be erased. Press 'Ok' to proceed.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        this.sectionForm.reset();
      }
    });

  }

}
