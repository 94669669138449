import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import * as $ from 'jquery';
import { UserService, LoaderService, DataService, AuthService } from '../app/shared/services';
import { timer } from 'rxjs/observable/timer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private userService: UserService,
    private router: Router,
    private loaderService: LoaderService,
    private dataService: DataService,
    private authService: AuthService) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationCancel && event.url == '/') {
          //this.userService.purgeAuth();
          //this.dataService.clearToken();
        this.loaderService.hideLoader();
      }
    });
    const source = timer(1000, 60000);
    const subscribe = source.subscribe(val => {     
        if (sessionStorage.getItem('ALOCQ')) {     
        this.authService.showAuthRefresh();
      } 
    });
  
  }
  //TFS 2456 All Section - Move ALOCQ ID and Consumer Name outside
  title = 'ALOCQ';
  showMatStepper: boolean = false;
  showConsumerInfo: boolean = true;
    ngOnInit() {
    
    this.userService.populate();   
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if ((event.url == '/section-a' ||
          event.url == '/section-b' ||
          event.url == '/section-b1' ||
          event.url == '/section-b2' ||
          event.url == '/section-c' ||
          event.url == '/section-d' ||
          event.url == '/section-e' ||
          event.url == '/certify-submit' ||
          event.url == '/summary')) {
          if (this.dataService.getSelectedDocument()) {
            this.showMatStepper = true;
            this.showConsumerInfo = true;
          } else {
            this.showMatStepper = false;
            this.showConsumerInfo = false;
          }
        } else {
          this.showMatStepper = false;
          this.showConsumerInfo = false;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.router.events
      .subscribe((event) => {

        if (event instanceof NavigationStart) {
          this.loaderService.showLoader();
        } else {
          this.loaderService.hideLoader();
        }

      });
  }
}
