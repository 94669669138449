import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { SectionB1Component } from './section-b1.component';

@Injectable({
  providedIn: 'root'
})
export class SecB1UnsaveGuard implements CanDeactivate<SectionB1Component> {
    canDeactivate(component: SectionB1Component, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!component.canNavigateAway()) {
            return confirm('Unsaved changes present ! \n Do you want to navigate away ?');
        } else {
            return true;
        }        
    }
}
