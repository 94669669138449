import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SectionB1RoutingModule } from './section-b1-routing.module';
import { SectionB1Component } from './section-b1/section-b1.component';
import { SubSectionComponent } from './section-b1/sub-section/sub-section.component';
import { SharedModule } from '../shared';
@NgModule({
  declarations: [SectionB1Component, SubSectionComponent],
  imports: [
    CommonModule,
    SectionB1RoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SectionB1Module { }
