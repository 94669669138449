import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SectionDRoutingModule } from './section-d-routing.module';
import { SectionDComponent } from './section-d/section-d.component';
import { SharedModule } from '../shared/index';

@NgModule({
  declarations: [SectionDComponent],
  imports: [
    CommonModule,
    SectionDRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SectionDModule { }
