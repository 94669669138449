import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule, HeaderComponent, FooterComponent, SectionComponent, ConsumerinfoComponent } from './shared';
import {SearchModule } from './search';
import { MaterialModule } from './material.module';
import { SectionAModule} from './section-a/section-a.module';
import { SectionBModule} from './section-b/section-b.module';
import { SectionB1Module } from './section-b1/section-b1.module';
import { SectionB2Module } from './section-b2/section-b2.module';
import { SectionCModule } from './section-c/section-c.module';
import { SectionDModule } from './section-d/section-d.module';
import { SectionEModule } from './section-e/section-e.module';
import { SummaryModule } from './summary/summary.module';
import { LoginModule } from './login/login.module';
import { UserInfoModule } from './user-info/user-info.module';
import { CertifySubmitModule } from './certify-submit/certify-submit.module';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SectionComponent,
    ConsumerinfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    SearchModule,
    MaterialModule,
    SectionAModule,
    SectionBModule,
    SectionB1Module,
    SectionB2Module,
    SectionCModule,
    SectionDModule,
    SectionEModule,
    SummaryModule,
    LoginModule,
    UserInfoModule,
    CertifySubmitModule
    
  ],
  providers: [ ],
  bootstrap: [AppComponent]
})
export class AppModule { }
