import { SectionB1DataRow } from './section-b1-data-row';

import { DatePipe } from '@angular/common';


export function mapCommonSection(CopyFrom: SectionB1DataRow,
  CopyTo: SectionB1DataRow): SectionB1DataRow {
  CopyTo.aB1_ALOC_ID = CopyFrom.aB1_ALOC_ID;
  CopyTo.aB1_CATEGORY = CopyFrom.aB1_CATEGORY;
  CopyTo.aB1_FREQ = CopyFrom.aB1_FREQ;
  CopyTo.aB1_FREQ_COMMENT = CopyFrom.aB1_FREQ_COMMENT;
  CopyTo.aB1_FREQ_DAYS = CopyFrom.aB1_FREQ_DAYS;
  CopyTo.aB1_SECTION_ID = CopyFrom.aB1_SECTION_ID;
  CopyTo.aB1_SKILL_COMMENT = CopyFrom.aB1_SKILL_COMMENT;
  CopyTo.aB1_SKILL_ID = CopyFrom.aB1_SKILL_ID;
  CopyTo.aB1_SKILL_LEVEL = CopyFrom.aB1_SKILL_LEVEL;
  CopyTo.skilL_ID = CopyFrom.skilL_ID;
  CopyTo.refCursor = CopyFrom.refCursor;
  return CopyTo;
}

export function mapBowelColostomyCustom(CopyFrom: {
  SkilledTask: string,
  OtherInfo: string
}, CopyTo: SectionB1DataRow): SectionB1DataRow {
  CopyTo.aB1_OPTION1 = CopyFrom.SkilledTask;
  CopyTo.aB1_OPTION_COMMENTS = CopyFrom.OtherInfo;
  return CopyTo;
}

export function mapDialysisCustom(CopyFrom: {
  TypeOfDialysis: string,
  Location: string
}, CopyTo: SectionB1DataRow): SectionB1DataRow {
  CopyTo.aB1_OPTION1 = CopyFrom.TypeOfDialysis;
  CopyTo.aB1_OPTION2 = CopyFrom.Location;
  return CopyTo;
}

export function mapIVSCustom(CopyFrom: {
  TypeOfIV: string,
  Location: string
}, CopyTo: SectionB1DataRow): SectionB1DataRow{
  return CopyTo;
}

export function mapParenteralNutritionCustom(CopyFrom: {
  kcalperday: string,
  mlkgday: string
}, CopyTo: SectionB1DataRow): SectionB1DataRow {
  CopyTo.aB1_OPTION1 = CopyFrom.kcalperday;
  CopyTo.aB1_OPTION2 = CopyFrom.mlkgday
  return CopyTo;
}

export function mapTubeFeedingsCustom(CopyFrom: {
  TypeofTubeFeeding: string,
  TypeofTubeFeedingOther: string,
  Duration: string,
  MethodofDelivery: string
}, CopyTo: SectionB1DataRow): SectionB1DataRow {
  CopyTo.aB1_OPTION1 = CopyFrom.TypeofTubeFeeding;
  CopyTo.aB1_OPTION4 = CopyFrom.TypeofTubeFeedingOther;
  CopyTo.aB1_OPTION2 = CopyFrom.Duration;
  CopyTo.aB1_OPTION3 = CopyFrom.MethodofDelivery;
  return CopyTo;
}

export function mapOxygenCustom(CopyFrom: {
  OxygenUse: string,
  Method: string,
  MethodOther: string,
  SpecialOrders: string,
  litersPerMinute: string
}, CopyTo: SectionB1DataRow): SectionB1DataRow {
  CopyTo.aB1_OPTION1 = CopyFrom.OxygenUse;
  CopyTo.aB1_OPTION2 = CopyFrom.Method;
  CopyTo.aB1_OPTION_COMMENTS = CopyFrom.MethodOther;
  CopyTo.aB1_OPTION3 = CopyFrom.SpecialOrders;
  CopyTo.aB1_OPTION4 = CopyFrom.litersPerMinute;
  return CopyTo;
}

export function mapUrinaryCatheterSkilledTasksCustom(CopyFrom: {
  TypeofCatheter: string,
  Dateoflastchange: string 
}, CopyTo: SectionB1DataRow): SectionB1DataRow {
  let dt: DatePipe = new DatePipe('en-us');  
  CopyTo.aB1_OPTION1 = CopyFrom.TypeofCatheter;
  CopyTo.aB1_OPTION2 = dt.transform(CopyFrom.Dateoflastchange, 'MM/dd/yyyy');
  return CopyTo;
}

export function mapSeizureManagementCustom(CopyFrom: { SeizureSeverity: string, Dateoflastseizure: string }, CopyTo: SectionB1DataRow): SectionB1DataRow {
  let dt: DatePipe = new DatePipe('en-us');
  CopyTo.aB1_OPTION1 = CopyFrom.SeizureSeverity;
  CopyTo.aB1_OPTION2 = dt.transform(CopyFrom.Dateoflastseizure, 'MM/dd/yyyy'); 
  return CopyTo;
}

export enum SkillLevelOptions {
    IndependentOnly,
    FrequencyOnly,
    Both
}
