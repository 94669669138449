import { Component, OnInit, Output, EventEmitter,ViewChild } from '@angular/core';
import { SummaryService,DataService, AlertsService, SummaryResponse, SummaryRequest } from '../../shared/index';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { TopsummaryComponent } from './topsummary/topsummary.component';
import { LocDeterminationComponent } from './loc-determination/loc-determination.component';
import { McpReviewComponent } from './mcp-review/mcp-review.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { LocDetermination } from '../../shared/models/loc-determination.model';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
  @ViewChild(TopsummaryComponent, { static: false }) topSummary: TopsummaryComponent;
  @ViewChild(LocDeterminationComponent, { static: false }) locDetermination: LocDeterminationComponent;
  @ViewChild(ContactInformationComponent, { static: false }) contactInformation: ContactInformationComponent;
  @ViewChild(McpReviewComponent, { static: false }) mcpInformation: McpReviewComponent;
  summaryResponseModel: SummaryResponse = {} as SummaryResponse;
  summaryRequestModel: SummaryRequest = {} as SummaryRequest;

  constructor(private summaryService: SummaryService, private alertsService: AlertsService, public dataService: DataService) { }

  ngOnInit() {
    this.summaryRequestModel.aS_ID = this.dataService.getSelectedDocument();
    this.summaryService.getSummary(this.summaryRequestModel).subscribe(response => {
      this.summaryResponseModel = response;
      //console.log(response);
      this.alertsService.success("Summary Loaded");

      this.topSummary.receiveTopSummaryData(this.summaryResponseModel);
      this.locDetermination.receiveLocDetermination(this.summaryResponseModel);
      this.contactInformation.receiveContactInformation(this.summaryResponseModel);
      this.mcpInformation.receivemcpReview(this.summaryResponseModel);
    }, err => {
      this.alertsService.error(JSON.stringify(err));
    });

  }
}
