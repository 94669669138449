export class SectionB1DataRow {
  aB1_SECTION_ID: number;
  aB1_ALOC_ID: number;
  aB1_CATEGORY: string;
  aB1_SKILL_ID: string;
  aB1_SKILL_LEVEL: string;
  skilL_ID: string;
  aB1_SKILL_COMMENT: string;
  aB1_FREQ: string;
  aB1_FREQ_DAYS: string;
  aB1_FREQ_COMMENT: string;
  aB1_OPTION_COMMENTS: string;
  aB1_OPTION1: string;
  aB1_OPTION2: string;
  aB1_OPTION3: string;
  aB1_OPTION4: string;
  refCursor: string;
}
