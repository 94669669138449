import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchResultModel, SearchModel } from '../models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private apiService: ApiService) { }

  getSearchLoad(pdfExport): Observable<SearchResultModel[]> {
    return this.apiService.get(`search/${pdfExport}`).pipe(map(data => {      
      return data;
    }));
  }

  searchDocuments(searchParams): Observable<SearchResultModel[]> {
    return this.apiService.post(`Search/searchDocument`, searchParams);
  }

  
}
