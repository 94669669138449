import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'new-document',
    loadChildren: () => import('./section-a/section-a.module').then(m => m.SectionAModule)
  },
  {
    path: 'section-a',
    loadChildren: () => import('./section-a/section-a.module').then(m => m.SectionAModule)
  },
  {
    path: 'section-b',
    loadChildren: () => import('./section-b/section-b.module').then(m => m.SectionBModule)
  },
  {
    path: 'section-b1',
    loadChildren: () => import('./section-b1/section-b1.module').then(m => m.SectionB1Module)
  },
  {
    path: 'section-b2',
    loadChildren: () => import('./section-b2/section-b2.module').then(m => m.SectionB2Module)
  },
  {
    path: 'section-c',
    loadChildren: () => import('./section-c/section-c.module').then(m => m.SectionCModule)
  },
  {
    path: 'section-d',
    loadChildren: () => import('./section-d/section-d.module').then(m => m.SectionDModule)
  },
  {
    path: 'section-e',
    loadChildren: () => import('./section-e/section-e.module').then(m => m.SectionEModule)
  },
  {
    path: 'summary',
    loadChildren: () => import('./summary/summary.module').then(m => m.SummaryModule)
  },
  {
    path: 'search',
    loadChildren : () => import('./search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./user-info/user-info.module').then(m => m.UserInfoModule)
  },
  {
    path: 'certify-submit',
    loadChildren: () => import('./certify-submit/certify-submit.module').then(m => m.CertifySubmitModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
