import { Component, OnInit,Input } from '@angular/core';
import { DataService, SummaryService, AlertsService } from '../../../shared/services';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { SummaryResponse } from '../../../shared';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topsummary',
  templateUrl: './topsummary.component.html',
  styleUrls: ['./topsummary.component.css'],
  providers: [DatePipe]
})
export class TopsummaryComponent implements OnInit {  
    topSummaryForm: FormGroup;
    enableWithDraw: boolean = false;
    constructor(private fb: FormBuilder, private router: Router,public dataService: DataService, public alert: AlertsService, public SummarySVC: SummaryService, private datePipe: DatePipe) {
    this.topSummaryForm = this.fb.group({
     documenT_ID:new FormControl(''),
     type:new FormControl(''),
     status:new FormControl(''),
     name:new FormControl(''),
     ssn:new FormControl(''),
        datE_OF_BIRTH: new FormControl({ value: '', diabled: true }),
     submitteR_FACILITY:new FormControl(''),
     submitteR_NAME:new FormControl(''),
        submiT_DATE: new FormControl({ value: '', diabled: true }),
     paa:new FormControl(''),
     clienT_NUMBER:new FormControl(''),
     aS_ADLS_INDEPENDENT:new FormControl(''),
     aS_ADLS_SUPERVISED:new FormControl(''),
     aS_ADLS_ASSISTED:new FormControl(''),
     aS_IADLS_INDEPENDENT:new FormControl(''),
     aS_IADLS_SUPERVISED:new FormControl(''),
     aS_IADLS_ASSISTED:new FormControl(''),
     aS_MED_ADMIN:new FormControl(''),
     aS_MED_ADMIN_INDEPENDENT:new FormControl(''),
     aS_MED_ADMIN_SUPERVISED:new FormControl(''),
     aS_MED_ADMIN_ASSISTED:new FormControl(''),
     aS_BCAT_SCORE:new FormControl(''),
     aS_MEDICAL_CONDITION:new FormControl(''),
     aS_SKILLED_NURSING_DAYS:new FormControl(''),
     aS_SKILLED_NURSING_SERVICE:new FormControl(''),
     aS_SKILLED_REHAB_DAYS:new FormControl(''),
     aS_SKILLED_REHAB_SERVICE:new FormControl(''),
    });
  }

  ngOnInit() {
   
  }

    withDraw() {
        //console.log("withdraw");
        let withDrawModel = { "aO_ALOC_ID": this.dataService.getSelectedDocument() }
        this.SummarySVC.withDraw(withDrawModel).subscribe(resp => {
            this.alert.success("Document withdrawn Succesfully");
            this.router.navigate(['search']);
        }, err => {
            this.alert.error(JSON.stringify(err));
        });
    }

    receiveTopSummaryData(data: SummaryResponse) {
        //console.log(data.status);
      if (data.status == "SUBMITTED") {
        this.enableWithDraw = true;
      } else if (data.status == "PIMSLOADED") {
        data.status = 'PIMS LOADED';
      } else if (data.status == "INPROCESS") {
        data.status = 'IN PROCESS';
      }
    this.topSummaryForm.patchValue(data);
    this.setFormData(data);
   
  }
  setFormData(data) {

    if (data['submiT_DATE'] != null)
      this.topSummaryForm.get('submiT_DATE').setValue(new Date(data['submiT_DATE']));

    if (data['datE_OF_BIRTH'] != null)
      this.topSummaryForm.get('datE_OF_BIRTH').setValue(new Date(data['datE_OF_BIRTH']));
  }
}
