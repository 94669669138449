import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared';
import { SubmitDocumentComponent } from './submit-document/submit-document.component';


const routes: Routes = [{
  path: '',
  component: SubmitDocumentComponent,
  canActivate: [AuthGuard]
}];


@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class CertifySubmitRoutingModule { }
