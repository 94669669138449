import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SectionERoutingModule } from './section-e-routing.module';
import { SectionEComponent } from './section-e/section-e.component';
import { SharedModule } from '../shared/index';

@NgModule({
  declarations: [SectionEComponent],
  imports: [
    CommonModule,
    SectionERoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SectionEModule { }
