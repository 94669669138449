import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { SearchRoutingModule } from './search-routing.module';
import { MaterialModule } from '../material.module';
import { TextMaskModule } from 'angular2-text-mask';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';
import { SharedModule } from '../shared';
@NgModule({
  declarations: [SearchComponent, ConfirmActionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SearchRoutingModule,
    MaterialModule,
    TextMaskModule,
    SharedModule
  ],
  entryComponents: [ConfirmActionComponent]
})
export class SearchModule { }
