import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SectionB2RoutingModule } from './section-b2-routing.module';
import { SectionB2Component } from './section-b2/section-b2.component';

@NgModule({
  declarations: [SectionB2Component],
  imports: [
    CommonModule,
    SectionB2RoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SectionB2Module { }
